import { trpc } from '@frontend/trpc';

type Input = {
  deposit: {
    deposits: Array<{
      id: string;
      value: number;
    }>;
    automatic: boolean;
    complementary: boolean;
    confirmationToken?: string;
    creditDate: Date;
    expirationDate?: Date;
    expires: boolean;
    paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
  };
  onSuccess: () => void;
  onAuthError: () => void;
};

export function useCreateDeposit() {
  const createMany =
    trpc.company.deposits.createDepositsFromSpreadsheet.useMutation();

  return {
    mutateAsync: (input: Input) => {
      return createMany.mutateAsync(input.deposit, {
        onSuccess: (data) => {
          if (data.auth === 'FAILED') {
            input.onAuthError();
          } else {
            input.onSuccess();
          }
        },
      });
    },
    data: createMany.data,
  };
}
