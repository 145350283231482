import Typography from '@frontend/components/Typography';

type UpdateCardStatusModalBody = {
  newStatus: 'HARD_LOCKED' | 'NORMAL';
  cardName: string;
  employeeName: string;
};

export function UpdateCardStatusModalBody(props: UpdateCardStatusModalBody) {
  if (props.newStatus === 'HARD_LOCKED') {
    return (
      <>
        <Typography.Body4 weight={700} as="b">
          {props.employeeName}
        </Typography.Body4>{' '}
        não conseguirá utilizar o cartão{' '}
        <Typography.Body4 weight={700} as="b">
          ”{props.cardName}”
        </Typography.Body4>{' '}
        enquanto estiver bloqueado.
      </>
    );
  }

  return (
    <>
      <Typography.Body4 weight={700} as="b">
        {props.employeeName}
      </Typography.Body4>{' '}
      poderá voltar a utilizar o cartão{' '}
      <Typography.Body4 weight={700} as="b">
        ”{props.cardName}”
      </Typography.Body4>
      .
    </>
  );
}
