import useColors, { Color } from '@frontend/utils/theme/colors';
import Button, { ButtonProps } from '../Button';
import Typography from '../Typography';

import * as SC from './styled';

export type SummaryFeedbackContentProps = {
  title?: string;
  description: string;
  action?: ButtonProps;
  backgroundColor?: Color;
};

export default function SummaryFeedbackContent(
  props: SummaryFeedbackContentProps,
) {
  const colors = useColors();
  return (
    <SC.ContentContainer
      backgroundColor={
        props.backgroundColor ? colors[props.backgroundColor] : undefined
      }
    >
      <div>
        {props.title && (
          <Typography.Body4 color="neutral_20" weight={600}>
            {props.title}
          </Typography.Body4>
        )}

        <Typography.Caption color="neutral_40">
          {props.description}
        </Typography.Caption>
      </div>

      {props.action && (
        <div>
          <Button {...props.action} />
        </div>
      )}
    </SC.ContentContainer>
  );
}
