import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import Button from '@frontend/components/Button';
import LinkButton from '@frontend/components/LinkButton';

import * as SC from './styled';

type ButtonAction = {
  description: string;
  disabled?: boolean;
  onClick?(): void;
  loading?: boolean;
  color?: 'default' | 'success' | 'info' | 'error' | 'neutral';
  variant?: React.ComponentProps<typeof Button>['variant'];
  /**
   * Icon to show in button
   * @example IconAdd
   */
  icon?: React.ComponentProps<typeof Icons>['name'];
};

type ModalFooter = {
  cancel?: Omit<ButtonAction, 'variant'>;
  primary?: ButtonAction;
  secondary?: ButtonAction;
};

export function ModalFooter(props: ModalFooter) {
  return (
    <SC.Container>
      {props?.cancel && (
        <LinkButton
          id="modal-cancel-button"
          {...props.cancel}
          variant="neutral"
        >
          {props.cancel.description}
        </LinkButton>
      )}

      {(props?.primary || props?.secondary) && (
        <div className="modal-right-actions-container">
          {props?.secondary && (
            <Button
              variant="secondary"
              {...props.secondary}
              onClick={() => void props?.secondary?.onClick?.()}
            >
              {props.secondary.description}
            </Button>
          )}

          {props?.primary && (
            <Button
              variant="primary"
              {...props.primary}
              onClick={() => void props?.primary?.onClick?.()}
            >
              {props.primary.description}
            </Button>
          )}
        </div>
      )}
    </SC.Container>
  );
}
