import { DatePicker } from '@flash-tecnologia/hros-web-ui-v2';
import ToggleCollapseField from '@frontend/components/inputs/ToggleCollapseField/ToggleField';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { useFormMetadata } from '../../data/useFormMetadata';

type Props = {
  form: UseFormReturn;
  formMetadata: ReturnType<typeof useFormMetadata>;
};

export function DepositExpiration(props: Props) {
  return (
    <Controller
      control={props.form.control}
      name="expires"
      render={({ field: toggleField }) => (
        <ToggleCollapseField
          title="Expirar depósito"
          value={toggleField.value}
          description={
            'Retornar valores das pessoas para a carteira corporativa após uma data determinada.'
          }
          onChange={(newValue) => toggleField.onChange(newValue)}
        >
          <Controller
            control={props.form.control}
            name="expirationDate"
            render={({ field: dateField, fieldState }) => (
              <DatePicker
                disabled={!toggleField.value}
                value={dateField.value}
                label="Data de expiração"
                onDateChange={(date) =>
                  dateField.onChange(date?.toDate() ?? undefined)
                }
                helperText={fieldState.error?.message}
                error={!!fieldState.error}
                onBlur={dateField.onBlur}
                fromDate={props.formMetadata.expirationDateFrom}
              />
            )}
          />
        </ToggleCollapseField>
      )}
    />
  );
}
