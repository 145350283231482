import ToggleCollapseField from '@frontend/components/inputs/ToggleCollapseField/ToggleField';
import ToggleField from '@frontend/components/inputs/ToggleField';
import Typography from '@frontend/components/Typography';
import { MODAL_AboutComplementaryDeposit } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/ActivateUsers/components/steps/01-Form/components/AboutComplementaryDepositModal/AboutComplementaryDepositModal';
import ModalService from '@frontend/services/ModalService';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { useFormMetadata } from '../../data/useFormMetadata';

type Props = {
  form: UseFormReturn;
  formMetadata: ReturnType<typeof useFormMetadata>;
};

export function DepositRecurrence(props: Props) {
  return (
    <Controller
      control={props.form.control}
      name="deposit.automatic"
      render={({ field }) => (
        <ToggleCollapseField
          title="Repetir depósito"
          description={
            props.formMetadata.recurrentDepositDisabled
              ? 'Esta opção só está disponível para depósitos feitos com a carteira corporativa.'
              : 'Você pode definir uma recorrência mensal de depósito para as pessoas.'
          }
          onChange={(newValue) => field.onChange(newValue)}
          value={field.value}
          disabled={props.formMetadata.recurrentDepositDisabled}
        >
          <Controller
            control={props.form.control}
            name="deposit.complementary"
            render={({ field }) => (
              <ToggleField
                title="Complementar saldo"
                description={
                  <>
                    O valor do depósito definido será um saldo fixo e iremos
                    apenas repor o que for consumido no período.{' '}
                    <Typography.Caption
                      color="secondary_50"
                      weight={700}
                      as="button"
                      onClick={() =>
                        ModalService.show(MODAL_AboutComplementaryDeposit, {})
                      }
                    >
                      Saiba mais
                    </Typography.Caption>
                  </>
                }
                onChange={(newValue) => field.onChange(newValue)}
                value={field.value}
              />
            )}
          />
        </ToggleCollapseField>
      )}
    />
  );
}
