import { formatTime } from '@frontend/utils/masks/formatTime';
import React from 'react';

type Props = {
  timeInSeconds: number;
  render: (seconds: number, formattedTime: string) => JSX.Element;
  onEnd: () => void;
};

type Ref = {
  reset: (timeInSeconds: number) => void;
};

export const Countdown = React.forwardRef(function (
  props: Props,
  ref: React.Ref<Ref>,
) {
  const [time, setTime] = React.useState(props.timeInSeconds);

  React.useImperativeHandle(ref, () => ({
    reset: (s) => {
      setTime(s);
    },
  }));

  React.useEffect(() => {
    if (time <= 0) {
      props.onEnd();
      return;
    }

    const interval = setInterval(() => setTime(time - 1), 1000);
    return () => clearInterval(interval);
  }, [time]);

  return props.render(time, formatTime(time));
});
