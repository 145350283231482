import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';

type Props = {
  disabled?: boolean;
  selected: boolean;
  icon: IconTypes;
};

export default function CardSelectIcon(props: Props) {
  return (
    <Icon
      background={props.disabled || !props.selected ? 'neutral' : 'default'}
      color={props.disabled || !props.selected ? 'neutral_40' : 'secondary_50'}
      name={props.selected ? 'IconCheck' : props.icon}
      size={32}
    />
  );
}
