import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import UpdateCardStatusModal from '../modals/UpdateCardStatusModal';
import { useGetUsersInput } from './useGetUsersInput';

type Input = {
  cardId: string;
  employeeId: string;
  status: 'NORMAL' | 'HARD_LOCKED';
};

export default function useUpdateCardStatus() {
  const context = trpc.useContext();
  const getUsersInput = useGetUsersInput();
  const patchCard = trpc.company.cards.virtual.patch.useMutation({
    onMutate: toastStart,
    onError: toastError,
  });

  return {
    mutateAsync: (input: Input) =>
      patchCard.mutateAsync(input, {
        onSuccess(data) {
          // Mutation failed
          if (!data.success) {
            toastError();
            return;
          }

          // Mutation succeeded
          toastSuccess();

          // Optimistic update
          context.company.cards.search.setData(
            getUsersInput.input,
            (oldData) => {
              if (!oldData || !input.status) return undefined;
              return {
                ...oldData,
                users: oldData.users.map((user) => {
                  if (user.employeeNanoId !== input.employeeId) return user;
                  return {
                    ...user,
                    cards: user.cards.map((card) => {
                      if (card.cardId !== input.cardId) return card;
                      return {
                        ...card,
                        status: input.status,
                      };
                    }),
                  };
                }),
              };
            },
          );

          // Waits until the update event propagates to em-service
          setTimeout(() => {
            context.employee.flashCards.virtual.getAll.invalidate();
          }, 10000);

          ModalService.hide(UpdateCardStatusModal);
        },
      }),
    isLoading: patchCard.isLoading,
  };
}

function toastStart() {
  dispatchToast({
    type: 'warning',
    content: 'Alterando status do cartão...',
  });
}

function toastError() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível alterar o status do cartão.',
    description: errors.actions.toastDescription,
  });
}

function toastSuccess() {
  dispatchToast({
    type: 'success',
    content: 'Status do cartão alterado com sucesso!',
  });
}
