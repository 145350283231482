import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';

import { ThreeColumnsTemplate } from '@frontend/components/FocusedFlow/templates/ThreeColumnsTemplate';
import Tip from '@frontend/components/Tip';
import { UseFormReturn } from '../../../controllers/form/useForm';
import { SummaryBox } from './components/SummaryBox/SummaryBox';
import { DepositCreditDate } from './components/inputs/DepositCreditDate.input';
import { DepositExpiration } from './components/inputs/DepositExpiration.input';
import { DepositPaymentMethod } from './components/inputs/DepositPaymentMethod.input';
import { DepositRecurrence } from './components/inputs/DepositRecurrence.input';
import { useFormMetadata } from './data/useFormMetadata';

type Props = {
  form: UseFormReturn;
};

export function Form(props: Props) {
  const formMetadata = useFormMetadata({ form: props.form });

  return (
    <ThreeColumnsTemplate
      id="onboarding.activate-users.form"
      MiddleColumn={
        <>
          {/* -------------------- First deposit setup ------------------- */}
          <Typography.Headline8 color={'neutral_20'}>
            Detalhes do depósito
          </Typography.Headline8>
          <Spacer y={'xs3'} />
          <DepositCreditDate form={props.form} formMetadata={formMetadata} />
          <Spacer y={'s'} />
          <Tip>
            Se quiser depositar valores diferentes por pessoa, você pode fazer
            um depósito via planilha.
          </Tip>
          <Spacer y={'s'} />
          <Typography.Headline9 color={'neutral_20'}>
            Escolha a forma de pagamento
          </Typography.Headline9>
          <Spacer y={'xs4'} />
          <DepositPaymentMethod form={props.form} formMetadata={formMetadata} />
          <Spacer y={'s'} />
          {/* ------------------- More deposit options ------------------- */}
          <Typography.Headline9 color={'neutral_20'}>
            Mais opções
          </Typography.Headline9>
          <Spacer y={'xs4'} />
          <DepositExpiration form={props.form} formMetadata={formMetadata} />
          <Spacer y={'xs4'} />
          <DepositRecurrence form={props.form} formMetadata={formMetadata} />
        </>
      }
      RightColumn={<SummaryBox form={props.form} />}
    />
  );
}
