import {
  BoxAdornment,
  Icons,
  IconTypes,
  Spinner,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { MouseEventHandler, useRef } from 'react';
import styled from 'styled-components';

const BaseIcon = styled(Icons)`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  fill: transparent;
  align-items: center;
`;

const SmallIcon = styled(BaseIcon)`
  width: 24px;
  height: 24px;
`;

const BigIcon = styled(BaseIcon)`
  width: 48px;
  height: 48px;
`;

type IBigButtonProps = {
  className?: string;
  description: string;
  iconName: IconTypes;
  iconColor: string;
  iconFill?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

function BigButtonBase({
  className,
  description,
  iconColor,
  iconFill,
  iconName,
  onClick,
}: IBigButtonProps) {
  return (
    <BoxAdornment
      className={className}
      onClick={onClick}
      leftAdornment={
        <SmallIcon
          fill={iconFill}
          size={12}
          color={iconColor}
          name={iconName}
        />
      }
      description={description}
    />
  );
}

interface CustomBoxAdornment {
  description: string;
  leftAdornment: React.ReactNode;
  title: string;
  loading?: boolean;
  hasError?: boolean;
  footnote?: JSX.Element;
}

const AlertIcon = styled(BigIcon)`
  background-color: #ffecd6;
`;

const AlertTypography = styled(Typography.Body3)`
  color: #83727d;
`;

const StyledBoxAdornment = styled(BoxAdornment)`
  background-color: #f8f6f8;
`;

const BigBoxAdornment = styled(BoxAdornment)`
  width: 100%;
`;

const BigDiv = styled.div`
  width: 100%;
  max-width: 500px;
`;

const Footnote = styled.p`
  display: inline-flex;
  gap: 2px;
`;

const FootnoteIcon = styled.span`
  align-items: center;
  display: flex;
`;

const Currency = styled.span`
  color: #c96c01;
  font-weight: 700;
`;

function AssetFreezeFootnote({ value }) {
  return (
    <Footnote>
      <span>
        <Currency>{value}</Currency> em bloqueio judicial
      </span>
      <Tooltip
        title="Bloqueio de saldo determinado por uma ordem judicial. Para mais informações, entre em contato com o nosso suporte."
        placement="top"
      >
        <FootnoteIcon>
          <Icons size={12} fill="transparent" name="IconInfoCircle" />
        </FootnoteIcon>
      </Tooltip>
    </Footnote>
  );
}

function CustomBoxAdornment({
  description,
  hasError,
  title,
  leftAdornment,
  loading,
  footnote,
}: CustomBoxAdornment) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <BigDiv ref={wrapperRef}>
      {hasError ? (
        <StyledBoxAdornment
          style={{
            width: wrapperRef.current?.clientWidth,
            height: wrapperRef.current?.clientHeight,
          }}
          leftAdornment={
            <AlertIcon
              size={24}
              color="#C96C01"
              fill="#FFECD6"
              name="IconAlertCircle"
            />
          }
          title={<AlertTypography>Dado não encontrado</AlertTypography>}
          description={
            <Typography.Caption>
              Tivemos um problema tentando trazer essa informação. Entre em
              contato com suporte.
            </Typography.Caption>
          }
        />
      ) : (
        <BigBoxAdornment
          leftAdornment={loading ? <Spinner size={48} /> : leftAdornment}
          title={
            <Typography.Body3 color="neutral_50">{title}</Typography.Body3>
          }
          description={
            <>
              <Typography.Headline5>{description}</Typography.Headline5>
              {!loading && footnote}
            </>
          }
        />
      )}
    </BigDiv>
  );
}

const BigButton = styled(BigButtonBase)`
  background-color: ${({ theme }) => theme.colors.secondary[99]};
  border-color: transparent !important;
  width: 100%;
  max-width: 300px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
`;

const Container = styled.div`
  display: flex;
`;

const SpaceBetween = styled(Container)`
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`;

const Sep = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.neutral[90]};
  width: 100%;
  height: 1px;
`;

const PaddingWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.s};
`;

type WrapperProps = {
  title: string;
  children: React.ReactNode;
};

function Wrapper({ title, children }: WrapperProps) {
  return (
    <StyledWrapper>
      <PaddingWrapper>
        <Typography.Headline8>{title}</Typography.Headline8>
      </PaddingWrapper>
      <Sep />
      <PaddingWrapper>{children}</PaddingWrapper>
    </StyledWrapper>
  );
}

export const CenterText = styled(Typography.Body3)`
  text-align: center;
`;

export {
  AssetFreezeFootnote,
  BigButton,
  BigIcon,
  Container,
  CustomBoxAdornment,
  SpaceBetween,
  Wrapper,
};
