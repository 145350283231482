import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import useUpdateCardStatus from '../../data/useUpdateCardStatus';
import { UpdateCardStatusModalBody } from './UploadCardStatusModalBody';

type Props = {
  card: {
    _id: string;
    name: string;
  };
  employee: {
    _id: string;
    name: string;
  };
  newStatus: 'HARD_LOCKED' | 'NORMAL';
};

function UpdateCardStatusModal(props: Props) {
  const modalController = ModalService.useModalController();
  const updateStatus = useUpdateCardStatus();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text:
            props.newStatus === 'HARD_LOCKED'
              ? 'Bloquear cartão'
              : 'Desbloquear cartão',
          onClick: () =>
            void updateStatus.mutateAsync({
              cardId: props.card._id,
              employeeId: props.employee._id,
              status: props.newStatus,
            }),
          icon: props.newStatus === 'HARD_LOCKED' ? 'IconLock' : 'IconLockOpen',
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => modalController.remove(),
        },
      }}
      loading={updateStatus.isLoading}
      isOpen={modalController.visible}
      onClose={() => modalController.remove()}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        highlightedSize: 'compact',
        title:
          props.newStatus === 'HARD_LOCKED'
            ? 'Tem certeza que deseja bloquear o cartão?'
            : 'Tem certeza que deseja desbloquear o cartão?',
        body: (
          <UpdateCardStatusModalBody
            newStatus={props.newStatus}
            cardName={props.card.name}
            employeeName={props.employee.name}
          />
        ),
      }}
    />
  );
}

export default ModalService.create(UpdateCardStatusModal);
