import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import errors from '@frontend/utils/commonTexts/errors';

type Props = {
  retryCreation: () => void;
};

function CreatePolicyErrorModal(props: Props) {
  const modalController = ModalService.useModalController();

  function handleRetry() {
    props.retryCreation();
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção',
        title: 'Não foi possível criar esta política',
        body: errors.actions.toastDescription,
      }}
      buttons={{
        cancel: {
          text: 'Voltar',
          onClick: modalController.remove,
        },
        confirm: {
          text: 'Tentar novamente',
          onClick: handleRetry,
        },
      }}
    ></ConfirmationModal>
  );
}

export default ModalService.create(CreatePolicyErrorModal);
