import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';

import { ThreeColumnsTemplate } from '@frontend/components/FocusedFlow/templates/ThreeColumnsTemplate';
import Tag from '@frontend/components/TagV2';
import Tip from '@frontend/components/Tip';
import Flex from '@frontend/components/frames/Flex';
import { UseFormReturn } from '../../../controllers/form/useForm';
import { SummaryBox } from './components/SummaryBox/SummaryBox';
import { useFormMetadata } from './data/useFormMetadata';
import { DepositAmount } from './inputs/DepositAmount.input';
import { DepositCreditDate } from './inputs/DepositCreditDate.input';
import { DepositExpiration } from './inputs/DepositExpiration.input';
import { DepositPaymentMethod } from './inputs/DepositPaymentMethod.input';
import { DepositRecurrence } from './inputs/DepositRecurrence.input';
import { EmployeeIds } from './inputs/EmployeeIds.input';

type Props = {
  form: UseFormReturn;
};

export function Form(props: Props) {
  const formMetadata = useFormMetadata({ form: props.form });

  return (
    <ThreeColumnsTemplate
      id="onboarding.activate-users.form"
      MiddleColumn={
        <>
          {/* --------------------- Select employees --------------------- */}
          <Typography.Headline8 color={'neutral_20'}>
            Quem vai começar a usar o cartão corporativo?
          </Typography.Headline8>
          <Spacer y={'xs1'} />
          <EmployeeIds form={props.form} />
          <Spacer y={'s'} />
          {/* -------------------- First deposit setup ------------------- */}
          <Flex gap="xs4" align="center">
            <Typography.Headline8 color={'neutral_20'}>
              Primeiro depósito
            </Typography.Headline8>
            <Tag variant="neutral" size="sm">
              Opcional
            </Tag>
          </Flex>
          <Typography.Body4 color={'neutral_30'}>
            As pessoas só conseguirão usar o cartão corporativo quando um
            primeiro depósito de saldo for disponibilizado para elas.
          </Typography.Body4>
          <Spacer y={'xs3'} />
          <Flex gap="xs3">
            <DepositAmount form={props.form} />
            <DepositCreditDate form={props.form} formMetadata={formMetadata} />
          </Flex>
          <Spacer y={'s'} />
          <Tip>
            Se quiser depositar valores diferentes por pessoa, você pode pular
            esta parte e fazer um depósito via planilha após a ativação.
          </Tip>
          <Spacer y={'s'} />
          <Typography.Headline9 color={'neutral_20'}>
            Escolha a forma de pagamento
          </Typography.Headline9>
          <Spacer y={'xs4'} />
          <DepositPaymentMethod form={props.form} formMetadata={formMetadata} />
          <Spacer y={'s'} />
          {/* ------------------- More deposit options ------------------- */}
          <Typography.Headline9 color={'neutral_20'}>
            Mais opções
          </Typography.Headline9>
          <Spacer y={'xs4'} />
          <DepositExpiration form={props.form} formMetadata={formMetadata} />
          <Spacer y={'xs4'} />
          <DepositRecurrence form={props.form} formMetadata={formMetadata} />
        </>
      }
      RightColumn={<SummaryBox form={props.form} />}
    />
  );
}
