import Typography from '@frontend/components/Typography';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingRequestBalanceTutorialModal from './OnboardingRequestBalanceTutorialModal';

function OnboardingRequestBalanceEndModal() {
  const modalController = ModalService.useModalController();

  function backToTutorial() {
    modalController.remove();
    ModalService.show(OnboardingRequestBalanceTutorialModal, {});
  }

  function handleDismiss() {
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      text={{
        highlightedText: 'Tudo certo!',
        title: 'Pedido de saldo',
        body: (
          <Typography.Body4>
            Agora, você está pronto para usar a nova funcionalidade de aprovar
            ou reprovar o pedido de saldo corporativo da sua empresa. Caso
            queira saber mais sobre, confira nossa{' '}
            <Typography.Body3
              as="a"
              href="https://beneficios.flashapp.com.br/faq/pedido-de-saldo-no-cartão-corporativo"
              target="_blank"
              color="secondary_50"
            >
              FAQ
            </Typography.Body3>
            .
            <br />
            <br />
            Você está pronto para explorar os pedidos de saldo.
          </Typography.Body4>
        ),
      }}
      variant="default"
      buttons={{
        cancel: {
          onClick: backToTutorial,
          text: 'Voltar',
        },
        confirm: {
          onClick: handleDismiss,
          text: 'Começar',
        },
      }}
    ></ConfirmationModal>
  );
}

export default ModalService.create(OnboardingRequestBalanceEndModal);
