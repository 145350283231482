import {
  LinkButton,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { ApprovalFlow } from '../../form/schema';
import { CriteriaSelector } from './CriteriaSelector';

export function BasicInformationStep() {
  const { control, watch, setValue } = useFormContext<ApprovalFlow>();
  const criteria = watch('criteria');

  return (
    <>
      <Grid templateColumns="1fr 3fr" padding="xs">
        <Flex direction="column" gap="xs2">
          <Typography.Headline8 color="primary">
            Informações básicas
          </Typography.Headline8>
          <Typography.Body3 color="neutral_30">
            Adicione as informações básicas do fluxo de aprovação, e quais serão
            os critérios para ativação.
          </Typography.Body3>
        </Flex>
        <Grid templateColumns="1fr" padding="xs2" gap="xs">
          <BorderedSection padding="xs" variant="neutral">
            <Typography.Headline8 color="neutral_30">
              Informações básicas
            </Typography.Headline8>
            <Grid templateColumns="50%" gap="xs" padding="xs2">
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} label="Nome" error={!!error} required />
                )}
              />
              <Flex>
                <Controller
                  control={control}
                  name="priority"
                  defaultValue={1}
                  render={({ field: { value } }) => (
                    <SelectField
                      size="medium"
                      value={value}
                      disabled
                      fullWidth
                      options={[{ value: value, label: `${value}` }]}
                      label="Prioridade"
                    />
                  )}
                />
              </Flex>
              <Controller
                control={control}
                name="description"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Descrição"
                    required
                    multiline
                    rows={4}
                    error={!!error}
                  />
                )}
              />
            </Grid>
          </BorderedSection>
          <BorderedSection padding="xs" variant="neutral">
            <Typography.Headline8 color="neutral_30">
              Critérios de aprovação
            </Typography.Headline8>
            <Typography.Body4 weight={400} color="neutral_30">
              Critérios de aprovação Insira critérios que quando atingidos,
              acionarão o fluxo de aprovação.
            </Typography.Body4>
            <Spacer y="xs2" />
            <Grid templateColumns="1fr" gap="xs2">
              {criteria.map((c, index) => {
                return <CriteriaSelector index={index} key={index} />;
              })}
              <Flex>
                <LinkButton
                  variant="default"
                  onClick={() =>
                    setValue('criteria', [
                      ...criteria,
                      { relationshipCondition: 'AND' } as any,
                    ])
                  }
                >
                  {'Adicionar critério'}
                </LinkButton>
              </Flex>
            </Grid>
          </BorderedSection>
        </Grid>
      </Grid>
    </>
  );
}
