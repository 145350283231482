import {
  Button,
  Icons,
  LinkButton,
  Spinner,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { capitalizeWords, formatDate, toCurrency } from '@frontend/utils/masks';
import _isEmpty from 'lodash/isEmpty';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { z } from 'zod';
import { CreateDepositFormSchema } from '../data/useCreateDeposit';

type Props = {
  adminInfo?: {
    email?: string;
    name: string;
  };
  billetMetadata?: {
    debitNote?: string;
    pdfLink: string;
  };
  form: UseFormReturn<z.infer<typeof CreateDepositFormSchema>>;
  isLoading: boolean;
};

export const BilletDepositFeedback = ({
  adminInfo,
  form,
  billetMetadata,
  isLoading,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const modalController = ModalService.useModalController();

  const { amount, expirationDate } = form.getValues();

  const { pdfLink, debitNote } = billetMetadata || {};
  const { name, email } = adminInfo || {};

  const downloadLink = (link?: string) => {
    const newWindow = window.open(link);
    if (newWindow) {
      dispatchToast({
        type: 'success',
        content: 'boleto baixado com sucesso!',
      });
      newWindow.focus();
    } else {
      dispatchToast({
        type: 'error',
        content: 'Não foi possível baixar o boleto.',
        description:
          'Tente novamente. Se o problema persistir, entre em contato com o suporte',
      });
    }
  };

  const leftSideActions = [
    ...(!_isEmpty(billetMetadata)
      ? [
          {
            label: 'Baixar comprovante da solicitação',
            onClick: () => downloadLink(debitNote),
          },
        ]
      : []),
    {
      label: 'Ir para depósito',
      onClick: () => {
        modalController.remove();
        navigate('/corporateCard/transactions/deposits');
      },
    },
  ];

  return (
    <ModuleContainer gap="24px">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Icons
          name="IconCircleCheck"
          color={theme.colors.feedback.success[40]}
          fill={theme.colors.feedback.success[90]}
          size={48}
        />
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tag variant="neutral" size="md">
            {
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <Icons name={'IconBarcode'} fill="none" />
                Boleto Bancário
              </div>
            }
          </Tag>
          <Tag variant="error" size="md" dot={true}>
            {'Aguardando pagamento'}
          </Tag>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography.Headline7 color="neutral_20">
          Depósito solicitado
        </Typography.Headline7>
        {email ? (
          <Typography.Body3 color="neutral_30">
            O Boleto Bancário também será enviado para o e-mail{' '}
            <strong>{email}</strong>
          </Typography.Body3>
        ) : (
          <Typography.Body3 color="neutral_30">
            O Boleto Bancário também será enviado por e-mail
            <strong>{email}</strong>
          </Typography.Body3>
        )}
      </div>
      <div style={{ display: 'flex', gap: '48px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography.Body3 color="neutral_30">
            Valor de depósito
          </Typography.Body3>
          <Typography.Headline8 color="neutral_30">
            {toCurrency(amount)}
          </Typography.Headline8>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography.Body3 color="neutral_30">
            Vencimento do Boleto Bancário
          </Typography.Body3>
          <Typography.Headline8 color="neutral_30">
            {formatDate(expirationDate)}
          </Typography.Headline8>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography.Body3 color="neutral_30">Solicitado por</Typography.Body3>
          <Typography.Headline8 color="neutral_30">
            {capitalizeWords(name || '')}
          </Typography.Headline8>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '24px' }}>
          {leftSideActions.map((action) => (
            <LinkButton
              variant={'neutral'}
              onClick={() => action.onClick()}
              style={{ alignSelf: 'center' }}
              key={action.label}
            >
              <Typography.Body3 color="neutral_30" weight={700}>
                {action.label}
              </Typography.Body3>
            </LinkButton>
          ))}
        </div>
        <div>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 96px',
              }}
            >
              <Spinner variant="primary" size={64} />
            </div>
          ) : (
            <Button
              disabled={!pdfLink}
              size="large"
              onClick={() => downloadLink(pdfLink)}
              buttonType={'primary'}
              variant={'primary'}
              variantType="default"
            >
              Baixar boleto <Icons name="IconDownload" fill="none" />
            </Button>
          )}
        </div>
      </div>
    </ModuleContainer>
  );
};
