import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { useNavigate, useParams } from 'react-router-dom';

export function useSave() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate, isLoading } = trpc.approvalFlow.save.useMutation({
    onError: () => {
      dispatchToast({
        type: 'error',
        content: `Não foi possível ${id ? 'editar' : 'adicionar'} o fluxo de aprovação.`,
        description:
          'Tente novamente e, caso o erro persista, entre em contato com o nosso atendimento.',
      });
    },
    onSuccess: () => {
      dispatchToast({
        type: 'success',
        content: `Fluxo de aprovação ${id ? 'editado' : 'adicionado'} com sucesso!`,
      });
      navigate('/corporateCard/balance/approval-flow');
    },
  });

  return {
    save: mutate,
    isLoading,
  };
}
