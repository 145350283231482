interface ServicesProps {
  [key: string]: {
    development: string;
    staging: string;
    production: string;
  };
}

export const services = {
  bff: {
    development: 'http://localhost:2022/trpc',
    staging:
      'https://hros-web-corporate-card-backend.private.flashapp.dev/trpc',
    production:
      'https://hros-web-corporate-card-backend.us.flashapp.services/trpc',
  },
  unleash: {
    development:
      'https://unleash-benefits-proxy-development.us.flashapp.services/proxy',
    staging:
      'https://unleash-benefits-proxy-development.us.flashapp.services/proxy',
    production:
      'https://unleash-benefits-proxy-production.us.flashapp.services/proxy',
  },
} as ServicesProps;
