import Button from '@frontend/components/Button';
import Typography from '@frontend/components/Typography';
import CreatePolicy from '@frontend/pages/Policies/focusedFlows/CreatePolicy';
import ModalService from '@frontend/services/ModalService';
import * as SC from './styled';

type AddFirstPolicyProps = {
  onAddPolicyClick(): void;
};

export function AddFirstPolicy(props: AddFirstPolicyProps) {
  function handleCreatePolicy() {
    ModalService.show(CreatePolicy, {});
    props.onAddPolicyClick();
  }

  return (
    <SC.AddFistPolicyContainer>
      <Typography.Headline9 center color="neutral_20">
        Nenhuma política foi adicionada ainda.
      </Typography.Headline9>

      <Typography.Caption center color="neutral_40">
        Você pode definir regras de uso para os cartões corporativos, como
        limite de gastos e saque para cartões físicos, além de poder restringir
        dias da semana e tipos de estabelecimentos.
      </Typography.Caption>

      <Button
        variant="primary"
        onClick={handleCreatePolicy}
        size="small"
        icon="Add"
        maxWidth="fit-content"
      >
        Adicionar política de cartão
      </Button>
    </SC.AddFistPolicyContainer>
  );
}
