import Flex from '@frontend/components/frames/Flex';
import { ModalV2 } from '@frontend/components/modals/ModalV2';
import { SurveyStore } from '@frontend/components/SurveyStore';
import ModalService from '@frontend/services/ModalService';
import { Feature, VersionContent } from '../../context/types';

type Props = VersionContent & { feature: Feature };

function VersionFeedbackModal(props: Props) {
  const controller = ModalService.useModalController();

  return (
    <ModalV2
      open
      highlight={{
        description: `Nova ${props.type === 'feature' ? 'funcionalidade' : 'página'} ${props.featureName}`,
        size: 'compact',
        variant: 'announcement',
        icon: 'IconMessageChatbot',
      }}
      size="sm"
      title="Deixe sua opinião"
      onClose={() => controller.remove()}
    >
      <Flex justify="center">
        <SurveyStore
          title={props.survey.title}
          type={props.survey.type}
          params={{ feature: props.feature, ...props.survey.params }}
        />
      </Flex>
    </ModalV2>
  );
}

export default ModalService.create(VersionFeedbackModal);
