import { useState } from 'react';

export function useApprovalType() {
  const [approvalType, setApprovalType] = useState<'CUSTOM' | 'AUTO'>();

  return {
    approvalType,
    setApprovalType,
  };
}
