import { CardJSON } from '@flash-tecnologia/corporate-card-bff/src/externalServices/ExpenseManagementService/users/schemas/card.json-schema';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';
import { useEnableUser } from '../data/useEnableUser';

type Props = {
  allSelected?: boolean;
  callback?: () => void;
  filter: {
    active?: Array<'active' | 'disabled'>;
    balance?: {
      max: number;
      min: number;
    };
    behavior?: 'include' | 'exclude';
    cards?: {
      status?: Array<CardJSON['status']>;
    };
    employeeId?: Array<string>;
    stringInput?: string;
  };
  total: number;
};

function EnableUserModal({ callback, ...input }: Props) {
  const modalController = ModalService.useModalController();

  const { loading, enableUser } = useEnableUser();

  const { total, filter } = input;

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Ativar colaborador',
          onClick: () =>
            enableUser(
              {
                ...filter,
                ids: filter.employeeId,
                search: filter.stringInput,
                total: total,
              },
              () => {
                callback?.();
                modalController.remove();
              },
            ),
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção',
        title: 'O Cartão Corporativo voltará estar ativo!',
        body: 'Com o desbloqueio do Cartão Corporativo, o(s) colaborador(es) estará(ão) apto(s) para voltar a utilizar o cartão corporativo.',
      }}
      loading={loading}
    />
  );
}

export default ModalService.create(EnableUserModal);
