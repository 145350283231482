import styled from 'styled-components';

export const Container = styled.div<{ size?: 'default' | 'compact' }>`
  display: flex;
  width: fit-content;
  justify-content: center;
  gap: ${({ size, theme }) =>
    size === 'compact' ? theme.spacings.xs5 : theme.spacings.xs4};
  flex-direction: ${({ size }) => (size === 'compact' ? 'row' : 'column')};
  align-items: center;
`;
