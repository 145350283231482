import styled from 'styled-components';

export const Container = styled.div<{ useNewVersion?: boolean }>`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs}`};
  border-color: ${({ theme, useNewVersion }) =>
    useNewVersion ? theme.colors.secondary[95] : theme.colors.neutral[90]};
  border-width: 1px 0px;
  background-color: ${({ theme, useNewVersion }) =>
    useNewVersion ? theme.colors.secondary[99] : theme.colors.neutral[95]};
`;

export const ActionContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`;
