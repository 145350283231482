import { Quotation } from '@frontend/components/display/Quotation/Quotation';
import { ModalV2 } from '@frontend/components/modals/ModalV2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';

function AboutComplementaryDepositModal() {
  const modalController = ModalService.useModalController();
  return (
    <ModalV2
      title="Como funciona o depósito recorrente complementar"
      open={modalController.visible}
      onClose={modalController.remove}
      actions={{
        primary: {
          description: 'Entendi',
          onClick: modalController.remove,
          color: 'default',
        },
      }}
    >
      <Typography.Body4 color="neutral_40">
        A função complementar permite que você defina um valor mensal de
        depósito por colaborador. Com esta função ativa, na data definida por
        você, iremos consultar o saldo atual do colaborador e complementar o
        saldo existente, garantindo que o total não ultrapasse o valor
        estabelecido.
      </Typography.Body4>
      <Spacer y="xs1" />
      <Typography.Body4 color="neutral_40" weight={700}>
        Por exemplo:
      </Typography.Body4>
      <Spacer y="xs1" />
      <Quotation color="primary">
        <Typography.Body4 color="neutral_40">
          Se você definiu um depósito de{' '}
          <Typography.Body4 color="neutral_40" weight={700} as="b">
            R$1.000,00
          </Typography.Body4>{' '}
          que será disponibilizado todo dia 30 para alguém e, chegando o dia 30,
          essa pessoa ainda tiver{' '}
          <Typography.Body4 color="neutral_40" weight={700} as="b">
            R$300,00
          </Typography.Body4>{' '}
          de saldo corporativo no seu cartão, o depósito automático será de{' '}
          <Typography.Body4 color="neutral_40" weight={700} as="b">
            R$700,00
          </Typography.Body4>
          . Esse valor é calculado para complementar o saldo atual e atingir o
          valor de R$1.000,00.
        </Typography.Body4>
        <Spacer y="xs1" />
        <Typography.Body4 color="neutral_40">
          Agora, considerando que a{' '}
          <Typography.Body4 color="neutral_40" weight={700} as="b">
            soma
          </Typography.Body4>{' '}
          de todos os saldos definidos seja de{' '}
          <Typography.Body4 color="neutral_40" weight={700} as="b">
            R$7.000,00
          </Typography.Body4>
          , este se torna o valor total que pode ser retirado do Flash Cash
          Corporativo por mês para os colaboradores listados. Porém, o valor
          total exato a ser retirado da carteira corporativa no mês vai depender
          do saldo que cada colaborador listado tiver no dia da
          disponibilização.
        </Typography.Body4>
      </Quotation>
    </ModalV2>
  );
}

export const MODAL_AboutComplementaryDeposit = ModalService.create(
  AboutComplementaryDepositModal,
);
