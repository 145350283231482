import Typography from '../../Typography';
import * as SC from './styled';

type CardSelectBodyProps = {
  disabled?: boolean;
  selected: boolean;
  title: React.ReactNode;
  description: string;
};

export default function CardSelectBody(props: CardSelectBodyProps) {
  return (
    <SC.BodyContainer>
      <Typography.Body4
        color={
          props.disabled
            ? 'neutral_60'
            : props.selected
              ? 'secondary_50'
              : 'neutral_20'
        }
        weight={700}
        center
      >
        {props.title}
      </Typography.Body4>

      <Typography.Caption
        color={props.disabled ? 'neutral_50' : 'neutral_30'}
        center
      >
        {props.description}
      </Typography.Caption>
    </SC.BodyContainer>
  );
}
