import {
  PillButton,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Typography from '@frontend/components/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { ApprovalFlow } from '../../form/schema';
import { basicComparisonList, comparisonList } from './compararisonList';
import { SelectDepartment } from './SelectDepartment';
import { SelectRole } from './SelectRole';
import { StyledFlex } from './styled';
type props = {
  index: number;
};
export function CriteriaSelector({ index }: props) {
  const { control, watch, setValue } = useFormContext<ApprovalFlow>();

  const type = watch(`criteria.${index}.type`);

  const deleteCriteria = () => {
    setValue(
      'criteria',
      watch('criteria').filter((_, criteriaIndex) => criteriaIndex !== index),
    );
  };

  return (
    <Grid
      templateColumns={`120px repeat(3, 1fr) 50px`}
      align="center"
      gap="xs2"
      justify="end"
    >
      <Flex>
        <Typography.Body3 color="neutral_30">
          {index === 0 ? `Quando` : `e`}
        </Typography.Body3>
      </Flex>
      <Controller
        control={control}
        name={`criteria.${index}.type`}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectField
            fullWidth
            error={!!error}
            onSelectChange={(_, option) => onChange(option?.value)}
            label={`Condicional 1`}
            value={value}
            options={[
              { value: 'BALANCE_AMOUNT', label: 'Valor' },
              { value: 'DEPARTMENT', label: 'Departamento' },
              { value: 'ROLE', label: 'Cargo' },
            ]}
          />
        )}
      />
      {type === 'DEPARTMENT' && (
        <>
          <Controller
            control={control}
            name={`criteria.${index}.operator`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                fullWidth
                error={!!error}
                value={value}
                onSelectChange={(_, option) => onChange(option?.value)}
                label={`Condicional 2`}
                options={basicComparisonList}
              />
            )}
          />
          <Controller
            control={control}
            name={`criteria.${index}.value`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectDepartment
                error={!!error}
                label={`Condicional 3`}
                value={value}
                onSelectChange={(_, option) => {
                  onChange(option?.value);
                }}
              />
            )}
          />
        </>
      )}
      {type === 'ROLE' && (
        <>
          <Controller
            control={control}
            name={`criteria.${index}.operator`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                fullWidth
                error={!!error}
                value={value}
                onSelectChange={(_, option) => onChange(option?.value)}
                label={`Condicional 2`}
                options={basicComparisonList}
              />
            )}
          />
          <Controller
            control={control}
            name={`criteria.${index}.value`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectRole
                label={`Condicional 3`}
                fullWidth
                error={!!error}
                value={value}
                onSelectChange={(_, option) => {
                  onChange(option?.value);
                }}
              />
            )}
          />
        </>
      )}
      {type === 'BALANCE_AMOUNT' && (
        <>
          <Controller
            control={control}
            name={`criteria.${index}.operator`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                fullWidth
                error={!!error}
                value={value}
                onSelectChange={(_, option) => onChange(option?.value)}
                label={`Condicional 2`}
                options={comparisonList}
              />
            )}
          />
          <Controller
            control={control}
            name={`criteria.${index}.value`}
            defaultValue={''}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                fullWidth
                error={!!error}
                onChange={onChange}
                imaskProps={{
                  mask: Number,
                  thousandsSeparator: '.',
                  padFractionalZeros: true,
                  scale: 2,
                  radix: ',',
                }}
                label={`Condicional 3`}
              />
            )}
          />
        </>
      )}
      {index > 0 && (
        <StyledFlex>
          <PillButton
            variant="default"
            size="medium"
            icon="IconTrash"
            onClick={deleteCriteria}
          />
        </StyledFlex>
      )}
    </Grid>
  );
}
