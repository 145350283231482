import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import Typography from '../Typography';
import * as SC from './styled';

type NextStepCardProps = {
  title: string;
  description: string;
  icon: IconTypes;
  action?: ButtonProps;
  width?: string;
  height?: string;
};

export default function NextStepCard(props: NextStepCardProps) {
  return (
    <SC.Container width={props.width} height={props.height}>
      <SC.HeaderContainer>
        <Icon
          name={props.icon}
          color="secondary_50"
          background="default"
          size={24}
        />

        <Typography.Body3 weight={600} color="neutral_30">
          {props.title}
        </Typography.Body3>
      </SC.HeaderContainer>

      <Typography.Body4 color="neutral_40">
        {props.description}
      </Typography.Body4>

      {props.action && <Button size="small" {...props.action} />}
    </SC.Container>
  );
}
