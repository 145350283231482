import { ModalV2 } from '@frontend/components/modals/ModalV2';
import { SurveyStore } from '@frontend/components/SurveyStore';

type Props = {
  open: boolean;
  onClose(): void;
};
export default function Feedback({ open, onClose }: Props) {
  return (
    <ModalV2
      open={open}
      highlight={{
        description: `Configuração do fluxo de aprovação de saldo`,
        size: 'compact',
        variant: 'announcement',
        icon: 'IconMessageChatbot',
      }}
      size="sm"
      title="Deixe sua opinião"
      onClose={onClose}
    >
      <SurveyStore
        title="Como você avalia a facilidade de uso ao configurar um fluxo de aprovação para pedidos de saldo?"
        type="approvals-feedback"
      />
    </ModalV2>
  );
}
