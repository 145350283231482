import { Radio } from '@flash-tecnologia/hros-web-ui-v2';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Typography from '@frontend/components/Typography';

type Props = {
  title: string;
  description: string;
  checked: boolean;
  setType(): void;
  children?: React.ReactNode;
};
export function ApprovalType({
  title,
  description,
  checked,
  setType,
  children,
}: Props) {
  return (
    <BorderedSection padding="xs" variant="neutral">
      <Grid templateColumns="auto 1fr" align="center" gap="xs2">
        <Radio checked={checked} onChange={setType} />
        <Flex direction="column">
          <Typography.Headline8 color="neutral_30">
            {title}
          </Typography.Headline8>
          <Typography.Body4>{description}</Typography.Body4>
        </Flex>
      </Grid>
      {children}
    </BorderedSection>
  );
}
