import { CardJSON } from '@flash-tecnologia/corporate-card-bff/src/externalServices/ExpenseManagementService/users/schemas/card.json-schema';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';
import { useDisableUser } from '../data/useDisableUser';

type Props = {
  allSelected?: boolean;
  callback?: () => void;
  filter: {
    active?: Array<'active' | 'disabled'>;
    balance?: {
      max: number;
      min: number;
    };
    behavior?: 'include' | 'exclude';
    cards?: {
      status?: Array<CardJSON['status']>;
    };
    employeeId?: Array<string>;
    stringInput?: string;
  };
  total: number;
};

function DisableUserModal({ callback, ...input }: Props) {
  const modalController = ModalService.useModalController();

  const { loading, disableUser } = useDisableUser();

  const { total, filter } = input;

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Desativar uso corporativo',
          onClick: () =>
            disableUser(
              {
                ...filter,
                ids: filter.employeeId,
                search: filter.stringInput,
                total: total,
              },
              () => {
                callback?.();
                modalController.remove();
              },
            ),
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
      confirmationWord="DESATIVAR"
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja desativar o uso corporativo?',
        body: 'Os cartões serão desativados e o saldo disponibilizado para a pessoa voltará para a carteira corporativa. Você poderá reativar o uso corporativo dela a qualquer momento.',
      }}
      loading={loading}
    />
  );
}

export default ModalService.create(DisableUserModal);
