import Icon from '@frontend/components/Icon';
import { ModalV2 } from '@frontend/components/modals/ModalV2';
import Spacer from '@frontend/components/Spacer';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { toCurrency } from '@frontend/utils/masks';
import React from 'react';
import { z } from 'zod';
import { DepositFormSchema } from '../../../../../controllers/form/schema';
import { UseFormReturn } from '../../../../../controllers/form/useForm';

type Props = {
  form: UseFormReturn;
};

function SummaryModal(props: Props) {
  const controller = ModalService.useModalController();
  const deposits = props.form.getValues().deposits;

  return (
    <ModalV2
      title="Detalhes do pedido"
      open={controller.visible}
      onClose={controller.remove}
    >
      <Spacer y={'s'} />
      <TableClient
        emptyState={{
          emptyText: 'Nenhuma pessoa selecionada',
          filteredEmptyText: '',
        }}
        loading={false}
        columns={columns}
        data={deposits}
        enablePagination
        pageSizeOptions={[{ label: '5', value: 5 }]}
      />
    </ModalV2>
  );
}

export default ModalService.create(SummaryModal);

type Row = z.infer<typeof DepositFormSchema>['deposits'][0];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

const columns = [
  {
    accessorKey: 'name',
    header: 'Pessoa',
    cell: (context) => {
      return (
        <>
          <Typography.Body3 color="neutral_40">
            {context.row.original.name}
          </Typography.Body3>
          <div style={{ display: 'flex', gap: '4px' }}>
            <Icon size={16} name="IconId" color="neutral_70" />
            <Typography.Body4 color="neutral_50">
              {context.row.original.documentNumber}
            </Typography.Body4>
          </div>
        </>
      );
    },
  },
  {
    accessorKey: 'amount',
    header: 'Valor do depósito',
    enableSorting: false,
    cell: (context) => {
      return (
        <Typography.Body3 color="neutral_40">
          {toCurrency(context.row.original.value)}
        </Typography.Body3>
      );
    },
  },
] satisfies Columns;
