import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';

export enum PeriodTypeEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export const PolicyLimit = z.object({
  /** Allowed expense amount for the `period` */
  amount: z
    .number({
      required_error: errors.forms.generic.required,
      invalid_type_error: errors.forms.generic.required,
    })
    .int(errors.forms.generic.required)
    .positive('Valor deve ser maior que R$ 0,00'),
  /** Period for resetting the amount spent that is limited by `amount` */
  period: z.nativeEnum(PeriodTypeEnum, {
    required_error: errors.forms.generic.required,
    invalid_type_error: errors.forms.generic.required,
  }),
});

const PolicySchema = z.object({
  // Identification
  /** Title of the policy, defined by the admin */
  title: z
    .string({ required_error: errors.forms.generic.required })
    .min(1, errors.forms.generic.required)
    .max(64, 'O título deve ter no máximo 64 caracteres'),
  /** Additional description of the policy, defined by the admin */
  description: z
    .string({ required_error: errors.forms.generic.required })
    .min(1, errors.forms.generic.required)
    .max(255, 'A descrição deve ter no máximo 255 caracteres'),

  // Usage rules
  /** Used only to check if `limit` is required */
  limitEnabled: z.boolean(),
  /** Whether the user can withdraw money. Only works with plastic cards */
  withdrawEnabled: z.boolean(),
  /** Days when the user can make payments with the card */
  weekdaysEnabled: z
    .object({
      SUNDAY: z.boolean(),
      MONDAY: z.boolean(),
      TUESDAY: z.boolean(),
      WEDNESDAY: z.boolean(),
      THURSDAY: z.boolean(),
      FRIDAY: z.boolean(),
      SATURDAY: z.boolean(),
    })
    .refine(
      (weekdaysEnabled) => {
        return Object.values(weekdaysEnabled).some((value) => value);
      },
      {
        message: 'É necessário habilitar o uso em pelo menos um dia da semana',
      },
    ),
  /** Allowed MCC groups for payments */
  mccGroups: z
    .object({
      CONVENIENCE: z.boolean().catch(false),
      CULTURE: z.boolean().catch(false),
      EDUCATION: z.boolean().catch(false),
      GROCERY: z.boolean().catch(false),
      HEALTH: z.boolean().catch(false),
      MEAL: z.boolean().catch(false),
      MOBILITY: z.boolean().catch(false),
    })
    .refine(
      (mccGroups) => {
        return Object.values(mccGroups).some((value) => value);
      },
      {
        message: 'É necessário habilitar o uso em pelo menos uma categoria',
      },
    ),
});

const WITHDRAW_MAX_AMOUNT = 2000000;

const PolicyWithMccLimitsSchema = PolicySchema.merge(
  z.object({
    /** Policy limits per category */
    mccLimits: z.object({
      CONVENIENCE: PolicyLimit.nullish(),
      CULTURE: PolicyLimit.nullish(),
      EDUCATION: PolicyLimit.nullish(),
      GROCERY: PolicyLimit.nullish(),
      HEALTH: PolicyLimit.nullish(),
      MEAL: PolicyLimit.nullish(),
      MOBILITY: PolicyLimit.nullish(),
      WITHDRAW: z
        .object({
          /** Allowed expense amount for the `period` */
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00')
            .max(
              WITHDRAW_MAX_AMOUNT,
              'O limite não pode ser superior ao limite da empresa.',
            ),
          /** Period for resetting the amount spent that is limited by `amount` */
          period: z.nativeEnum(PeriodTypeEnum, {
            required_error: errors.forms.generic.required,
            invalid_type_error: errors.forms.generic.required,
          }),
        })
        .nullish(),
    }),
    limitType: z.literal('BY_CATEGORY'),
  }),
);

const PolicyWithLimitSchema = PolicySchema.merge(
  z.object({
    limitType: z.literal('GENERAL'),
    limit: PolicyLimit,
  }),
);

const PolicyWithoutLimitSchema = PolicySchema.merge(
  z.object({
    limitType: z.literal('UNLIMITED'),
  }),
);

export const FormSchema = z.discriminatedUnion('limitType', [
  PolicyWithMccLimitsSchema,
  PolicyWithLimitSchema,
  PolicyWithoutLimitSchema,
]);

export type PolicyWithMccLimits = z.infer<typeof PolicyWithMccLimitsSchema>;
export type PolicyWithLimit = z.infer<typeof PolicyWithLimitSchema>;
export type PolicyWithoutLimit = z.infer<typeof PolicyWithoutLimitSchema>;
