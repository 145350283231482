import { trpc } from '@frontend/trpc';

export function useCreateOrder() {
  const { mutateAsync, data } = trpc.company.orderV2.useMutation();

  return {
    data,
    mutateAsync,
  };
}
