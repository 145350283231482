import useColors, { ColorOptions } from '@frontend/utils/theme/colors';
import styled from 'styled-components';

type Props = {
  color: (typeof ColorOptions)[number];
  children: React.ReactNode;
};

export function Quotation(props: Props) {
  const colors = useColors();
  return <LeftBlock color={colors[props.color]}>{props.children}</LeftBlock>;
}

const LeftBlock = styled.div<{ color: string }>`
  border-left: 2px solid ${(p) => p.color};
  padding-left: ${(p) => p.theme.spacings.xs3};
`;
