import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { useContext } from 'react';
import { Feature, UseVersionReturn } from './types';
import { VersionContext } from './VersionContext';

/**
 * Get if version is active to show feature
 */
export function useVersion(feature: Feature): UseVersionReturn {
  const isEnabled = FeatureFlagService.getFlag(feature);

  const context = useContext(VersionContext);

  function onChangeVersion() {
    context.onChangeVersion(feature);
  }

  function onShowSurveyModal() {
    context.onShowSurveyModal(feature);
  }
  return {
    showCallout: isEnabled,
    isNewVersion: context.isNewVersion(feature),
    onChangeVersion,
    onShowSurveyModal,
  };
}
