import { trpc } from '@frontend/trpc';
import { QUERY_RETRY_CONFIG } from '@frontend/utils/constants';

type Input = {
  orderId?: string;
};

export default function useGetOrder(input: Input) {
  const order = trpc.company.billing.paymentInfo.useQuery(input.orderId ?? '', {
    enabled: !!input.orderId,
    ...QUERY_RETRY_CONFIG,
  });

  return {
    reportUrl: order.data?.debitNote,
    pixCode: order.data?.pixCode,
    pdfUrl: order.data?.pdfLink,
    isLoading: order.isFetching,
    isError: order.isError,
    refetch: order.refetch,
  };
}
