import LazyComponent from '@frontend/components/LazyComponent';

export { RequestBalanceTab } from '@frontend/pages/Management/tabs/RequestBalanceSection';
export { CustomizationsTab } from './Customizations';
export { ExternalRoot } from './Root';
export {
  showAddFlashCashModal,
  showCancelAutomaticCreditModal,
  showEditAutomaticCreditModal,
} from './StatementSection';

export function DashboardSection() {
  return <LazyComponent factory={() => import('./DashboardSection')} />;
}

export function ReportSection() {
  return <LazyComponent factory={() => import('./ReportsSection')} />;
}

export function ReportSectionTabLabel() {
  return <LazyComponent factory={() => import('./ReportsSectionTabLabel')} />;
}

export function ReportNewsBanner() {
  return (
    <LazyComponent
      factory={() => import('./ReportsSection/components/ReportNewsBanner')}
    />
  );
}

export function CardsSection() {
  return (
    <LazyComponent factory={() => import('./CardsSection/CardsSection')} />
  );
}
