import { Checkbox, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { Divider } from '@frontend/components/frames/Divider/Divider';
import Flex from '@frontend/components/frames/Flex';
import InputErrorMessage from '@frontend/components/inputs/_primitives/InputErrorMessage';
import { InfiniteScrollList } from '@frontend/components/lists/InfiniteScrollList/InfiniteScrollList';
import Typography from '@frontend/components/Typography';
import { useComboBoxFieldController } from '../../useComboBoxFieldController';

type Props = {
  controller: ReturnType<typeof useComboBoxFieldController>;
};

export function ListDefault(props: Props) {
  return (
    <>
      <Flex padding={['xs4', 'xs3']} align="center">
        <Checkbox
          disabled={Boolean(props.controller.warnings.warnAllOptionsDisabled)}
          checked={props.controller.selection.allSelected}
          onChange={(_, checked) =>
            props.controller.selection.handleToggleAll(checked)
          }
        />
        {props.controller.warnings.warnAllOptionsDisabled ? (
          <Flex direction="column">
            <Typography.Body4 color="neutral_60" weight={600}>
              Selecionar tudo
            </Typography.Body4>
            <InputErrorMessage
              error={props.controller.warnings.warnAllOptionsDisabled}
              noPadding
            />
          </Flex>
        ) : (
          <Typography.Body4 color="neutral_20" weight={600}>
            Selecionar tudo
          </Typography.Body4>
        )}
      </Flex>
      <Divider direction="horizontal" />
      <Flex direction="column" padding={'xs4'} gap="xs5">
        <InfiniteScrollList
          length={props.controller.filter.filteredPaginatedOptions.length}
          next={props.controller.filter.nextPage}
          loader={
            <Flex padding="xs5" justify="center" align="center">
              <Spinner variant="primary" size={24} />
            </Flex>
          }
          hasMore={props.controller.filter.hasMore}
          height={260}
        >
          {props.controller.filter.filteredPaginatedOptions.map((option) => (
            <Flex
              padding={['xs4', 'xs3']}
              align="center"
              backgroundColor={
                props.controller.selection.selectedOptions.has(option.value)
                  ? 'secondary_99'
                  : 'neutral_100'
              }
              radius="s"
            >
              <Checkbox
                checked={props.controller.selection.selectedOptions.has(
                  option.value,
                )}
                onChange={(_, checked) =>
                  props.controller.selection.handleToggleOption(
                    option.value,
                    checked,
                  )
                }
                disabled={option.disabled}
              />
              <Flex direction="column" align="start">
                <Typography.Body4
                  color={option.disabled ? 'neutral_60' : 'neutral_20'}
                  weight={600}
                >
                  {option.title}
                </Typography.Body4>
                <Typography.Caption color="neutral_40">
                  {option.description}
                </Typography.Caption>
              </Flex>
            </Flex>
          ))}
        </InfiniteScrollList>
      </Flex>
    </>
  );
}
