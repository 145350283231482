import {
  BoxAdornment,
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
  PillButton,
  TagDateFilter,
  TagSliderRangeFilter,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { useNavigate, useParams } from 'react-router-dom';
import Spacer from '../../../components/Spacer';
import Table from '../../../components/TableV2';
import { toCurrency } from '../../../utils/masks';
import DownloadEmployeeStatement from '../FlashCard/modals/DownloadEmployeeStatement';
import { useEmployeeActions } from './data/useEmployeeActions';
import { useEmployeeBalance } from './data/useEmployeeInfo';
import { useTableData } from './data/useTableData';
import * as styled from './styled';
import {
  initialPagination,
  pageSizeOptions,
  statementTableColumns,
} from './tableSetup';

export default function EmployeeStatement() {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const { data, loading } = useEmployeeBalance(employeeId as string);
  const {
    getStatement,
    getStatementLoading,
    isFiltered,
    pagination,
    setFilters,
    setPagination,
    value,
  } = useTableData(employeeId as string);

  const isLoading = loading || getStatementLoading;
  const { fetchActions, actions: employeeActions } = useEmployeeActions({
    documentNumber: data.documentNumber,
    nanoId: data.employeeNanoId,
    name: data.name,
    email: data.email,
    automaticCredit: {},
    balance: data.balanceAmount,
  });

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              onClick={() => {
                navigate('/corporateCard/home', { replace: true });
              }}
              variant="neutral"
            >
              Gerenciar cartão
            </LinkButton>,
            <LinkButton variant="neutral" disabled>
              Detalhes do Cartão
            </LinkButton>,
          ]}
          separator={<Icons name="IconChevronRight" fill="#ffffff" />}
        />
      </PageHeader>
      <PageContainer>
        {!loading && (
          <>
            <styled.Accordion
              title={data.name}
              status={data.active}
              options={employeeActions}
              onClick={fetchActions}
              description={
                <styled.Line>
                  {data.cardNumber && (
                    <>
                      •&nbsp;
                      <Icons
                        fill="transparent"
                        name="IconCreditCard"
                        size={15}
                      />
                      &nbsp;{data.cardNumber} &nbsp;
                    </>
                  )}
                  {data.email && (
                    <>
                      •&nbsp;
                      <Icons fill="transparent" name="IconMail" size={15} />
                      &nbsp;{data.email}
                    </>
                  )}
                </styled.Line>
              }
              leftAdornment={
                <styled.StyledIcon>{data.name[0]}</styled.StyledIcon>
              }
            />
            <Spacer y="s" />
            <BoxAdornment
              style={{ width: '33%', paddingLeft: '0px' }}
              title={
                <styled.Line>
                  <Typography.Body4>Saldo atual</Typography.Body4>
                </styled.Line>
              }
              description={
                <Typography.Headline8>{data.balance}</Typography.Headline8>
              }
              leftAdornment=""
            />
          </>
        )}
        <Spacer y="s" />
        <styled.SpaceBetween>
          <Typography.Headline8>Extrato</Typography.Headline8>
          <Tooltip title="Download extrato">
            <PillButton
              icon="IconFileDownload"
              variant="default"
              onClick={() => {
                ModalService.show(DownloadEmployeeStatement, {
                  employeeId: employeeId ?? '',
                });
              }}
              size="small"
            />
          </Tooltip>
        </styled.SpaceBetween>
        <Spacer y="xs" useMargin />
        <Table
          initialState={{
            pagination: {
              pageIndex: initialPagination.currentPage,
              pageSize: initialPagination.pageSize,
            },
          }}
          loading={isLoading}
          emptyState={{
            isFiltered,
            emptyText: 'Colaborador ainda não possui nenhuma transação',
            filteredEmptyText: 'Não encontramos nenhuma transação',
          }}
          onPaginationChange={({ pageIndex, pageSize }) => {
            setPagination({
              currentPage: pageIndex,
              pageSize,
            });
          }}
          pageSizeOptions={pageSizeOptions}
          columns={statementTableColumns()}
          data={getStatement.transactions}
          pageCount={getStatement.pagination.totalPages}
          pagination={{
            pageIndex: pagination.currentPage,
            pageSize: pagination.pageSize,
          }}
          TableFilters={
            <>
              <TagDateFilter
                variant="tertiary"
                filterLabel="Data"
                hasLeftIcon={false}
                onSubmit={(dates) => {
                  setFilters((filters) => {
                    if (dates.from && dates.to) {
                      filters['date'] = dates;
                    } else {
                      delete filters['date'];
                    }
                    return {
                      ...filters,
                    };
                  });
                }}
              />
              {/* <TagCheckboxFilter
                variant="tertiary"
                filterLabel="Moeda"
                onClick={() => {}}
              /> */}
              <TagSliderRangeFilter
                variant="tertiary"
                filterLabel="Valor"
                hasLeftIcon={false}
                disabled={value?.max === value?.min}
                step={10}
                fieldMask={(number) => toCurrency(number)}
                max={value?.max}
                min={value?.min}
                onApply={(values) => {
                  const [min, max] = values;
                  setFilters((filters) => ({
                    ...filters,
                    value: {
                      max: max !== undefined ? max : value.max,
                      min: min !== undefined ? min : value.min,
                    },
                  }));
                }}
              />
            </>
          }
        />
        <Spacer y="l" />
        <Spacer y="m" />
      </PageContainer>
    </>
  );
}
