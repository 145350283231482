import LazyComponent from '@frontend/components/LazyComponent';
import RouterService from '@frontend/services/RouterService';
import { PageMetadata } from '@frontend/utils/types/pageMetadata';
import { Route } from 'react-router-dom';
import { RouteComponent_RequestBalance } from '../request-balance';

export const ROUTE_UserAccounts = RouterService.create(
  'corporateCard/user-accounts',
);

const ListAccounts = () => (
  <LazyComponent factory={() => import('./components/AccountsList')} />
);

export const UserAccounts = () => (
  <LazyComponent factory={() => import('./UserAccounts')} />
);

export const METADATA_UserAccounts = {
  title: 'Saldos e cartões corporativos',
} as const satisfies PageMetadata;

export const RouteComponent_UserAccounts = (
  <Route index path={ROUTE_UserAccounts.path} element={<ListAccounts />} />
);

export const UserAccountsPage = (
  <Route path={ROUTE_UserAccounts.path} element={<UserAccounts />}>
    {RouteComponent_UserAccounts}

    {RouteComponent_RequestBalance}
  </Route>
);
