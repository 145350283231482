import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { METADATA_UserAccounts, ROUTE_UserAccounts } from '../..';
import { useFlowController } from './controllers/useFlowController';

function DepositFromSpreadsheet() {
  const flowController = useFlowController();
  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            text: METADATA_UserAccounts.title,
            link: ROUTE_UserAccounts.buildPath({}),
          },
          {
            text: 'Depósito via planilha',
          },
        ],
      }}
      content={flowController.content}
      footer={{
        leftActions: flowController.leftButtons,
        rightActions: flowController.rightButtons,
      }}
    />
  );
}

export const MODAL_DepositFromSpreadsheet = ModalService.create(
  DepositFromSpreadsheet,
);
