import { Divider } from '@flash-tecnologia/hros-web-ui-v2';
import { Template } from '@frontend/components/FocusedFlow/Template';
import Callout from '@frontend/components/frames/Callout';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import {
  sortedWeekdays,
  translatedWeekdays,
} from '@frontend/utils/dataFormatters/weekdays.dataFormatter';
import { toCurrency } from '@frontend/utils/masks';
import { translatedPeriodTypes } from '../../../../shared/mappers';
import {
  PolicyWithLimit,
  PolicyWithMccLimits,
  PolicyWithoutLimit,
} from '../../forms/schema';
import { UseFormReturn } from '../../forms/useForm';
import { CategoryTitleContainer, SpacerContainer } from './styled';

type Props = {
  form: UseFormReturn;
};

export default function Confirmation(props: Props) {
  const policy: PolicyWithMccLimits | PolicyWithLimit | PolicyWithoutLimit =
    props.form.getValues();

  const hasLimit = !!policy.limitType;
  const limitByCategory: PolicyWithMccLimits['mccLimits'] =
    policy.limitType === 'BY_CATEGORY'
      ? policy.mccLimits
      : Object.keys(policy.mccGroups).reduce(
          (acc, mcc: keyof typeof translatedMccGroups) => {
            return {
              ...acc,
              ...(policy.mccGroups[mcc] && policy.limitType === 'GENERAL'
                ? { [mcc]: policy.limit }
                : {}),
            };
          },
          {},
        );

  if (policy.withdrawEnabled && policy.limitType === 'GENERAL') {
    limitByCategory['WITHDRAW'] = policy.limit;
  }

  return (
    <Template
      pageInfo={{
        title: 'Adicionar política de uso do cartão corporativo',
      }}
      contentInfo={{
        title: 'Resumo',
        description:
          'Revise as informações de sua política. Só prossiga depois de confirmar as definições. Caso necessário, retorne para a edição.',
      }}
      Modules={
        <>
          <Callout title="Informações básicas">
            <Typography.Caption>Nome da política</Typography.Caption>
            <Typography.Body3>{policy.title}</Typography.Body3>
            <Spacer y={'xs1'} />
            <Typography.Caption>Descrição</Typography.Caption>
            <Typography.Body3>{policy.description}</Typography.Body3>
          </Callout>
          <Spacer y="xs" />
          {policy && 'limit' in policy && policy.limit && (
            <Callout
              title="Limite de gastos habilitado"
              description="Valor máximo que um cartão pode utilizar em um período"
              leftAdornment={
                <Icon
                  name="IconCoin"
                  color="success_40"
                  background="success"
                  size={48}
                />
              }
              alignItems="start"
            >
              <Flex gap="xs">
                <Flex direction="column" align="start">
                  <Typography.Caption>Limite</Typography.Caption>
                  <Typography.Body3>
                    {toCurrency(policy.limit.amount)}
                  </Typography.Body3>
                </Flex>
                <Flex direction="column" align="start">
                  <Typography.Caption>Período</Typography.Caption>
                  <Typography.Body3>
                    {translatedPeriodTypes[policy.limit.period]}
                  </Typography.Body3>
                </Flex>
              </Flex>
            </Callout>
          )}
          {policy && !('limit' in policy) && !('mccLimits' in policy) && (
            <Callout
              title="Limite de gastos desabilitado"
              description="Os titulares dos cartões poderão utilizar a qualquer momento todo o saldo corporativo disponibilizado."
              leftAdornment={
                <Icon
                  name="IconCoinOff"
                  color="error_40"
                  background="error"
                  size={48}
                />
              }
            />
          )}
          <Spacer y="xs" />
          {policy.withdrawEnabled ? (
            <Callout
              title="Saque no cartão físico habilitado"
              description="Limite de 2 saques por dia para o colaborador e limite de R$ 20.000,00 por dia para toda a empresa. Para cada saque, haverá uma taxa de operação de R$ 5,99."
              leftAdornment={
                <Icon
                  name="IconCash"
                  color="success_40"
                  background="success"
                  size={48}
                />
              }
            >
              {hasLimit && limitByCategory['WITHDRAW'] && (
                <Flex gap="xs">
                  <Typography.Body3>
                    Limite {periodMap[limitByCategory['WITHDRAW'].period]}
                  </Typography.Body3>
                  <Typography.Body3>
                    {toCurrency(limitByCategory['WITHDRAW'].amount)}
                  </Typography.Body3>
                </Flex>
              )}
            </Callout>
          ) : (
            <Callout
              title="Saque no cartão físico desabilitado"
              description="Os titulares dos cartões não poderão realizar saques do saldo corporativo em caixas eletrônicos."
              leftAdornment={
                <Icon
                  name="IconCashOff"
                  color="error_40"
                  background="error"
                  size={48}
                />
              }
            />
          )}
          <Spacer y="xs" />
          <Callout
            title="Dias de uso"
            description="Dias da semana em que transações com esse cartão estão permitidas."
            leftAdornment={
              <Icon
                name="IconCalendar"
                size={48}
                color="secondary_50"
                background="default"
              />
            }
          >
            <Flex wrap="wrap" gap="xs4">
              {sortedWeekdays
                .filter((weekday) => policy.weekdaysEnabled[weekday])
                .map((weekday) => (
                  <Tag size="sm" variant="neutral" key={weekday}>
                    {translatedWeekdays[weekday]}
                  </Tag>
                ))}
            </Flex>
          </Callout>
          <Spacer y="xs" />{' '}
          {policy && policy.limitType === 'BY_CATEGORY' && (
            <Callout
              title="Categorias e limites de uso"
              description="Valor máximo que um cartão pode utilizar em um período conforme as categorias ativas na política."
              leftAdornment={
                <Icon
                  name="IconCoin"
                  size={48}
                  color="success_40"
                  background="success"
                />
              }
            >
              <Flex wrap="wrap" direction="column">
                {sortedMccGroups
                  .filter((mccGroup) => policy.mccGroups[mccGroup])
                  .map((mccGroup: keyof typeof translatedMccGroups, index) => {
                    const category = translatedMccGroups[mccGroup];
                    const categoryLimit =
                      limitByCategory && limitByCategory[mccGroup];

                    return (
                      <>
                        {index !== 0 && (
                          <SpacerContainer>
                            <Spacer y="xs3" />
                            <Divider orientation="horizontal" />
                            <Spacer y="xs3" />
                          </SpacerContainer>
                        )}
                        <Flex gap="xs" align="center">
                          <CategoryTitleContainer>
                            <Typography.Body3 color="neutral_30" weight={600}>
                              {category.title}
                            </Typography.Body3>
                          </CategoryTitleContainer>
                          {!!categoryLimit &&
                            !!categoryLimit.amount &&
                            !!categoryLimit.period && (
                              <>
                                <CategoryTitleContainer>
                                  <Typography.Body3
                                    color="neutral_30"
                                    weight={600}
                                  >
                                    {toCurrency(categoryLimit.amount)}
                                  </Typography.Body3>
                                </CategoryTitleContainer>
                                <Tag variant="neutral" size="md">
                                  {periodMap[categoryLimit.period]}
                                </Tag>
                              </>
                            )}
                        </Flex>
                      </>
                    );
                  })}
              </Flex>
            </Callout>
          )}
          {policy && policy.limitType !== 'BY_CATEGORY' && (
            <Callout
              title="Tipos de estabelecimento"
              description="Categorias nas quais transações feitas com esse cartão serão aceitas."
              leftAdornment={
                <Icon
                  name="IconBuildingStore"
                  size={48}
                  color="secondary_50"
                  background="default"
                />
              }
            >
              <Flex wrap="wrap" direction="column">
                {sortedMccGroups
                  .filter((mccGroup) => policy.mccGroups[mccGroup])
                  .map((mccGroup, index) => (
                    <>
                      {index !== 0 && (
                        <div style={{ width: '100%' }}>
                          <Spacer y="xs3" />
                          <Divider orientation="horizontal" />
                          <Spacer y="xs3" />
                        </div>
                      )}
                      <Typography.Body3 color="neutral_30" weight={600}>
                        {translatedMccGroups[mccGroup].title}
                      </Typography.Body3>
                      <Typography.Body4 color="neutral_40">
                        {translatedMccGroups[mccGroup].description}
                      </Typography.Body4>
                    </>
                  ))}
              </Flex>
            </Callout>
          )}
          <Spacer y="s" />
        </>
      }
    />
  );
}

const periodMap = {
  DAY: 'diário',
  WEEK: 'semanal',
  MONTH: 'mensal',
};
