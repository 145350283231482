import { trpc } from '@frontend/trpc';
import { toasts } from '../toasts';

type Input = {
  cardId: string;
  policyId: string;
  onSuccess: () => void;
};

export default function useLinkPolicy() {
  const context = trpc.useContext();
  const linkPolicy = trpc.company.policies.cardLinks.patch.useMutation();
  return {
    mutateAsync: async (input: Input) => {
      return linkPolicy
        .mutateAsync({
          policyId: input.policyId,
          linkCardIds: [input.cardId],
        })
        .then(() => {
          context.company.cards.search.refetch();
          toasts.toastSuccess();
          input.onSuccess();
        })
        .catch(() => {
          toasts.toastServerError();
        });
    },
  };
}
