import { Template } from '@frontend/components/FocusedFlow/Template';
import Spacer from '@frontend/components/Spacer';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { UseFormReturn } from '../../forms/useForm';
import {
  CategoriesMultiselect,
  EstablishmentToggleList,
  LimitSection,
  LimitSwitch,
  WeekdaysToggleList,
  WithdrawToggle,
} from './components';

type Props = {
  form: UseFormReturn;
};

export default function Rules(props: Props) {
  const limitByCategoryEnabled = FeatureFlagService.getFlag(
    'limitByCategoryPolicy',
  );

  return (
    <Template
      pageInfo={{
        title: 'Editar política de uso do cartão corporativo',
      }}
      contentInfo={{
        title: 'Regras de aplicação',
        description: (
          <>
            <p>
              Defina de quais formas os cartões corporativos que tiverem essa
              política aplicada poderão ser utilizados.
            </p>
            <br />
            <p>
              As pessoas que forem titulares dos cartões verão essas regras ao
              acessá-los
            </p>
          </>
        ),
      }}
      Modules={
        <>
          {!limitByCategoryEnabled && (
            <>
              <LimitSwitch {...props} />
              <Spacer y="xs" />
            </>
          )}
          {!limitByCategoryEnabled && (
            <>
              <WithdrawToggle {...props} />
              <Spacer y="xs" />
            </>
          )}
          <WeekdaysToggleList {...props} />
          <Spacer y="xs" />
          {limitByCategoryEnabled ? (
            <>
              <CategoriesMultiselect {...props} />
              <Spacer y="xs" />
              <WithdrawToggle {...props} />
            </>
          ) : (
            <EstablishmentToggleList {...props} />
          )}
          {limitByCategoryEnabled && <LimitSection {...props} />}
        </>
      }
    />
  );
}
