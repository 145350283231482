import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24px auto;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs3}`};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  color: ${({ theme }) => theme.colors.neutral[30]};
  cursor: help;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.colors.neutral[90]};
`;
