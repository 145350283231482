import Spacer from '@frontend/components/Spacer';
import Flex from '@frontend/components/frames/Flex';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import { ROUTE_Home } from '@frontend/pages/Home';
import { CARDS_HOME_TITLE } from '@frontend/pages/Home/Home';
import ModalService from '@frontend/services/ModalService';
import RouterService from '@frontend/services/RouterService';

function CreatePolicySuccessModal() {
  const modalController = ModalService.useModalController();
  const navigate = RouterService.useNavigate();

  function handleNavigateToManageCards() {
    navigate({ route: ROUTE_Home, params: {}, searchParams: {}, state: {} });
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        title: 'Política criada! Agora é só vincular os cartões.',
        body: (
          <Flex align="center" direction="column">
            Em {CARDS_HOME_TITLE}, você pode selecionar os Cartões Corporativos
            da Flash que deseja vincular a essa política.
            <Spacer y="xs4" />
            Você pode fazer isso com quantos cartões desejar, assim como
            desvinculá-los da política quando for necessário.
            <Spacer y="xs4" />
            <img
              style={{ width: '350px' }}
              src="https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/policies/link-card-policy.gif"
              alt="Tutorial para vínculo de política a um cartão"
            />
          </Flex>
        ),
      }}
      buttons={{
        cancel: {
          text: 'Concluir',
          onClick: modalController.remove,
        },
        confirm: {
          text: `Ir para ${CARDS_HOME_TITLE}`,
          onClick: handleNavigateToManageCards,
        },
      }}
    ></ConfirmationModal>
  );
}

export default ModalService.create(CreatePolicySuccessModal);
