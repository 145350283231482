import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Tag from '@frontend/components/TagV2';
import { useNavigate, useParams } from 'react-router-dom';
import { TableProps } from '../../../components/TableV2';
import { formatDateTime, toCurrency } from '../../../utils/masks';
import { GetStatementOutput } from './data/useTableData';
import * as styles from './styled';

interface ITablePageSizeOption {
  label: string;
  value: number;
}

type Rows = GetStatementOutput['transactions'][number];
type Columns = TableProps<Rows>['columns'];

export const initialPagination = {
  currentPage: 0,
  pageSize: 10,
};

export const pageSizeOptions: ITablePageSizeOption[] = [
  { label: '5 itens', value: 5 },
  { label: '10 itens', value: 10 },
  { label: '20 itens', value: 20 },
];

export function statementTableColumns(): Columns {
  return [
    {
      accessorKey: 'date',
      header: 'Data',
      accessorFn: ({ date }) => formatDateTime(date),
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
      cell: ({ cell }) => {
        const value = cell.row.original.description;
        return <styles.NoWrap>{value}</styles.NoWrap>;
      },
    },
    {
      accessorKey: 'amount',
      header: 'Valor',
      cell: ({ cell }) => {
        const value = cell.row.original.amount;
        return <styles.NoWrap>{toCurrency(value)}</styles.NoWrap>;
      },
    },
    {
      accessorKey: 'accountability',
      header: 'Status do Relatório',
      cell: ({ cell }) => {
        const value = cell.row.original.accountability;
        const type = cell.row.original.type;

        return (
          type === 'DEBIT' && (
            <Tag
              size="sm"
              variant={value?.code ? tagStyle(value.code) : 'neutral'}
            >
              {value?.translated ?? 'N/A'}
            </Tag>
          )
        );
      },
    },
    {
      id: 'actions',
      header: 'Ações',
      isSticky: true,
      cell: ({ cell }) => <ActionCell transaction={cell.row.original} />,
    },
  ];
}

type ActionCellProps = {
  transaction: Rows;
};

function ActionCell({ transaction }: ActionCellProps) {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const options = [
    {
      label: 'Ver mais detalhes',
      onClick: () => {
        navigate(
          `/corporateCard/home/${employeeId}/statement/${transaction._id}`,
          {
            state: {
              transaction,
            },
          },
        );
      },
    },
  ];

  return (
    <Menu type="default" locked options={options}>
      <PillButton size="medium" variant="default" icon="IconDotsVertical" />
    </Menu>
  );
}

type Code = Required<Rows>['accountability']['code'];

function tagStyle(code: Code) {
  switch (code) {
    case 'OPEN':
    case 'SUBMITTED':
    case 'PROCESSING':
      return 'info';
    case 'APPROVED':
    case 'REFUNDED':
      return 'success';
    case 'DISAPPROVED':
      return 'error';
    case 'NA':
    default:
      return 'neutral';
  }
}
