import { trpc } from '@frontend/trpc';

export default function useGetEmployeesInfo() {
  const searchEmployees = trpc.company.employees.search.useMutation();
  return {
    mutateAsync: searchEmployees.mutateAsync,
    loading: searchEmployees.isLoading,
    employeeIds: searchEmployees.variables?.ids ?? [],
  };
}

export type UseCreateDepositsReturn = ReturnType<typeof useGetEmployeesInfo>;
