import { Button, PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import Callout from '@frontend/components/frames/Callout';
import Flex from '@frontend/components/frames/Flex';
import { CARDS_HOME_TITLE } from '@frontend/pages/Home/Home';
import OnboardingAddEmployeeStartModal from '@frontend/pages/Home/modals/onboardingAddEmployee/OnboardingAddEmployeeStartModal';
import OnboardingCoupledVirtualCardStartModal from '@frontend/pages/Home/modals/onboardingCoupledVirtualCard/OnboardingCoupledVirtualCardStartModal';
import OnboardingRechargeStartModal from '@frontend/pages/Home/modals/onboardingRecharge/OnboardingRechargeStartModal';
import OnboardingVirtualCardStartModal from '@frontend/pages/Home/modals/onboardingVirtualCard/OnboardingVirtualCardStartModal';
import OnboardingFlashCashStartModal from '@frontend/pages/Management/modals/onboardingFlashCash/OnboardingFlashCashStartModal';
import OnboardingRequestBalanceStartModal from '@frontend/pages/Management/tabs/RequestBalanceSection/modals/onboardRequestBalance/OnboardingRequestBalanceStartModal';
import OnboardingPoliciesStartModal from '@frontend/pages/Policies/modals/onboardingPoliciesModal/OnboardingPoliciesStartModal';
import ModalService from '@frontend/services/ModalService';

export default function Onboardings() {
  return (
    <PageContainer>
      <Spacer y={'l'} />
      <Typography.Headline6>Onboardings</Typography.Headline6>
      <Spacer y={'m'} />
      <Flex gap="xs2" direction="column">
        {onboardings.map((onboarding) => (
          <Callout
            leftAdornment={
              <Icon
                name={onboarding.icon}
                color="secondary_40"
                background="default"
                size={40}
              />
            }
            title={onboarding.title}
            description={onboarding.description}
          >
            <Button size="small" variant="primary" onClick={onboarding.onClick}>
              Ver tutorial
            </Button>
          </Callout>
        ))}
      </Flex>
      <Spacer y={'m'} />
    </PageContainer>
  );
}

const onboardings = [
  {
    title: 'Adicionar Flash Cash',
    description:
      'Exibido ao entrar em "Saldo corporativo", caso não tenha sido visto anteriormente',
    icon: 'IconCash',
    onClick: () => ModalService.show(OnboardingFlashCashStartModal, {}),
  },
  {
    title: 'Cartão virtual',
    description: `Acessado a partir de um banner de 'Novidade!' na tela ${CARDS_HOME_TITLE}`,
    icon: 'IconCards',
    onClick: () => ModalService.show(OnboardingVirtualCardStartModal, {}),
  },
  {
    title: 'Cartão virtual acoplado',
    description: `Acessado a partir de um banner de 'Novidade!' na tela ${CARDS_HOME_TITLE}`,
    icon: 'IconCards',
    onClick: () =>
      ModalService.show(OnboardingCoupledVirtualCardStartModal, {}),
  },
  {
    title: 'Pedido de carga',
    description:
      'Exibido ao clicar em um botão de ajuda, na tela de Pedidos de Carga"',
    icon: 'IconGitPullRequest',
    onClick: () => ModalService.show(OnboardingRequestBalanceStartModal, {}),
  },
  {
    title: 'Políticas de uso do cartão corporativo',
    description: '',
    icon: 'IconShield',
    onClick: () => ModalService.show(OnboardingPoliciesStartModal, {}),
  },
  {
    title: 'Recarga para colaborador',
    description: `Na tela "${CARDS_HOME_TITLE}", ao selecionar "Nova Recarga" no menu de um usuário, ou "Fazer recarga" no menu inferior (ações em lote), mostrará o tutorial antes de iniciar o fluxo de carga se não visto anteriormente`,
    icon: 'IconRecharging',
    onClick: () =>
      ModalService.show(OnboardingRechargeStartModal, {
        callback: () => {},
      }),
  },
  {
    title: 'Vincular colaborador ao cartão corporativo',
    description: `Exibido ao entrar em "${CARDS_HOME_TITLE}", caso não tenha sido visto anteriormente e nenhum colaborador tenha sido vinculado ao cartão corporativo`,
    icon: 'IconUserPlus',
    onClick: () => ModalService.show(OnboardingAddEmployeeStartModal, {}),
  },
] as const satisfies Readonly<
  Array<{
    title: string;
    description: string;
    icon: React.ComponentProps<typeof Icon>['name'];
    onClick: () => void;
  }>
>;
