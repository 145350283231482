import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  /** Total items count */
  length: number;
  /** Function to fetch next page. Should update the children */
  next: () => void;
  /** If true, there are more items to fetch */
  hasMore: boolean;
  /** List's height */
  height: string | number;
  /** Loader component */
  loader: React.ReactNode;
  /** End message component */
  endMessage?: React.ReactNode;
  /** List's render */
  children: React.ReactNode;
};

export function InfiniteScrollList(props: Props) {
  return (
    <InfiniteScroll
      dataLength={props.length}
      next={props.next}
      hasMore={props.hasMore}
      loader={props.loader}
      endMessage={props.endMessage}
      scrollThreshold={0.9}
      height={props.height}
    >
      {props.children}
    </InfiniteScroll>
  );
}
