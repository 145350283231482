import { trpc } from '@frontend/trpc';

export function useGetBalances() {
  const response = trpc.dashboard.balances.useQuery();

  return {
    isLoading: response.isLoading,
    balances: response.data?.balances,
  };
}
