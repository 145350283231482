import styled from 'styled-components';

type Props = {
  direction: 'horizontal' | 'vertical';
};

export function Divider(props: Props) {
  return <SCDivider {...props} />;
}

const SCDivider = styled.div<Props>`
  width: ${(props) => (props.direction === 'horizontal' ? '100%' : '1px')};
  height: ${(props) => (props.direction === 'horizontal' ? '1px' : 'auto')};
  min-height: ${(props) =>
    props.direction === 'horizontal' ? 'unset' : '100%'};
  background-color: ${(props) => props.theme.colors.neutral[90]};
`;
