import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { ComponentProps } from 'react';
import CardSelectBody from './CardSelectBody';
import CardCardSelectErrorMessage from './CardSelectErrorMessage';
import CardSelectIcon from './CardSelectIcon';
import CardSelectTag from './CardSelectTag';
import * as SC from './styled';

type Props = {
  title: React.ReactNode;
  description: string;
  tag?: {
    description: string;
    variant: ComponentProps<typeof CardSelectTag>['variant'];
  };
  icon: IconTypes;
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
  width?: string;
  errorMessage?: string;
};

export default function CardSelect(props: Props) {
  return (
    <SC.Container>
      <SC.CardSelectContainer
        disabled={props.disabled}
        selected={props.selected}
        onClick={() => !props.disabled && props.onClick()}
      >
        <CardSelectIcon
          icon={props.selected ? 'IconCheck' : props.icon}
          disabled={props.disabled}
          selected={props.selected}
        />

        <CardSelectBody
          title={props.title}
          description={props.description}
          selected={props.selected}
          disabled={props.disabled}
        />

        {props.tag && (
          <CardSelectTag
            description={props.tag.description}
            variant={props.tag.variant}
            disabled={props.disabled}
          />
        )}
      </SC.CardSelectContainer>

      {props.errorMessage && (
        <CardCardSelectErrorMessage>
          {props.errorMessage}
        </CardCardSelectErrorMessage>
      )}
    </SC.Container>
  );
}
