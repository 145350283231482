import styled from 'styled-components';

type Props = {
  LeftColumn?: React.ReactNode;
  MiddleColumn: React.ReactNode;
  RightColumn?: React.ReactNode;
  /** Container `id`. Useful for triggering Pendo onboardings
   * @example `onboarding.my-flow.step`
   */
  id?: string;
};

export function ThreeColumnsTemplate(props: Props) {
  return (
    <Container id={props.id}>
      <section>{props.LeftColumn}</section>
      <section>{props.MiddleColumn}</section>
      <section>{props.RightColumn}</section>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 275px 588px 275px;
  justify-content: center;
  grid-gap: ${({ theme }) => theme.spacings.xs};
  @media screen and (max-width: 1200px) {
    grid-template-columns: min(588px, 90%);
  }
`;
