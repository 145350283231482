import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';
import useLinkPolicy from '../data/useLinkPolicy';
import { toasts } from '../toasts';

type Input = {
  cardId: string;
  onSuccess: () => void;
};

export default function useForm(input: Input) {
  const form = FormService.useCreateForm(FormSchema);
  const linkPolicy = useLinkPolicy();

  const onSubmit = form.handleSubmit(
    async (data) => {
      await linkPolicy.mutateAsync({
        cardId: input.cardId,
        policyId: data.policyId,
        onSuccess: input.onSuccess,
      });
    },
    (errors) => {
      // Schema validation
      ErrorMonitorService.error({
        message: 'Error while validating policy form schema',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      toasts.toastFormSchemaError();
    },
  );

  return {
    control: form.control,
    isValid: form.formState.isValid || true,
    isSubmitting: form.formState.isSubmitting,
    onSubmit,
  };
}

const FormSchema = z.object({
  policyId: z.string({ required_error: errors.forms.generic.required }),
});
