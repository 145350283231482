import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';

const PaymentMethodSchema = z.enum(['PIX', 'BILLET']);

export const AddFlashCashSchema = z
  .object({
    value: z.number({
      required_error: errors.forms.generic.required,
    }),
    paymentMethod: PaymentMethodSchema,
    creditDate: z.coerce.date(),
  })
  .refine((data) => {
    if (data.paymentMethod === 'BILLET') {
      return data.value >= 1000 && data.value <= 1000000000;
    }

    return data.value <= 1000000000;
  });
