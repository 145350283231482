import LazyComponent from '@frontend/components/LazyComponent';
import RouterService from '@frontend/services/RouterService';
import { PageMetadata } from '@frontend/utils/types/pageMetadata';
import { Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';
import { ApprovalFlowForm } from './ApprovalFlowForm';

const ApprovalFlowList = () => (
  <LazyComponent
    factory={() => import('./ApprovalFlowList/ApprovalFlowList')}
  />
);

export const METADATA_RequestBalance = {
  title: 'Aprovação de saldo',
} as const satisfies PageMetadata;

export const ROUTE_ApprovalFlow = RouterService.create(
  'corporateCard/balance/approval-flow',
);

export const ROUTE_ApprovalFlowForm = RouterService.create(
  'corporateCard/balance/approval-flow/form',
);

export const ROUTE_ApprovalFlowEditForm = RouterService.create(
  'corporateCard/balance/approval-flow/form/:id',
  {
    params: { id: zod(z.string()) },
  },
);

export const RouteComponent_ApprovalFlow = (
  <Route index path={ROUTE_ApprovalFlow.path} element={<ApprovalFlowList />} />
);

export const RouteComponent_ApprovalFlowForm = (
  <Route
    index
    path={ROUTE_ApprovalFlowForm.path}
    element={<ApprovalFlowForm />}
  />
);

export const RouteComponent_ApprovalFlowEditForm = (
  <Route
    index
    path={ROUTE_ApprovalFlowEditForm.path}
    element={<ApprovalFlowForm />}
  />
);
