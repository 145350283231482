import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useNavigate } from 'react-router-dom';
import Spacer from '../../../../components/Spacer';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';
import { useFindBackboneUsers } from '../../data/useAddUsers';
import * as styled from '../../styled';

function SuccessAddUserModal() {
  const modalController = ModalService.useModalController();
  const navigate = useNavigate();

  const { addUsersData } = useFindBackboneUsers();

  return (
    <ConfirmationModal
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
        },
        confirm: {
          text: 'Concluir',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        highlightedText: 'Tudo certo!',
        title: 'Cartão vinculado com sucesso!',
        body: (
          <>
            Agora é possível realizar recargas para o colaborador e fazer a
            gestão dos gastos. Seu colaborador ainda não possui cartão físico, é
            necessário solicitar.
            <Spacer y="s" />
            {!addUsersData.some(({ hasCard }) => hasCard) && (
              <styled.CenteredBox>
                <LinkButton
                  variant="default"
                  onClick={() => {
                    modalController.remove();
                    navigate('/benefits/dashboard/company/cards');
                  }}
                >
                  Solicitar Cartão Físico
                </LinkButton>
              </styled.CenteredBox>
            )}
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(SuccessAddUserModal);
