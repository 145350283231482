import styled from 'styled-components';

type Props = {
  width?: string;
  height?: string;
};

export const Container = styled.div<Props>(
  ({ theme, width = '314px', height }) => ({
    width,
    height,
    display: 'grid',
    gap: theme.spacings.xs2,
    padding: theme.spacings.xs2,
    backgroundColor: theme.colors.secondary[99],
    border: `${theme.borders.width.xs2} solid ${theme.colors.secondary[95]}`,
    borderRadius: theme.borders.radius.m,
  }),
);

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
