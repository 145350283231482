import { cpfMask } from '@flash-tecnologia/corporate-card-bff/src/utils/masks/formatCPF';
import { trpc } from '@frontend/trpc';

/** Returns a list of employees as options for the ComboBox input */
export function useGetEmployees() {
  const getEmployees = trpc.company.employees.getAll.useQuery();

  return {
    isError: getEmployees.isError,
    loading: getEmployees.isLoading,
    refresh: getEmployees.refetch,
    employees:
      getEmployees.data?.map((user) => ({
        /** Employee's name */
        title: user.name,
        /** Employee's nanoID */
        value: user.id,
        /** Employee's document number */
        description: cpfMask(user.documentNumber),
        /** Employee's raw document number */
        additionalFilterKey: user.documentNumber,
        /** Disables selection of the employee if already active */
        disabled: false,
      })) ?? [],
  };
}
