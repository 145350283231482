import { Icons, ShapeIcon as SI } from '@flash-tecnologia/hros-web-ui-v2';
import useColors, { Color } from '@frontend/utils/theme/colors';
import styled from 'styled-components';

type IconsPropsType = React.ComponentProps<typeof Icons>;
type ShapeIconPropsType = React.ComponentProps<typeof SI>;

type Props = {
  name: IconsPropsType['name'];
  color: Color;
  onClick?: () => void;
} & (
  | {
      size?: number;
      background?: false;
    }
  | {
      size?: keyof typeof shapeIconSize;
      background: ShapeIconPropsType['variant'];
    }
);

export default function Icon(props: Props) {
  const colors = useColors();
  if (props.background) {
    const size = props.size ?? 24;
    return (
      <ShapeIcon size={size} background={props.background}>
        <Icons
          name={props.name}
          fill="transparent"
          color={colors[props.color]}
          size={shapeIconSize[size]}
          style={{ flexShrink: 0 }}
          onClick={props.onClick}
        />
      </ShapeIcon>
    );
  }

  return (
    <Icons
      name={props.name}
      fill="transparent"
      color={colors[props.color]}
      size={props.size}
      style={{ flexShrink: 0 }}
      onClick={props.onClick}
    />
  );
}
export const shapeIconSize = {
  24: 16,
  32: 16,
  40: 16,
  48: 24,
  56: 32,
  64: 40,
  72: 40,
  96: 64,
} as const;

const ShapeIcon = styled.div<{
  size: number;
  background: ShapeIconPropsType['variant'];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: ${(p) => p.theme.borders.radius.circular};
  background-color: ${(p) => {
    switch (p.background) {
      case 'default':
        return p.theme.colors.secondary[95];
      case 'info':
        return p.theme.colors.feedback.info[90];
      case 'success':
        return p.theme.colors.feedback.success[90];
      case 'error':
        return p.theme.colors.feedback.error[90];
      case 'neutral':
      case 'disabled':
        return p.theme.colors.neutral[90];
    }
  }};
`;
