import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Typography from '@frontend/components/Typography';
import { useFormContext } from 'react-hook-form';
import { ApprovalFlow } from '../../form/schema';
import { ApprovalType } from './ApprovalType';
import { SelectApproverMode } from './SelectApproverMode';

export function ApprovalTypeStep() {
  const { watch, setValue } = useFormContext<ApprovalFlow>();

  const type = watch('approvalType');

  const setType = (type: ApprovalFlow['approvalType']) => {
    setValue('approvalType', type);
    if (type !== 'CUSTOM') {
      setValue('condition', []);
    } else {
      setValue('condition', [{} as any]);
    }
  };

  return (
    <>
      <Grid templateColumns="1fr 3fr" padding="xs">
        <Flex direction="column" gap="xs2">
          <Typography.Headline8 color="primary">
            Tipo de aprovação
          </Typography.Headline8>
          <Typography.Body3 color="neutral_30">
            Selecione de que forma acontecerá a aprovação pelos aprovadores.
          </Typography.Body3>
          <Typography.Headline9>Importante:</Typography.Headline9>
          <Typography.Body3 color="neutral_30">
            <ul>
              <li>
                Quando o solicitante também for o aprovador do pedido de saldo,
                a aprovação é realizada de forma automática, seguindo para os
                demais aprovadores se houver.
              </li>
              <li>
                Se um aprovador estiver envolvido em várias etapas do fluxo ele
                só precisará aprovar uma vez.
              </li>
              <li>
                Caso o pedido de saldo atenda o critério de 2 fluxos de
                aprovação, ele irá requisitar apenas o primeiro nível de
                aprovação.
              </li>
            </ul>
          </Typography.Body3>
        </Flex>
        <Grid templateColumns="1fr" padding="xs2" gap="xs">
          <ApprovalType
            title="Aprovação manual"
            description="Defina quem será o aprovador, sendo possível adicionar mais de
                  um aprovador para o fluxo."
            checked={type === 'CUSTOM'}
            setType={() => setType('CUSTOM')}
          >
            {type === 'CUSTOM' && <SelectApproverMode />}
          </ApprovalType>
          <ApprovalType
            title="Aprovação automática"
            description="Ao submeter o relatório, ele é enviado automaticamente para o
                  financeiro, sem a necessidade de aprovação por algum
                  aprovador."
            checked={type === 'AUTO'}
            setType={() => setType('AUTO')}
          />
          <ApprovalType
            title="Reprovação automática"
            description="Cumprindo com os critérios estabelecidos na etapa anterior, o
                  relatório é reprovado automaticamente."
            checked={type === 'AUTO_REPROVE'}
            setType={() => setType('AUTO_REPROVE')}
          />
        </Grid>
      </Grid>
    </>
  );
}
