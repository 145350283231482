import ErrorMonitorService from '@frontend/services/MonitorService';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { DateTime } from 'luxon-business-days';
import FormService from 'src/services/FormService';
import { useCreateOrder } from '../data/useCreateOrder';
import { AddFlashCashSchema } from './schema';

type Input = {
  onSuccess: () => void;
  onError: () => void;
};

export function useForm(input: Input) {
  const form = FormService.useCreateForm(AddFlashCashSchema, {
    mode: 'onChange',
    defaultValues: {
      creditDate: DateTime.now().plusBusiness({ days: 10 }).toJSDate(),
    },
  });

  const mutation = useCreateOrder();

  const onSubmit = form.handleSubmit(
    async (values) => {
      await mutation.mutateAsync(values, {
        onSuccess() {
          input.onSuccess();
        },
        onError() {
          input.onError();
        },
      });
    },
    (error) => {
      dispatchToast({
        type: 'error',
        content: 'Falha ao validar formulário de adicionar saldo à carteira',
        description: errors.actions.toastDescription,
      });
      ErrorMonitorService.error({
        message: 'Error while activating users',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(error),
        },
      });
    },
  );

  return {
    ...form,
    onSubmit,
    data: mutation.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
