import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import BasePath from '../../../routes/BasePath';
import Modals from './Modals';

export const ROUTE_Modals = RouterService.create(
  `${BasePath.relativePath}/playground/modals`,
);

export const RouteComponent_Modals = (
  <Route path={ROUTE_Modals.path} element={<Modals />}></Route>
);
