import { Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { ThreeColumnsTemplate } from '@frontend/components/FocusedFlow/templates/ThreeColumnsTemplate';
import Flex from '@frontend/components/frames/Flex';
import { UseFormReturn } from '../../../controllers/form/useForm';
import { Failure } from './components/Content/Failure';
import { Success } from './components/Content/Success';

type Props = {
  form: UseFormReturn;
};

export function Feedback(props: Props) {
  const result = props.form.result;
  if (!result || result.auth === 'FAILED') {
    // Might happen when the user clicks `Try Again` button after a FAILED deposit
    return (
      <Flex style={flexStyle} align="center" justify="center">
        <Spinner size={48} />
      </Flex>
    );
  }
  const deposit = result.deposit;

  function getContent() {
    switch (deposit.status) {
      case 'FAILED':
        return <Failure {...props} />;
      case 'SUCCESS':
        return <Success {...props} />;
    }
  }
  return <ThreeColumnsTemplate MiddleColumn={getContent()} />;
}

const flexStyle = { width: '100%' } as const;
