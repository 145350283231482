import { ComboBoxFieldProps } from '../../ComboBoxField';
import { useComboBoxFieldController } from '../../useComboBoxFieldController';
import { ListDefault } from './ListDefault';
import { ListEmpty } from './ListEmpty';
import { ListError } from './ListError';
import { ListLoading } from './ListLoading';
import { ListNoSearchResults } from './ListNoSearchResults';

type Props = {
  comboBoxFieldProps: ComboBoxFieldProps;
  controller: ReturnType<typeof useComboBoxFieldController>;
};

export function List(props: Props) {
  if (props.comboBoxFieldProps.loading) return <ListLoading />;
  if (props.comboBoxFieldProps.errorState?.isError)
    return <ListError errorState={props.comboBoxFieldProps.errorState} />;

  const emptyState = props.comboBoxFieldProps.emptyState;
  if (emptyState) {
    if (!props.controller.filter.hasAvailableFilteredOptions) {
      if (props.controller.filter.isFiltered) {
        return <ListNoSearchResults emptyState={emptyState} />;
      } else {
        return <ListEmpty emptyState={emptyState} />;
      }
    }
  }

  return <ListDefault controller={props.controller} />;
}
