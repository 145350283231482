import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import TextField from '@mui/material/TextField';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '../../Typography';

type Props = {
  /** Input's label */
  label: string;
  /** Container's size */
  size: 'lg' | 'md';
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Readable error message displayed under the input container */
  error?: string;
  /** Triggered when the input value changes */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** Controlled input value */
  value?: string;
  /** Initial value */
  initialValue?: string;
  /** Container's additional styles */
  containerStyle?: React.CSSProperties;
};

export default function SearchField(props: Props) {
  const ref_TextField = React.useRef<HTMLInputElement>();
  const theme = useTheme();
  const [isFocused, setIsFocused] = React.useState(false);

  /** Defines the input variant state depending on the priority of flag values */
  const variant = props.disabled
    ? 'DISABLED'
    : isFocused
      ? 'FOCUSED'
      : props.error
        ? 'ERROR'
        : 'DEFAULT';

  function handleOnClick() {
    ref_TextField.current?.focus();
  }

  function handleOnFocus() {
    setIsFocused(true);
  }

  function handleOnBlur() {
    setIsFocused(false);
  }

  return (
    <div style={props.containerStyle}>
      <StyledInputContainer
        onClick={handleOnClick}
        size={props.size}
        variant={variant}
      >
        <Icons
          name="IconSearch"
          fill="transparent"
          color={(() => {
            switch (variant) {
              case 'ERROR':
                return theme.colors.feedback.error[50];
              case 'FOCUSED':
                return theme.colors.primary;
              case 'DEFAULT':
              case 'DISABLED':
                return theme.colors.neutral[80];
            }
          })()}
        />
        <TextField
          fullWidth
          variant="standard"
          label={props.label}
          inputRef={ref_TextField}
          disabled={props.disabled}
          defaultValue={props.initialValue}
          InputProps={{
            disableUnderline: true,
            onBlur: handleOnBlur,
            onFocus: handleOnFocus,
          }}
          onChange={props.onChange}
          value={props.value}
        />
      </StyledInputContainer>
      {props.error && (
        <StyledErrorMessageArea>
          <Icons
            name="IconInfoCircle"
            size={14}
            color={theme.colors.feedback.error[50]}
            fill="transparent"
          />
          <Typography.Body4 color="neutral_50">{props.error}</Typography.Body4>
        </StyledErrorMessageArea>
      )}
    </div>
  );
}

type InputContainerProps = {
  variant: 'DISABLED' | 'ERROR' | 'FOCUSED' | 'DEFAULT';
  size: 'lg' | 'md';
};

const StyledInputContainer = styled.div<InputContainerProps>`
  display: flex;
  width: 100%;
  height: ${(p) => (p.size === 'lg' ? '60px' : '48px')};
  font-size: ${(p) => (p.size === 'lg' ? '16px' : '14px')};
  color: ${(p) => p.theme.colors.neutral[30]};

  box-sizing: border-box;
  border: 1px solid
    ${(p) => {
      switch (p.variant) {
        case 'ERROR':
          return p.theme.colors.feedback.error[50];
        case 'FOCUSED':
          return p.theme.colors.primary;
        case 'DEFAULT':
        case 'DISABLED':
          return p.theme.colors.neutral[80];
      }
    }};
  background-color: ${(p) => {
    switch (p.variant) {
      case 'ERROR':
        return p.theme.colors.feedback.error[90];
      case 'DISABLED':
        return p.theme.colors.neutral[95];
      case 'DEFAULT':
      case 'FOCUSED':
        return 'transparent';
    }
  }};
  border-radius: ${(p) => p.theme.borders.radius.pill};

  align-items: center;
  gap: 20px;
  padding: 0 20px;
  label {
    color: ${(p) => p.theme.colors.neutral[50]};
    font-family: ${(p) => p.theme.fonts.secondary};
    font-weight: 600;
    margin-top: -8px;
    &.MuiInputLabel-shrink {
      margin-top: ${(p) => (p.size === 'lg' ? '0px' : '4px')};
    }
  }
  input {
    color: ${(p) => p.theme.colors.neutral[30]};
    font-family: ${(p) => p.theme.fonts.secondary};
    font-weight: 400;
  }
  cursor: ${(p) => (p.variant === 'DISABLED' ? 'default' : 'text')};
`;

const StyledErrorMessageArea = styled.div`
  display: flex;
  padding-left: 18px;
  gap: 6px;
  align-items: center;
`;
