import styled from 'styled-components';

type CardSelectProps = {
  disabled?: boolean;
  selected?: boolean;
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  > div {
    height: 100%;
  }
`;

export const CardSelectContainer = styled.div<CardSelectProps>(
  ({ theme, disabled, selected }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacings.xs3,
    borderRadius: theme.borders.radius.m,
    border: `${theme.borders.width.xs2} solid`,
    padding: `${theme.spacings.xs3} ${theme.spacings.xs3} ${theme.spacings.xs2}`,
    cursor: 'pointer',
    borderColor: theme.colors.neutral[90],
    backgroundColor: theme.colors.neutral[100],
    '&:hover': {
      background: theme.colors.secondary[99],
      borderColor: 'transparent',
    },
    ...(selected && {
      borderColor: 'transparent',
      backgroundColor: theme.colors.secondary[99],
    }),
    ...(disabled && {
      borderColor: theme.colors.neutral[80],
      backgroundColor: theme.colors.neutral[95],
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: theme.colors.neutral[95],
      },
    }),
  }),
);

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ErrorMessageArea = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding-left: ${({ theme }) => theme.spacings.xs};
`;
