import Icon from '../Icon';
import Typography from '../Typography';
import * as SC from './styled';

type TipProps = {
  children: string;
};

export default function Tip(props: TipProps) {
  return (
    <SC.Container>
      <SC.IconContainer>
        <Icon name="IconBulb" size={12} color="neutral_40" />
      </SC.IconContainer>

      <Typography.Caption>{props.children}</Typography.Caption>
    </SC.Container>
  );
}
