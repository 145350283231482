import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import styled from 'styled-components';

type Props = {
  error?: string;
  noPadding?: boolean;
};

/** Error message displayed below an input */
export default function InputErrorMessage(props: Props) {
  if (!props.error) return null;
  return (
    <Container noPadding={Boolean(props.noPadding)}>
      <Icon color="error_50" name="IconInfoCircle" size={16} />
      <Typography.Caption color="neutral_40" weight={600}>
        {props.error}
      </Typography.Caption>
    </Container>
  );
}

const Container = styled.div<{ noPadding: boolean }>`
  display: flex;
  align-items: center;
  padding-top: ${({ noPadding, theme }) =>
    noPadding ? 0 : theme.spacings.xs5};
  padding-left: ${({ noPadding, theme }) =>
    noPadding ? 0 : theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs5};
`;
