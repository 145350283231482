import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import { Divider } from '@frontend/components/frames/Divider/Divider';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import SummaryModal from '../SummaryModal/SummaryModal';
import { useSummaryData } from './data/useSummaryData';

type Props = {
  form: UseFormReturn;
};

export function SummaryBox(props: Props) {
  const summaryData = useSummaryData(props);

  return (
    <BorderedSection style={borderedSectionStyles}>
      <Flex direction="column" padding="xs2" gap="xs2">
        <Flex align="center" gap="xs4">
          <Icon
            size={24}
            color="neutral_40"
            background="neutral"
            name="IconUsers"
          />
          <Typography.Body4 color="neutral_20" weight={600}>
            {summaryData.selectedPeopleText}
          </Typography.Body4>
        </Flex>
        {summaryData.deposit.creditDateDescription && (
          <Flex align="center" gap="xs4">
            <Icon
              size={24}
              color="neutral_40"
              background="neutral"
              name="IconSquareNumber1"
            />
            <Flex direction="column">
              <Typography.Body4 color="neutral_20" weight={600}>
                {summaryData.deposit.depositType}
              </Typography.Body4>
              {summaryData.deposit.creditDateDescription && (
                <Typography.Caption color="neutral_40">
                  {summaryData.deposit.creditDateDescription}
                </Typography.Caption>
              )}
            </Flex>
          </Flex>
        )}
        <Flex align="center" gap="xs4">
          <Icon
            size={24}
            color="neutral_40"
            background="neutral"
            name="IconCoin"
          />
          <Flex direction="column">
            <Typography.Body4 color="neutral_20" weight={600}>
              {summaryData.depositAmount.totalAmount}
            </Typography.Body4>
            {summaryData.depositAmount.individualAmount && (
              <Typography.Caption color="neutral_40">
                {summaryData.depositAmount.individualAmount}
              </Typography.Caption>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Divider direction="horizontal" />
      <Flex padding="xs3" align="center" justify="center">
        <LinkButton
          variant="neutral"
          onClick={() => {
            ModalService.show(SummaryModal, {
              employeeIds: props.form.getValues().employeeIds,
              form: props.form,
            });
          }}
        >
          Ver mais detalhes
        </LinkButton>
      </Flex>
    </BorderedSection>
  );
}

const borderedSectionStyles = { position: 'sticky', top: '40px' } as const;
