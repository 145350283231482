import ModalService from '@frontend/services/ModalService';
import React from 'react';
import { z } from 'zod';
import ConfirmDepositModal from '../components/steps/01-Form/components/ConfirmDepositModal/ConfirmDepositModal';
import { Form } from '../components/steps/01-Form/Form';
import { Feedback } from '../components/steps/02-Feedback/Feedback';
import { DepositFormSchema } from './form/schema';
import { useForm } from './form/useForm';

enum ActivateUsersStepsEnum {
  /** Formulary for selecting users and defining the first deposit */
  FORM = 1,
  /** Reports the result of the operation */
  FEEDBACK = 2,
}

type Input = {
  defaultValues: Partial<z.output<typeof DepositFormSchema>>;
};

export function useFlowController(input: Input) {
  const [step, setStep] = React.useState(ActivateUsersStepsEnum.FORM);
  const form = useForm({
    onSuccess: onSubmitSuccess,
    onAuthError: () => {
      ModalService.show(ConfirmDepositModal, {
        form,
        onConfirm: () => {
          void form.onSubmit();
        },
      });
    },
    defaultValues: input.defaultValues,
  });
  const modalController = ModalService.useModalController();

  return {
    content: getContent(),
    leftButtons: getLeftButtons(),
    rightButtons: getRightButtons(),
  };

  function getContent() {
    switch (step) {
      case ActivateUsersStepsEnum.FORM:
        return <Form form={form} />;
      case ActivateUsersStepsEnum.FEEDBACK:
        return <Feedback form={form} />;
    }
  }

  function getLeftButtons() {
    switch (step) {
      case ActivateUsersStepsEnum.FORM:
        return {
          label: 'Voltar',
          onClick: () => {
            modalController.remove();
          },
          isDisabled: false,
        } as const;
      case ActivateUsersStepsEnum.FEEDBACK:
        return undefined;
    }
  }
  function getRightButtons() {
    switch (step) {
      case ActivateUsersStepsEnum.FORM:
        return [
          {
            label: 'Depositar',
            onClick: confirm,
            isDisabled: false,
            isLoading: form.formState.isSubmitting,
            variant: 'primary',
          },
        ] as const;
      case ActivateUsersStepsEnum.FEEDBACK:
        return [
          {
            label: 'Fechar',
            onClick: () => {
              modalController.remove();
            },
            isDisabled: false,
            isLoading: false,
            variant: 'primary',
          },
        ] as const;
    }
  }
  function confirm() {
    void form.onSubmit();
  }
  function onSubmitSuccess() {
    setStep(ActivateUsersStepsEnum.FEEDBACK);
  }
}
