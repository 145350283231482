import { Focused, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useController } from './data/useController';
import { BasicInformationStep } from './steps/01-BasicInfo';
import { ApprovalTypeStep } from './steps/02-ApprovalType';

export function ApprovalFlowForm() {
  const { step, form, leftActions, rightActions, isLoading } = useController();
  const { id } = useParams();

  return (
    <Focused
      stepper={{
        steps: ['Informações básicas', 'Tipo de aprovação'],
        activeStep: step,
      }}
      routes={[
        {
          route: '/expenseManagement/customizations?tab=corporate-card',
          label: 'Preferências',
        },
        {
          route: '/corporateCard/balance/approval-flow',
          label: 'Aprovação para pedidos de saldo',
        },
        {
          label: 'Adicionar fluxo de aprovação',
        },
      ]}
      footer={{
        startActions: leftActions,
        endActions: rightActions,
      }}
    >
      <>
        <Typography.Headline6>
          {!!id ? 'Editar fluxo de aprovação' : 'Adicionar fluxo de aprovação'}
        </Typography.Headline6>
        {isLoading ? (
          <Skeleton animation="wave" width="100%" height={'60px'} />
        ) : (
          <FormProvider {...form}>
            {step === 0 ? <BasicInformationStep /> : <ApprovalTypeStep />}
          </FormProvider>
        )}
      </>
    </Focused>
  );
}
