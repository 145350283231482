import {
  SelectField,
  SelectFieldProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export function SelectRole(props: SelectFieldProps) {
  const { data, isLoading } = trpc.company.role.getRoles.useQuery(undefined, {
    onError: (error) => {
      dispatchToast({ type: 'error', content: error.message });
    },
  });
  const options =
    data?.map((role) => {
      return { value: role.id, label: role.name };
    }) || [];

  return (
    <SelectField
      {...props}
      fullWidth
      options={isLoading ? [{ value: 0, label: 'Buscando...' }] : options}
    />
  );
}
