import EnvService from '@frontend/services/EnvService';
import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import BasePath from '../../routes/BasePath';
import { RouteComponent_ErrorMonitor } from './ErrorMonitor';
import { RouteComponent_FeatureFlags } from './FeatureFlags';
import { RouteComponent_Modals } from './Modals';
import { RouteComponent_Onboardings } from './Onboardings';
import Playground from './Playground';

const playgroundRoutesEnabled = EnvService.BUILD_ENV !== 'production';

export const ROUTE_Playground = RouterService.create(
  `${BasePath.relativePath}/playground`,
);

export const RouteComponent_Playground = playgroundRoutesEnabled && (
  <Route path={ROUTE_Playground.path}>
    <Route element={<Playground />} index />
    {RouteComponent_ErrorMonitor}
    {RouteComponent_FeatureFlags}
    {RouteComponent_Onboardings}
    {RouteComponent_Modals}
  </Route>
);
