import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { Popover } from '@mui/material';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import SearchField from '../SearchField';
import { List } from './components/List/List';
import {
  ComboBoxFieldState,
  useComboBoxFieldController,
} from './useComboBoxFieldController';

type ComboBoxFieldOption = {
  /** An optional additional key used for filtering */
  additionalFilterKey?: string;
  /** An optional subtitle of the option */
  description?: string;
  /** Disables the option */
  disabled?: boolean;
  /** The visible title of the option */
  title: string;
  /** The unique key of the option. Will be used to represent the option on the selected output */
  value: string;
};

type ListStateSetup = {
  title: string;
  description: string;
  linkButton?: {
    label: string;
    onClick: () => void;
  };
};

export type ComboBoxFieldProps = {
  /** Disables the select field interactions */
  disabled?: boolean;
  /** Empty-state setup. If not provided, an empty list will be rendered */
  emptyState?: {
    /** Shown under "Select all" checkbox when all options are disabled */
    allDisabled: string;
    /** Shown when a filtered search yields no results */
    filtered: ListStateSetup;
    /** Shown when an unfiltered search yields no results (no options provided) */
    unfiltered: ListStateSetup;
  };
  /** An optional error state, displayed when the options could not be loaded */
  errorState?: {
    isError: boolean;
  } & ListStateSetup;
  /** An optional error message, displayed under the field */
  error?: string;
  /** The name of the icon displayed on the left side of the field label */
  icon?: React.ComponentProps<typeof Icon>['name'];
  /** The field label */
  label: string;
  /** Indicates that the field data is still loading */
  loading?: boolean;
  /** Callback fired when the user updates the selection */
  onChange: (selected: Set<string>) => void;
  /** The options of the select field */
  options: Readonly<Array<ComboBoxFieldOption>>;
  /** Text to show in search component placeholder */
  searchPlaceholder?: string;
  /** The selected options */
  value: Set<string>;
};

/** A searchable Select-field input */
export function ComboBoxField(props: ComboBoxFieldProps) {
  const controller = useComboBoxFieldController(props);
  const theme = useTheme();

  return (
    <div>
      <SelectField
        aria-describedby={controller.popover.popoverId}
        onClick={controller.popover.handleOpen}
        state={controller.state}
      >
        <Flex justify="space-between">
          <Flex gap="xs4" padding={['xs5', undefined, 'xs5', 'xs3']}>
            {props.icon && (
              <Flex align="center">
                <Icon name={props.icon} color={controller.field.iconColor} />
              </Flex>
            )}
            {controller.selection.hasSelected ? (
              <Flex direction="column">
                <Typography.Caption color="neutral_50" weight={600}>
                  {props.label}
                </Typography.Caption>
                <Flex gap="xs5" style={{ flexWrap: 'wrap' }}>
                  {controller.tags.visibleTags.map((opt) => (
                    <Tag key={opt.value} size="sm" variant="primary">
                      {opt.title}
                    </Tag>
                  ))}
                  {controller.tags.collapsedCount > 0 && (
                    <Tag size="sm" variant="neutral">
                      +{controller.tags.collapsedCount}
                    </Tag>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Flex align="center">
                <Typography.Body3 color="neutral_40" weight={600}>
                  {props.label}
                </Typography.Body3>
              </Flex>
            )}
          </Flex>
          <Flex align="center" padding={'xs3'}>
            <Icon
              name={
                controller.popover.popoverOpen
                  ? 'IconChevronUp'
                  : 'IconChevronDown'
              }
              color="neutral_50"
            />
          </Flex>
        </Flex>
      </SelectField>
      {props.error && (
        <Flex padding={['xs5', 'xs3']}>
          <Typography.Caption color="error_40" weight={700}>
            {props.error}
          </Typography.Caption>
        </Flex>
      )}
      <Popover
        id={controller.popover.popoverId}
        open={controller.popover.popoverOpen}
        anchorEl={controller.popover.anchor}
        onClose={controller.popover.handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            maxHeight: '400px',
            maxWidth: '600px',
            width: '100%',
            borderRadius: theme.borders.radius.s,
          },
        }}
      >
        <Flex
          padding={['xs4', 'xs3']}
          style={{
            position: 'sticky',
            top: 0,
            background: 'white',
            zIndex: 10,
          }}
        >
          <SearchField
            label={props.searchPlaceholder ?? 'Buscar'}
            size="md"
            onChange={(e) => {
              controller.filter.handleFilter(e.target.value);
            }}
            containerStyle={{ flex: 1 }}
          />
        </Flex>
        <List comboBoxFieldProps={props} controller={controller} />
      </Popover>
    </div>
  );
}

type SelectFieldProps = {
  state: ComboBoxFieldState;
};

const SelectField = styled.button<SelectFieldProps>`
  box-sizing: border-box;
  width: 100%;

  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.m};

  border-color: ${({ state, theme }) => {
    switch (state) {
      case 'ERROR':
        return theme.colors.feedback.error[50];
      case 'FOCUSED':
        return theme.colors.secondary[70];
      case 'DEFAULT':
      case 'DISABLED':
        return theme.colors.neutral[80];
    }
  }};
  border-width: ${({ state, theme }) => {
    switch (state) {
      case 'ERROR':
      case 'FOCUSED':
        return theme.borders.width.s;
      case 'DEFAULT':
      case 'DISABLED':
        return theme.borders.width.xs2;
    }
  }};
  cursor: ${({ state }) => {
    switch (state) {
      case 'DISABLED':
        return 'not-allowed';
      default:
        return 'pointer';
    }
  }};
  background-color: ${({ state, theme }) => {
    switch (state) {
      case 'DISABLED':
        return theme.colors.neutral[95];
      case 'ERROR':
        return theme.colors.feedback.error[90];
      default:
        return 'unset';
    }
  }};
`;
