import Typography from '@frontend/components/Typography';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingPoliciesTutorialModal from './OnboardingPoliciesTutorialModal';

function OnboardingPoliciesEndModal() {
  const modalController = ModalService.useModalController();

  function backToTutorial() {
    modalController.remove();
    ModalService.show(OnboardingPoliciesTutorialModal, {});
  }

  function handleDismiss() {
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      text={{
        highlightedText: 'Tudo certo!',
        title: 'Agora é só criar a sua primeira política!',
        body: (
          <Typography.Body3>
            Se você tiver dúvidas ou quiser saber mais sobre as políticas de
            cartão corporativo, acesse a nossa{' '}
            <Typography.Body3
              as="a"
              href="https://beneficios.flashapp.com.br/faq/políticas-do-cartão-corporativo"
              target="_blank"
              color="secondary_50"
            >
              FAQ
            </Typography.Body3>
            .
          </Typography.Body3>
        ),
      }}
      variant="default"
      buttons={{
        cancel: {
          onClick: backToTutorial,
          text: 'Voltar',
        },
        confirm: {
          onClick: handleDismiss,
          text: 'Começar',
        },
      }}
    ></ConfirmationModal>
  );
}

export default ModalService.create(OnboardingPoliciesEndModal);
