import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import Callout from '@frontend/components/frames/Callout';
import Flex from '@frontend/components/frames/Flex';
import { translatedPeriodTypes } from '@frontend/pages/Policies/shared/mappers';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import {
  sortedWeekdays,
  translatedWeekdays,
} from '@frontend/utils/dataFormatters/weekdays.dataFormatter';
import { toCurrency } from '@frontend/utils/masks';
import { Divider } from '@mui/material';
import { UseGetPolicyOutput } from '../../data/useGetPolicy';
import { CategoryTitleContainer, SpacerContainer } from './styled';

type Props = {
  policy: NonNullable<UseGetPolicyOutput['data']>;
};

export function PolicyData(props: Props) {
  return (
    <>
      {props.policy.limit && (
        <Callout
          title="Limite de gastos habilitado"
          description="Valor máximo que um cartão pode utilizar em um período"
          leftAdornment={
            <Icon
              name="IconCoin"
              color="success_40"
              background="success"
              size={48}
            />
          }
          alignItems="start"
        >
          <Flex gap="xs">
            <Flex direction="column" align="start">
              <Typography.Caption>Limite</Typography.Caption>
              <Typography.Body3>
                {toCurrency(props.policy.limit.amount)}
              </Typography.Body3>
            </Flex>
            <Flex direction="column" align="start">
              <Typography.Caption>Período</Typography.Caption>
              <Typography.Body3>
                {translatedPeriodTypes[props.policy.limit.period]}
              </Typography.Body3>
            </Flex>
          </Flex>
        </Callout>
      )}{' '}
      {!props.policy.limit && !props.policy.mccLimits && (
        <Callout
          title="Limite de gastos desabilitado"
          description="Os titulares dos cartões poderão utilizar a qualquer momento todo o saldo corporativo disponibilizado."
          leftAdornment={
            <Icon
              name="IconCoinOff"
              color="error_40"
              background="error"
              size={48}
            />
          }
        />
      )}
      <Spacer y="xs" />
      {props.policy.withdrawEnabled ? (
        <Callout
          title="Saque no cartão físico habilitado"
          description="Limite de 2 saques por dia para o colaborador e limite de R$ 20.000,00 por dia para toda a empresa. Para cada saque, haverá uma taxa de operação de R$ 5,99."
          leftAdornment={
            <Icon
              name="IconCash"
              color="success_40"
              background="success"
              size={48}
            />
          }
        >
          {!!props.policy.mccLimits && props.policy.mccLimits['WITHDRAW'] && (
            <Flex gap="xs">
              <Typography.Body3>
                Limite {periodMap[props.policy.mccLimits['WITHDRAW'].period]}
              </Typography.Body3>
              <Typography.Body3>
                {toCurrency(props.policy.mccLimits['WITHDRAW'].amount)}
              </Typography.Body3>
            </Flex>
          )}
        </Callout>
      ) : (
        <Callout
          title="Saque no cartão físico desabilitado"
          description="Os titulares dos cartões não poderão realizar saques do saldo corporativo em caixas eletrônicos."
          leftAdornment={
            <Icon
              name="IconCashOff"
              color="error_40"
              background="error"
              size={48}
            />
          }
        />
      )}
      <Spacer y="xs" />
      <Callout
        title="Dias de uso"
        description="Dias da semana em que transações com esse cartão estão permitidas."
        leftAdornment={
          <Icon
            name="IconCalendar"
            size={48}
            color="secondary_50"
            background="default"
          />
        }
      >
        <Flex wrap="wrap" gap="xs4">
          {sortedWeekdays
            .filter((weekday) => props.policy?.weekdaysEnabled[weekday])
            .map((weekday) => (
              <Tag size="sm" variant="neutral" key={weekday}>
                {translatedWeekdays[weekday]}
              </Tag>
            ))}
        </Flex>
      </Callout>
      <Spacer y="xs" />
      {!!props.policy.mccLimits ? (
        <Callout
          title="Categorias e limites de uso"
          description="Valor máximo que um cartão pode utilizar em um período conforme as categorias ativas na política."
          leftAdornment={
            <Icon
              name="IconCoin"
              size={48}
              color="success_40"
              background="success"
            />
          }
        >
          <Flex wrap="wrap" direction="column">
            {sortedMccGroups
              .filter((mccGroup) => props.policy.mccGroups[mccGroup])
              .map((mccGroup: keyof typeof translatedMccGroups, index) => {
                const category = translatedMccGroups[mccGroup];
                const categoryLimit =
                  props.policy.mccLimits && props.policy.mccLimits[mccGroup];

                return (
                  <>
                    {index !== 0 && (
                      <SpacerContainer>
                        <Spacer y="xs3" />
                        <Divider orientation="horizontal" />
                        <Spacer y="xs3" />
                      </SpacerContainer>
                    )}
                    <Flex gap="xs" align="center">
                      <CategoryTitleContainer>
                        <Typography.Body3 color="neutral_30" weight={600}>
                          {category.title}
                        </Typography.Body3>
                      </CategoryTitleContainer>
                      {!!categoryLimit &&
                        categoryLimit.amount &&
                        categoryLimit.period && (
                          <>
                            <CategoryTitleContainer>
                              <Typography.Body3 color="neutral_30" weight={600}>
                                {toCurrency(categoryLimit.amount)}
                              </Typography.Body3>
                            </CategoryTitleContainer>
                            <Tag variant="neutral" size="md">
                              {periodMap[categoryLimit.period]}
                            </Tag>
                          </>
                        )}
                    </Flex>
                  </>
                );
              })}
          </Flex>
        </Callout>
      ) : (
        <Callout
          title="Tipos de estabelecimento"
          description="Categorias nas quais transações feitas com esse cartão serão aceitas."
          leftAdornment={
            <Icon
              name="IconBuildingStore"
              size={48}
              color="secondary_50"
              background="default"
            />
          }
        >
          <Flex wrap="wrap" direction="column">
            {sortedMccGroups
              .filter((mccGroup) => props.policy?.mccGroups[mccGroup])
              .map((mccGroup, index) => (
                <>
                  {index !== 0 && (
                    <SpacerContainer>
                      <Spacer y="xs3" />
                      <Divider orientation="horizontal" />
                      <Spacer y="xs3" />
                    </SpacerContainer>
                  )}
                  <Typography.Body3 color="neutral_30" weight={600}>
                    {translatedMccGroups[mccGroup].title}
                  </Typography.Body3>
                  <Typography.Body4 color="neutral_40">
                    {translatedMccGroups[mccGroup].description}
                  </Typography.Body4>
                </>
              ))}
          </Flex>
        </Callout>
      )}
    </>
  );
}

const periodMap = {
  DAY: 'diário',
  WEEK: 'semanal',
  MONTH: 'mensal',
};
