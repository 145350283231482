import { Toggle as UIToggle } from '@flash-tecnologia/hros-web-ui-v2';

type Props = {
  disabled?: boolean;
  /** The controlled value of the toggle input
   *
   * Be sure to initialize the field value as a `boolean` on `react-hook-form`.
   * If no default value is provided, the field will render as an uncontrolled
   * field for the rest of its life.
   */
  value: boolean;
  onChange: (newValue: boolean) => void;
};

export default function Toggle(props: Props) {
  return (
    <UIToggle
      disabled={props.disabled}
      checked={props.value}
      onChange={(_e, newValue) => {
        props.onChange(newValue);
      }}
    />
  );
}
