import styled from 'styled-components';

export const AddFistPolicyContainer = styled.div(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  gap: theme.spacings.xs2,
  padding: `${theme.spacings.xs} ${theme.spacings.m}`,
  borderRadius: theme.borders.radius.m,
  backgroundColor: theme.colors.neutral[95],
}));
