import { FocusedFlow } from '@frontend/components/FocusedFlow';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import conditionalList from '@frontend/utils/conditionalList';
import React from 'react';
import useForm from '../forms/useForm';

type Input = {
  /** Closes the `create policy` focused flow */
  close: () => void;
};

export default function useController(input: Input) {
  const limitByCategoryEnabled = FeatureFlagService.getFlag(
    'limitByCategoryPolicy',
  );
  const [step, setStep] = React.useState<Steps>(Steps.BASIC_INFO);
  const form = useForm({
    onSuccess: () =>
      limitByCategoryEnabled ? setStep(step + 1) : input.close(),
  });

  /* ------------------------- Confirm button setup ------------------------- */
  let confirmEnabled: boolean;
  let confirmLabel: string;
  switch (step) {
    case Steps.BASIC_INFO:
      // Checks if `title` and `description` are filled and valid
      confirmEnabled =
        !!form.formState.dirtyFields.title &&
        !form.formState.errors.title &&
        !!form.formState.dirtyFields.description &&
        !form.formState.errors.description;
      confirmLabel = 'Continuar';
      break;
    case Steps.RULES:
      // Checks if the entire form is valid
      confirmEnabled = form.validSchema;
      confirmLabel = 'Continuar';
      break;
    case Steps.CONFIRMATION:
      confirmEnabled = true;
      confirmLabel = 'Adicionar política';
      break;
    case Steps.FEEDBACK:
      confirmEnabled = true;
      confirmLabel = 'Concluir';
      break;
  }

  function onConfirm() {
    if (step === Steps.CONFIRMATION) {
      void form.onSubmit();
    } else if (step === Steps.FEEDBACK) {
      void onCancel();
    } else {
      setStep(step + 1);
    }
  }

  /* -------------------------- Cancel button setup ------------------------- */
  function onCancel() {
    input.close();
  }

  /* -------------------------------- Return -------------------------------- */
  return {
    form,
    step,
    setStep,
    rightButtons: conditionalList<RightButton>([
      [
        step > Steps.BASIC_INFO && step !== Steps.FEEDBACK,
        {
          isDisabled: form.isSubmitting,
          isLoading: false,
          label: 'Voltar',
          onClick: () => setStep(step - 1),
          variant: 'secondary',
        },
      ],
      [
        true,
        {
          isDisabled: !confirmEnabled,
          isLoading: form.isSubmitting,
          label: confirmLabel,
          onClick: onConfirm,
          variant: 'primary',
        },
      ],
    ]),
    ...(step !== Steps.FEEDBACK
      ? {
          cancelButton: {
            isDisabled: form.isSubmitting,
            label: 'Cancelar',
            onClick: onCancel,
          } satisfies LeftButton,
        }
      : {}),
  };
}

/** Identifies the Focused Flow page steps */
export enum Steps {
  /** Prompts the user for basic information about the policy (title and description) */
  BASIC_INFO = 1,
  /** Prompts the user for the usage rules of the policy (usage limits, withdraw permission etc.) */
  RULES = 2,
  /** Confirmation page, presents a summary of the policy before creating it */
  CONFIRMATION = 3,
  /** Feedback page, presents a feedback component */
  FEEDBACK = 4,
}

type LeftButton = React.ComponentProps<
  typeof FocusedFlow
>['footer']['leftActions'];
type RightButton = React.ComponentProps<
  typeof FocusedFlow
>['footer']['rightActions'][number];
