import { Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import Callout from '@frontend/components/frames/Callout';
import React from 'react';

type Props = {
  title: string;
  description: React.ReactNode;
  value?: boolean;
  onChange?: (_newValue: boolean) => void;
  children?: React.ReactNode;
  /**
   * Toggle position in screen
   *
   * @default left
   */
  togglePosition?: 'left' | 'right';
  /**
   * @default center
   */
  toggleAlign?: 'start' | 'center';
};
export default function ToggleField(props: Props) {
  function renderToggle() {
    return (
      <Toggle
        checked={props.value}
        onChange={(_e, newValue) => {
          props.onChange?.(newValue);
        }}
      />
    );
  }

  return (
    <Callout
      title={props.title}
      description={props.description}
      leftAdornment={props.togglePosition !== 'right' && renderToggle()}
      rightAdornment={props.togglePosition === 'right' && renderToggle()}
      alignItems={props.toggleAlign}
    >
      {props.children}
    </Callout>
  );
}
