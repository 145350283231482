import { OTPField as OTP } from '@flash-tecnologia/hros-web-ui-v2';

type Props = {
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
  length: number;
};

export function OTPField(props: Props) {
  return (
    <OTP
      disabled={props.disabled}
      numInputs={props.length}
      value={props.value}
      /** @ts-expect-error - Bad UI typing due to MUI props override */
      onChange={(value: string) => {
        props.onChange(value);
      }}
    />
  );
}
