import Button from '@frontend/components/Button';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { FailedActivationSummary } from '../FailedActivationSummary/FailedActivationSummary';

type Props = {
  form: UseFormReturn;
};

export function Failure(props: Props) {
  return (
    <Flex direction="column" align="center">
      <Icon name="IconX" background="error" color="error_40" size={48} />
      <Spacer y="xs4" />
      <Typography.Headline8 center>
        Não foi possível ativar o uso corporativo
      </Typography.Headline8>
      <Spacer y="xs4" />
      <Typography.Body4 center color="neutral_40">
        Tivemos um erro ao processar as informações da ativação. Tente novamente
        e, se o erro persistir, entre em contato com o nosso atendimento.
      </Typography.Body4>
      <Spacer y="m" />
      <div>
        <Button
          icon="IconRefresh"
          variant="primary"
          color="error"
          onClick={() => {
            void props.form.onSubmit();
          }}
        >
          Tentar novamente
        </Button>
      </div>
      <Spacer y="m" />
      <FailedActivationSummary form={props.form} />
    </Flex>
  );
}
