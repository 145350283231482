import { GenerateXLSXFile } from '@flash-tecnologia/hros-web-ui-v2';
import { ThreeColumnsTemplate } from '@frontend/components/FocusedFlow/templates/ThreeColumnsTemplate';
import Spacer from '@frontend/components/Spacer';
import Tip from '@frontend/components/Tip';
import Typography from '@frontend/components/Typography';
import NotificationCallout from '@frontend/components/display/NotificationCallout';
import SpreadsheetUpload from '@frontend/components/inputs/SpreadsheetUpload';
import { TRPCClientError } from '@trpc/client';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../controllers/form/useForm';
import useCheckEmployees from './data/useCheckEmployees';

type Props = {
  form: UseFormReturn;
};

export function FileSubmission(props: Props) {
  const checkEmployees = useCheckEmployees();

  return (
    <ThreeColumnsTemplate
      MiddleColumn={
        <>
          <Typography.Headline8 color={'neutral_20'}>
            1. Baixe o modelo Flash e preencha os dados necessários
          </Typography.Headline8>
          <Spacer y={'xs'} />
          <GenerateXLSXFile
            fileName="depósito-cartão-corporativo-flash.xlsx"
            data={[['CPF', 'Valor']]}
            docIconSize={24}
            buttonTitle="Baixar o modelo da Flash em XLSX"
            sheetName="deposito"
            style={{ width: '100%' }}
          />
          <Spacer y={'xs'} />

          <Typography.Headline8 color={'neutral_20'}>
            2. Envie seu arquivo preenchido
          </Typography.Headline8>
          <Spacer y={'xs'} />
          <Controller
            control={props.form.control}
            name="deposits"
            render={({ field }) => (
              <>
                <SpreadsheetUpload
                  onReset={() => checkEmployees.resetErrors()}
                  onUpload={async (data, setProgress) => {
                    try {
                      const result = await checkEmployees.queryAsync(
                        data,
                        setProgress,
                      );
                      field.onChange(result);
                      if (!result.length) {
                        return {
                          success: false,
                          customError: validationErrors.NO_VALID_ROWS,
                        };
                      }
                      return {
                        success: true,
                      };
                    } catch (error) {
                      if (error instanceof TRPCClientError) {
                        return {
                          success: false,
                          customError: validationErrors.QUERY_FAILED,
                        };
                      }
                      return {
                        success: false,
                        customError: validationErrors.GENERIC_ERROR,
                      };
                    }
                  }}
                />
              </>
            )}
          />
          <Spacer y={'xs'} />
          {checkEmployees.errors.hasErrors && (
            <>
              <NotificationCallout
                variant="error"
                title="Encontramos alguns errors no preenchimento da planilha"
                description="Você pode continuar o depósito com os registros que estão corretos, ou então verificar o arquivo e tentar enviá-lo novamente."
              >
                {Object.values(checkEmployees.errors.errorCategories)
                  .filter((errorList) => errorList.rows?.length)
                  .map((errorList, index) => (
                    <Typography.Body4 key={errorList.message}>
                      <b>
                        {index + 1}. {errorList.message}
                      </b>{' '}
                      - Registros: {errorList.rows?.join(', ')}
                    </Typography.Body4>
                  ))}
              </NotificationCallout>
              <Spacer y={'xs'} />
            </>
          )}
          <Tip>
            Se na planilha houverem pessoas que ainda não usam o cartão
            corporativo, elas terão seu uso ativado, desde que já tenham sido
            cadastradas na área Equipe da plataforma.
          </Tip>

          <Spacer y={'m'} />
        </>
      }
    />
  );
}

const validationErrors = {
  NO_VALID_ROWS:
    'Não conseguimos identificar os dados da planilha. Verifique se você está utilizando o modelo da Flash e tente enviá-la novamente.',
  QUERY_FAILED:
    'Não foi possível buscar as informações dos colaboradores. Por favor, tente novamente.',
  GENERIC_ERROR:
    'Não foi possível validar o arquivo. Verifique se você está utilizando o modelo da Flash e tente enviá-lo novamente.',
} as const;
