import { Tab, TabItens, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import ApprovalFlow from '@frontend/pages/ApprovalFlowTab/ApprovalFlow';
import Policies from '@frontend/pages/Policies/Policies';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import PermissionsService from '@frontend/services/Permissions';

export function CustomizationsTab() {
  const permissions = PermissionsService.usePermissions();
  const approvals = FeatureFlagService.getFlag('approvals');
  const show =
    permissions?.includes('*') ||
    permissions?.includes('expense_manage_corporate_cards');

  const tabs: TabItens[] = [
    {
      label: 'Políticas',
      component: <Policies />,
    },
  ];

  if (approvals) {
    tabs.push({
      label: <Typography variant="body4"> Aprovações </Typography>,
      component: <ApprovalFlow />,
    });
  }

  return {
    show,
    label: 'Cartão corporativo',
    component: <Tab variant="secondary" tabItens={tabs} />,
  };
}
