import { trpc } from '@frontend/trpc';
import { toasts } from '../toasts';

type Input = {
  /** Function to close modal when the data cannot be loaded */
  onError: () => void;
};

export default function useGetPolicies(input: Input) {
  const policies = trpc.company.policies.search.useQuery(
    { active: true },
    {
      onError: () => {
        toasts.toastLoadPoliciesError();
        input.onError();
      },
    },
  );

  return {
    data:
      policies.data?.map(
        (policy) =>
          ({
            label: policy.title,
            value: policy._id,
          }) as const,
      ) ?? [],
    isLoading: policies.isLoading,
  };
}
