import styled from 'styled-components';

export const StyledFlex = styled.div`
  grid-column-start: 4;
`;

export const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacings.m};
  min-width: ${({ theme }) => theme.spacings.m};
  height: ${({ theme }) => theme.spacings.m};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;
