import styled from 'styled-components';

export const BgImage = styled.img`
  width: 100%;
  height: 200px;
  pointer-events: none;
  object-fit: cover !important;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borders.radius.l} ${theme.borders.radius.l}`};
`;

export const TitleContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.m} 0px`};
`;
