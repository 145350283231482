import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';
import { UseFormReturn } from '../../../../../../controllers/form/useForm';

type Input = {
  form: UseFormReturn;
};

export function useSummaryData(input: Input) {
  const formValues = input.form.watch();
  return {
    selectedPeopleText: selectedPeopleText(),
    deposit: {
      creditDateDescription: creditDateDescription(),
      depositType: depositType(),
    },
    depositAmount: {
      totalAmount: totalAmount(),
      individualAmount: individualAmount(),
    },
  };

  function creditDateDescription() {
    const creditDate = formValues.deposit.creditDate
      ? DateTime.fromJSDate(formValues.deposit.creditDate, {
          zone: 'America/Sao_Paulo',
        }).startOf('day')
      : null;

    if (!creditDate?.isValid) return null;

    return formValues.deposit.automatic
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy');
  }

  function depositType() {
    if (formValues.deposit.automatic) {
      return formValues.deposit.complementary
        ? 'Depósito automático complementar'
        : 'Depósito automático';
    }
    return formValues.deposit.expires
      ? 'Depósito único com expiração de saldo'
      : 'Depósito único';
  }

  function totalAmount() {
    if (!formValues.deposit.amount) return 'Nenhum depósito definido';
    if (!formValues.employeeIds.size) return 'Nenhuma pessoa selecionada';
    return `${toCurrency(formValues.deposit.amount * formValues.employeeIds.size)} total`;
  }

  function individualAmount() {
    if (!formValues.deposit.amount) return null;
    return `${toCurrency(formValues.deposit.amount)} para cada conta`;
  }

  function selectedPeopleText() {
    const selectedCount = formValues.employeeIds.size;
    if (!selectedCount) {
      return 'Nenhuma pessoa selecionada';
    }
    if (selectedCount === 1) {
      return '1 pessoa selecionada';
    }
    return `${selectedCount} pessoas selecionadas`;
  }
}
