import { usePermissions as usePermissionsWebUtility } from '@flash-tecnologia/hros-web-utility';
import {
  ROUTE_Home_ExternalCard,
  ROUTE_Home_ExternalCards_v2,
} from '@frontend/pages/ExternalCards';
import { ROUTE_ExternalCardDetails } from '@frontend/pages/ExternalCards/subpages/ExternalCardDetails';
import { ROUTE_Home } from '@frontend/pages/Home';
import { ROUTE_EmployeeStatement } from '@frontend/pages/Home/EmployeeStatement';
import { ROUTE_Home_FlashCard } from '@frontend/pages/Home/FlashCard';
import { ROUTE_company_transactions } from '@frontend/pages/Transactions';
import { ROUTE_Billet } from '@frontend/pages/Transactions/Billet';
import { ROUTE_Deposit } from '@frontend/pages/Transactions/Deposits';
import { ROUTE_FlashCash } from '@frontend/pages/Transactions/FlashCash';
import BasePath from '@frontend/routes/BasePath';
import { trpc } from '@frontend/trpc';
import { useBffLog } from '@frontend/utils/log';
import { matchPath } from 'react-router-dom';
import { z } from 'zod';
import AuthService from './AuthService';
import FeatureFlagService from './FeatureFlagService';
import ErrorMonitorService from './MonitorService';

const PermissionsService = {
  getRoutePermission,
  usePermissions,
  getContractPermission,
};
export default PermissionsService;

/** Fetches the permissions for the currently selected company */
function usePermissions() {
  const selectedCompany = AuthService.useCompany();
  if (!selectedCompany) return null;

  const permissions = usePermissionsWebUtility();
  /** Permissions the user has for all of his companies */
  const permissionsParsed = permissionsSchema.safeParse(permissions);

  if (!permissionsParsed.success) {
    ErrorMonitorService.zodError({
      error: permissionsParsed.error,
      extras: {
        company: JSON.stringify(selectedCompany),
        permissions: JSON.stringify(permissions),
      },
    });
    return null;
  }

  /** Permissions for the currently selected company */
  const selectedCompanyPermission = permissionsParsed.data.companies.find(
    (company) => company.id === selectedCompany.id,
  );
  if (!selectedCompanyPermission) {
    ErrorMonitorService.error({
      severity: 'fatal',
      message: 'Company not found in permissions',
      extras: {
        company: JSON.stringify(selectedCompany),
        permissions: JSON.stringify(permissions),
      },
    });
    return null;
  }

  return selectedCompanyPermission.permissions;
}

/** Checks if the user has permission to access the current route */
function getRoutePermission(): boolean {
  const { log } = useBffLog();
  const permissions = usePermissions();
  if (!permissions) return false;

  const hasRoutePermission = permissions
    .filter(
      (permission): permission is Exclude<typeof permission, 'IGNORE'> =>
        permission !== 'IGNORE',
    )
    .some((permissionFlag) => {
      return PERMISSION_TOKENS[permissionFlag].some(
        (token: string) => !!matchPath(token, location.pathname),
      );
    });

  if (!hasRoutePermission) {
    log('permission not found for company', { permissions });
  }

  return hasRoutePermission;
}

function getContractPermission() {
  const { data, isLoading } = trpc.company.contract.hasCorporateCard.useQuery();

  const isAccessViaMenuOrUrl = FeatureFlagService.getFlag('accessViaMenuOrUrl');
  return {
    isValid: isAccessViaMenuOrUrl ? data : true,
    isLoading,
  };
}

/** Maps routes to its required permissions */
const PERMISSION_TOKENS = {
  '*': ['*'],
  expense_manage_corporate_card_balances: [
    ROUTE_Billet.relativePath,
    ROUTE_Deposit.relativePath,
    ROUTE_FlashCash.relativePath,
    ROUTE_company_transactions.relativePath,
    `${BasePath.relativePath}/management`,
    `${BasePath.relativePath}/management/balanceRequests`,
    `${BasePath.relativePath}/management/deposits`,
    `${BasePath.relativePath}/management/deposits/failedCredit`,
    `${BasePath.relativePath}/management/deposits/nextCredit`,
  ],
  expense_manage_corporate_cards: [
    ROUTE_EmployeeStatement.relativePath,
    ROUTE_ExternalCardDetails.relativePath,
    ROUTE_Home.relativePath,
    ROUTE_Home_ExternalCard.relativePath,
    ROUTE_Home_ExternalCards_v2.relativePath,
    ROUTE_Home_FlashCard.relativePath,
    `${ROUTE_Home.relativePath}/:employeeId/statement/:transactionId`,
  ],
} as const;

/** Permissions' names. All permissions that doesn't concerns us are replaced
 * by `IGNORE`
 */
const permissionTokenSchema = z
  .enum([
    '*',
    'expense_manage_corporate_card_balances',
    'expense_manage_corporate_cards',
    'IGNORE',
  ])
  .catch('IGNORE');

const permissionsSchema = z.object({
  companies: z
    .array(
      z.object({
        /** Company id */
        id: z.string(),
        /** Company registration number */
        registrationNumber: z.string(),
        /** Permissions for this company */
        permissions: z.array(permissionTokenSchema),
      }),
    )
    .min(1),
});
