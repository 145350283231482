import { DialogActions } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(DialogActions)`
  display: flex;
  border-top: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.m}`};

  :has(.modal-right-actions-container) {
    justify-content: center;
  }

  :has(#modal-cancel-button) {
    justify-content: space-between;
  }

  .modal-right-actions-container {
    display: flex;
    gap: ${({ theme }) => theme.spacings.xs3};
  }
`;
