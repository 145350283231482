import { MODAL_Deposit } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/Deposit/Deposit';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import ModalService from '@frontend/services/ModalService';
import { RouterOutputs, trpc } from '@frontend/trpc';
import { useState } from 'react';
import useShowRechargeOnboarding from '../../data/useShowRechargeOnboarding';
import { CreateVirtualCorporateCard } from '../../FlashCard/focusedFlows/CreateVirtualCorporateCard';
import AutomaticCreditSetWarningModal from '../../FlashCard/modals/AutomaticCreditSettedWarning/AutomaticCreditSettedWarningModal';
import DisableUserModal from '../../FlashCard/modals/DisableUserModal';
import EditAutomaticCreditModal from '../../FlashCard/modals/EditAutomaticCredit/EditAutomaticCreditModal';
import EnableUserModal from '../../FlashCard/modals/EnableUserModal';
import RefundUserBalanceModal from '../../FlashCard/modals/RefundUserBalance/RefundUserBalanceModal';
import CreateDeposits from '../../focusedFlows/CreateDeposits';
import useCreateDeposits from '../../focusedFlows/CreateDeposits/data/useCreateDeposits';
import useGetEmployeesInfo from '../../focusedFlows/CreateDeposits/data/useGetEmployeesInfo';

type UserPermissions = Omit<
  RouterOutputs['company']['cards']['actions']['preview'],
  'users'
>;

function getEmployeeActions(
  {
    canCreateVirtualCard,
    canDisableUsers,
    canEnableUsers,
    canRefundBalance,
    canEditAutomaticCredit,
    metadata,
  }: UserPermissions,
  user: { email: string; nanoId: string; name: string },
) {
  const { nanoId } = user;

  const shouldShowOnboarding = useShowRechargeOnboarding();

  const createVirtualCard = () => ({
    label: 'Criar Cartão Virtual',
    onClick: () => {
      ModalService.show(CreateVirtualCorporateCard, {
        selectedEmployee: {
          name: user.name,
          email: user.email,
          nanoId: user.nanoId,
        },
      });
    },
  });

  const { mutateAsync } = useGetEmployeesInfo();

  const depositsV2Enabled = FeatureFlagService.getFlag('depositsV2');

  const addCreditAction = () => ({
    label: 'Nova Recarga',
    onClick: () => {
      function showAddCredit() {
        if (depositsV2Enabled) {
          ModalService.show(MODAL_Deposit, {
            defaultValues: {
              employeeIds: new Set([nanoId]),
            },
          });
        } else {
          void mutateAsync(
            {
              ids: [nanoId],
              total: 1,
            },
            {
              onSuccess: (employees) =>
                ModalService.show(CreateDeposits, {
                  initialValues: { employees },
                  useApiHook: useCreateDeposits,
                }),
            },
          );
        }
      }
      shouldShowOnboarding(showAddCredit);
    },
  });

  const editAutomaticCreditAction = () => ({
    label: 'Editar Recarga Automática',
    onClick: () =>
      ModalService.show(EditAutomaticCreditModal, {
        automaticCredit: metadata?.automaticCredit || {},
      }),
  });

  const context = trpc.useContext();

  const refundBalanceAction = () => ({
    label: 'Retornar Saldo para Flash Cash',
    onClick: () =>
      ModalService.show(RefundUserBalanceModal, {
        callback: () => {
          void context.company.cards.get.invalidate();
        },
        total: 1,
        filter: {
          employeeId: [nanoId],
        },
      }),
  });

  const disableCorporateCardAction = () => ({
    label: 'Desativar Cartão Corporativo',
    onClick: () => {
      if (canEditAutomaticCredit) {
        return ModalService.show(AutomaticCreditSetWarningModal, {
          total: 1,
        });
      }

      return ModalService.show(DisableUserModal, {
        callback: () => {
          void context.company.cards.get.invalidate();
        },
        total: 1,
        filter: {
          employeeId: [nanoId],
        },
      });
    },
  });

  const enableCorporateCardAction = () => ({
    label: 'Ativar Cartão Corporativo',
    onClick: () =>
      ModalService.show(EnableUserModal, {
        callback: () => {
          void context.company.cards.get.refetch(undefined);
        },
        total: 1,
        filter: {
          employeeId: [nanoId],
        },
      }),
  });

  const options = [
    ...(canCreateVirtualCard ? [createVirtualCard()] : []),
    ...(canDisableUsers ? [addCreditAction()] : []),
    ...(canEditAutomaticCredit ? [editAutomaticCreditAction()] : []),
    ...(canRefundBalance ? [refundBalanceAction()] : []),
    ...(canDisableUsers ? [disableCorporateCardAction()] : []),
    ...(canEnableUsers ? [enableCorporateCardAction()] : []),
  ];

  return options;
}

export function useEmployeeActions(user: {
  nanoId: string;
  name: string;
  email: string;
  automaticCredit: object;
  balance: number;
  documentNumber: string;
}) {
  const [callPreview, setCallPreview] = useState(false);
  const { data } = trpc.company.cards.actions.preview.useQuery(
    {
      total: 1,
      filter: {
        employeeId: [user.nanoId],
      },
    },
    {
      enabled: callPreview && !!user?.nanoId,
      onSuccess() {
        setCallPreview(false);
      },
    },
  );

  const permissions = data || {
    canCreateVirtualCard: false,
    canDisableUsers: false,
    canEnableUsers: false,
    canCreateAutomaticCredit: false,
    canRefundBalance: false,
    canEditAutomaticCredit: false,
    metadata: {},
    users: [],
  };

  return {
    fetchActions: () => setCallPreview(true),
    actions: getEmployeeActions(permissions, user),
  };
}
