import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';

export const Section = styled(BorderedSection)`
  width: 100%;
`;

type StyledFlexProps = {
  width: string;
};

export const StyledFlex = styled(Flex)<StyledFlexProps>`
  width: ${({ width }) => width};
`;
