import { trpc } from '@frontend/trpc';
import toasts from '../toasts';

type Input = {
  cardId: string;
  policyId: string;
  onSuccess: () => void;
};

export default function useUnlinkPolicy(input: Input) {
  const context = trpc.useContext();
  const unlinkPolicy = trpc.company.policies.cardLinks.patch.useMutation();
  return {
    mutateAsync: () => {
      return unlinkPolicy
        .mutateAsync({
          policyId: input.policyId,
          unlinkCardIds: [input.cardId],
        })
        .then(() => {
          toasts.toastSuccess();
          context.company.cards.search.refetch();
          input.onSuccess();
        })
        .catch(() => {
          toasts.toastServerError();
        });
    },
    isLoading: unlinkPolicy.isLoading,
  };
}
