import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingCoupledVirtualCardTutorialModal from './OnboardingCoupledVirtualCardTutorialModal';

function OnboardingCoupledVirtualCardStartModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function handleAccept() {
    ModalService.show(OnboardingCoupledVirtualCardTutorialModal, {});
    modalController.remove();
  }

  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      highlight={{
        description: 'Novidade!',
        variant: 'announcement',
      }}
      title="Cartão virtual corporativo"
      buttons={{
        cancel: {
          onClick: handleDismiss,
          text: 'Não, obrigado',
        },
        confirm: {
          onClick: handleAccept,
          text: 'Conferir',
        },
      }}
    >
      Agora as pessoas de sua equipe poderão gerar um cartão virtual específico
      de saldo corporativo através do Flash App.
      <br />
      <br />
      Quer entender os benefícios e como o fluxo funciona? Vamos te mostrar!
    </DefaultModal>
  );
}

export default ModalService.create(OnboardingCoupledVirtualCardStartModal);
