import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

function toastLoadPoliciesError() {
  dispatchToast({
    type: 'error',
    content: 'Falha ao buscar políticas',
    description: errors.actions.toastDescription,
  });
}

function toastFormSchemaError() {
  dispatchToast({
    type: 'error',
    content: 'Falha ao validar formulário de vínculo de política de uso',
    description: errors.actions.toastDescription,
  });
}

function toastServerError() {
  dispatchToast({
    type: 'error',
    content: 'Falha ao vincular a política de uso',
    description: errors.actions.toastDescription,
  });
}

function toastSuccess() {
  dispatchToast({
    type: 'success',
    content: 'Política vinculada com sucesso',
  });
}

export const toasts = {
  toastLoadPoliciesError,
  toastFormSchemaError,
  toastServerError,
  toastSuccess,
};
