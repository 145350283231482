import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Button from '@frontend/components/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../form/useForm';
import { useSave } from './useSave';

export function useController() {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { save, isLoading } = useSave();

  const { methods, isLoadingForm } = useForm();

  const submit = methods.handleSubmit((value) => {
    save(value);
  });

  const rightActions = [
    [
      <Button
        size="medium"
        variant="primary"
        onClick={() => {
          void methods
            .trigger(['name', 'description', 'criteria'])
            .then((valid) => {
              if (valid) setStep(step + 1);
            });
        }}
      >
        Continuar
        <Icons name="IconArrowRight" />
      </Button>,
    ],
    [
      <Button
        size="medium"
        variant="secondary"
        onClick={() => {
          setStep(step - 1);
        }}
      >
        <Icons name="IconArrowLeft" />
        voltar
      </Button>,
      <Button
        loading={isLoading}
        size="medium"
        variant="primary"
        onClick={submit}
      >
        Salvar
      </Button>,
    ],
  ];

  const leftActions = [
    <LinkButton size="medium" variant="neutral" onClick={() => navigate(-1)}>
      Cancelar
    </LinkButton>,
  ];

  return {
    step,
    form: methods,
    rightActions: rightActions[step],
    leftActions,
    isLoading: isLoadingForm,
  };
}
