import {
  Icons,
  LinkButton,
  SelectField,
  SelectFieldProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { useNavigate } from 'react-router-dom';

type FilterOptions = SelectFieldProps['filterOptions'];

export function SelectApprover(props: SelectFieldProps) {
  const navigate = useNavigate();
  const { data, isLoading } = trpc.approvalFlow.approvers.useQuery(undefined, {
    onError: (error) => {
      dispatchToast({ type: 'error', content: error.message });
    },
  });

  const options =
    data?.map((approver) => {
      return {
        value: approver.id,
        label: approver.name,
        caption: approver.email,
      };
    }) || [];

  const filterOptions: FilterOptions = (options, { inputValue }) => {
    const filtered = options.filter((_) =>
      _.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
    if (filtered.length > 0) return filtered;

    return [
      {
        disabled: true,
        label: 'Nenhum resultado encontrado',
        caption: (
          <Typography.Caption color="neutral_40">
            Garanta que a pessoa esteja com o perfil de Aprovador Expense para
            você conseguir encontrá-la nesta lista e selecioná-la.
            <LinkButton
              size="medium"
              variant="default"
              onClick={() => navigate('/settings/permissions')}
            >
              Ir para Acessos e permissões
              <Icons name="IconArrowRight" size={20} />
            </LinkButton>
          </Typography.Caption>
        ),
      },
    ];
  };

  return (
    <SelectField
      {...props}
      fullWidth
      options={isLoading ? [{ value: 0, label: 'Buscando...' }] : options}
      filterOptions={filterOptions}
    />
  );
}
