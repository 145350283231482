import { PillButton, SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Typography from '@frontend/components/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { ApprovalFlow } from '../../form/schema';
import { SelectApprover } from './SelectApprover';
import { StyledBadge, StyledFlex } from './styled';
type props = {
  index: number;
};
export function ApproverOptions({ index }: props) {
  const { control, watch, setValue } = useFormContext<ApprovalFlow>();

  const type = watch(`condition.${index}.conditionType`);

  const deleteCondition = () => {
    setValue(
      'condition',
      watch('condition').filter((_, criteriaIndex) => criteriaIndex !== index),
    );
  };
  return (
    <Grid
      templateColumns="auto 1fr 1fr 50px"
      align="center"
      gap="xs2"
      justify="end"
    >
      <Flex>
        <StyledBadge>
          <Typography.Body3 color="primary" weight={700}>
            {index + 1}
          </Typography.Body3>
        </StyledBadge>
      </Flex>
      <Controller
        control={control}
        name={`condition.${index}.conditionType`}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectField
            fullWidth
            error={!!error}
            onSelectChange={(_, option) => onChange(option?.value)}
            label={`Critério`}
            value={value}
            options={[
              {
                value: 'MANUAL',
                label: 'Escolher aprovador manualmente',
                caption:
                  'Selecione uma pessoa com perfil de aprovação para aprovar.',
              },
              {
                value: 'HIERARCHY',
                label: 'Aprovação por hierarquia',
                caption:
                  'Os aprovadores cadastrados a partir do gestor imediato recebem o pedido de saldo para aprovar.',
              },
            ]}
          />
        )}
      />
      {type === 'MANUAL' && (
        <Controller
          control={control}
          name={`condition.${index}.value`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectApprover
              searchable
              error={!!error}
              onSelectChange={(_, option) => onChange(option?.value)}
              label={`Selecione o aprovador`}
              value={value}
            />
          )}
        />
      )}
      {type === 'HIERARCHY' && (
        <Controller
          control={control}
          name={`condition.${index}.value`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectField
              fullWidth
              error={!!error}
              onSelectChange={(_, option) => onChange(option?.value)}
              label={`Níveis de hierarquia`}
              value={value}
              options={[
                { value: '1', label: '1 nível' },
                { value: '2', label: '2 níveis' },
                { value: '3', label: '3 níveis' },
                { value: '4', label: '4 níveis' },
                { value: '5', label: '5 níveis' },
              ]}
            />
          )}
        />
      )}
      <StyledFlex>
        {index > 0 && (
          <PillButton
            variant="default"
            size="medium"
            icon="IconTrash"
            onClick={deleteCondition}
          />
        )}
      </StyledFlex>
    </Grid>
  );
}
