import Flex from '@frontend/components/frames/Flex';
import CardSelect from '@frontend/components/inputs/CardSelect';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../../controllers/form/useForm';
import { useFormMetadata } from '../data/useFormMetadata';
type Props = {
  form: UseFormReturn;
  formMetadata: ReturnType<typeof useFormMetadata>;
};

export function DepositPaymentMethod(props: Props) {
  const paymentMethod = props.form.getValues('deposit.paymentMethod');

  return (
    <Controller
      control={props.form.control}
      name="deposit.paymentMethod"
      render={(ctx) => (
        <Flex gap="xs4" direction="row">
          <CardSelect
            title="Carteira corporativa"
            description={
              props.formMetadata.hasEnoughFlashCash ||
              props.formMetadata.flashCashBalance.isLoading
                ? 'Ideal para fazer depósitos em tempo real e recorrentes.'
                : 'Não há saldo suficiente para realizar os depósitos.'
            }
            icon="IconWallet"
            tag={{
              description: props.formMetadata.flashCashBalance.isLoading
                ? 'Carregando...'
                : `Disponível: ${props.formMetadata.flashCashBalance.balance}`,
              variant: props.formMetadata.flashCashBalance.isLoading
                ? 'neutral'
                : props.formMetadata.hasEnoughFlashCash
                  ? 'success'
                  : 'error',
            }}
            selected={ctx.field.value === 'FLASH_CASH'}
            onClick={() =>
              paymentMethod === 'FLASH_CASH'
                ? ctx.field.onChange()
                : ctx.field.onChange('FLASH_CASH')
            }
            disabled={!props.formMetadata.hasEnoughFlashCash}
          />
          <CardSelect
            title="Gerar código Pix"
            description={
              props.formMetadata.flashCashOnly
                ? 'Este método não está disponível quando a opção Repetir depósito está habilitada.'
                : 'Pagamento instantâneo que pode ser feito em poucos segundos.'
            }
            icon="Pix"
            selected={ctx.field.value === 'PIX'}
            onClick={() =>
              paymentMethod === 'PIX'
                ? ctx.field.onChange()
                : ctx.field.onChange('PIX')
            }
            disabled={props.formMetadata.flashCashOnly}
          />
          <CardSelect
            title="Gerar Boleto bancário"
            description={
              props.formMetadata.flashCashOnly
                ? 'Este método não está disponível quando a opção Repetir depósito está habilitada.'
                : 'O pagamento do boleto será reconhecido em até 3 dias úteis'
            }
            icon="BarcodeMethod"
            selected={ctx.field.value === 'BILLET'}
            onClick={() =>
              paymentMethod === 'BILLET'
                ? ctx.field.onChange()
                : ctx.field.onChange('BILLET')
            }
            disabled={props.formMetadata.flashCashOnly}
          />
        </Flex>
      )}
    />
  );
}
