import Flex from '@frontend/components/frames/Flex';
import BalanceField from '@frontend/components/inputs/BalanceField';
import CardSelect from '@frontend/components/inputs/CardSelect';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { useGetBalances } from '@frontend/external/DashboardSection/balance-summary-box/data/useGetBalances';
import { toCurrency } from '@frontend/utils/masks';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../form/useForm';
import * as SC from './styled';

type Props = {
  form: UseFormReturn;
};

const MIN_VALUE = 1000;

export function Form(props: Props) {
  const { balances, isLoading } = useGetBalances();

  const paymentMethod = props.form.watch('paymentMethod');

  return (
    <>
      <Typography.Body4 color="neutral_40">
        Com a carteira corporativa você pode distribuir saldo para as pessoas a
        qualquer momento e até de forma recorrente.
      </Typography.Body4>
      <Spacer y="xs" />
      <Controller
        name="value"
        control={props.form.control}
        render={(ctx) => (
          <BalanceField
            fullWidth
            value={ctx.field.value}
            label="Valor a ser adicionado (R$)"
            onChange={ctx.field.onChange}
          />
        )}
      />
      <>
        <Spacer y="xs5" />
        <Flex direction="row" grow={1}>
          <Spacer x="xs3" />
          <Typography.Caption weight={600} color="neutral_40">
            {paymentMethod === 'BILLET' &&
              `Valor mínimo ${toCurrency(MIN_VALUE)} • `}
            Saldo atual:&nbsp;
            {!isLoading && toCurrency(balances?.flashCash.actual ?? 0)}
          </Typography.Caption>
          {isLoading && (
            <div>
              <SC.Skeleton width={150} />
            </div>
          )}
        </Flex>
      </>
      <Spacer y="s" />
      <Typography.Headline9 color="neutral_20">
        Escolha a forma de pagamento
      </Typography.Headline9>
      <Spacer y="xs3" />
      <Flex justify="space-between" gap="xs3">
        <Flex grow={1}>
          <Controller
            name="paymentMethod"
            control={props.form.control}
            render={(ctx) => (
              <CardSelect
                width="100%"
                icon="Pix"
                title="Gerar código Pix"
                description="Pagamento instantâneo que pode ser feito em poucos segundos."
                onClick={() => ctx.field.onChange('PIX')}
                selected={ctx.field.value === 'PIX'}
              />
            )}
          />
        </Flex>
        <Flex grow={1}>
          <Controller
            name="paymentMethod"
            control={props.form.control}
            render={(ctx) => (
              <CardSelect
                width="100%"
                icon="IconBarcode"
                title="Gerar Boleto bancário"
                description="O pagamento do boleto será reconhecido em até 3 dias úteis."
                onClick={() => ctx.field.onChange('BILLET')}
                selected={ctx.field.value === 'BILLET'}
              />
            )}
          />
        </Flex>
      </Flex>
    </>
  );
}
