import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import React from 'react';
import Toggle from '../../Toggle';

type Props = {
  disabled?: boolean;
  title: string;
  description: React.ReactNode;
  value: boolean;
  onChange: (newValue: boolean) => void;
  children?: React.ReactNode;
};
export default function ToggleCollapseField(props: Props) {
  return (
    <BorderedSection
      padding={'xs2'}
      backgroundColor={props.disabled ? 'neutral' : undefined}
    >
      <Flex align="center" justify="space-between">
        <Flex direction="column">
          <Typography.Body4
            color={props.disabled ? 'neutral_60' : 'neutral_30'}
            weight={700}
          >
            {props.title}
          </Typography.Body4>
          <Typography.Caption
            color={props.disabled ? 'neutral_60' : 'neutral_40'}
          >
            {props.description}
          </Typography.Caption>
        </Flex>
        <Toggle
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </Flex>
      {props.value && (
        <>
          <Spacer y="xs2" />
          {props.children}
        </>
      )}
    </BorderedSection>
  );
}
