import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '../../Typography';
import * as SC from './styled';

type CardSelectErrorMessageProps = {
  children: string;
};

export default function CardSelectErrorMessage(
  props: CardSelectErrorMessageProps,
) {
  return (
    <>
      <Spacer y="xs5" />
      <SC.ErrorMessageArea>
        <Icon color="error_40" name="IconInfoCircle" size={16} />

        <Typography.Body4 color="neutral_40">{props.children}</Typography.Body4>
      </SC.ErrorMessageArea>
    </>
  );
}
