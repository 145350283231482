import InfoModal from '../../../../components/InfoModal';
import Spacer from '../../../../components/Spacer';
import Tag from '../../../../components/TagV2';
import Typography from '../../../../components/Typography';
import ModalService from '../../../../services/ModalService';

function CorporateMode() {
  const modalController = ModalService.useModalController();

  return (
    <InfoModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      header={{
        noWrap: true,
        title: 'Modo Corporativo',
        subtitle: 'Saiba mais sobre o funcionamento do modo corporativo',
      }}
      children={
        <>
          <Typography.Body3 color="neutral_40">
            Essa função é utilizada quando o cartão possui mais de um saldo
            disponível (por exemplo, saldo de benefício). Com isso, a “chave”
            determina qual saldo será utilizado na hora da compra.
          </Typography.Body3>
          <Spacer y="m" />
          <Tag size="md" variant="neutral">
            Desligado
          </Tag>
          <Spacer y="xs3" />
          <Typography.Body3>
            O modo corporativo está desligado e ao usar o cartão será consumido
            de outro saldo
          </Typography.Body3>
          <Spacer y="m" />
          <Tag size="md" variant="success">
            Ligado
          </Tag>
          <Spacer y="xs3" />
          <Typography.Body3>
            Modo corporativo está ligado e ao usar o cartão será consumido do
            saldo Corporativo.
          </Typography.Body3>
          <Spacer y="xs3" />
        </>
      }
    />
  );
}

export default ModalService.create(CorporateMode);
