import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 24px auto 24px;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs2}`};
  border-bottom: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
`;

export type ContentContainerProps = {
  backgroundColor?: string;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ theme }) => theme.spacings.xs3};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacings.xs2};

  > div {
    gap: ${({ theme }) => theme.spacings.xs2};
  }
`;
