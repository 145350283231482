/** Masks the email to avoid leaking user data
 *
 * @example maskEmail("abcdefgh@mail.com") => "•••••fgh@mail.com"
 * @example maskEmail("a@mail.com") => "•@mail.com"
 * @example maskEmail("abcdefgh") => "abcdefgh"
 */
export function maskEmail(email: string) {
  const emailParts = email.split('@', 2);
  if (emailParts.length === 2) {
    const firstPartLength = emailParts[0].length;
    if (firstPartLength > 3) {
      emailParts[0] =
        '•'.repeat(firstPartLength - 3) +
        emailParts[0].substring(firstPartLength - 3);
    } else {
      emailParts[0] = '•'.repeat(firstPartLength);
    }
  }
  return emailParts.join('@');
}
