import { ThemesType } from '@flash-tecnologia/hros-web-ui-v2';

type Padding = keyof ThemesType['spacings'] | undefined;

export type ComponentPadding =
  | Padding
  | [Padding, Padding]
  | [Padding, Padding, Padding, Padding];

export function makePadding(theme: ThemesType, padding: ComponentPadding) {
  if (Array.isArray(padding)) {
    return padding.map((p) => (p ? theme.spacings[p] : '0px')).join(' ');
  }
  return padding ? theme.spacings[padding] : '0px';
}
