import { Template } from '@frontend/components/FocusedFlow/Template';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import BorderedSection from '@frontend/components/frames/BorderedSection';

import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import NotificationCard from '@frontend/components/NotificationCard';
import ModalService from '@frontend/services/ModalService';
import { toCurrency } from '@frontend/utils/masks';
import { Divider } from '@mui/material';
import { UseFormReturn } from '../../forms/useForm';
import DepositsByEmployeeModal from '../../modals/DepositsByEmployeeModal';
import useFormData from './data/useFormData';
import * as SC from './styles';

type Props = {
  form: UseFormReturn;
};

export default function Confirmation(props: Props) {
  const formData = useFormData({ form: props.form });
  return (
    <Template
      pageInfo={{
        title: 'Fazer depósito',
      }}
      contentInfo={{
        title: 'Revise os detalhes da solicitação',
        description:
          'Aproveite pra conferir os valores e os detalhes do seu depósito.',
      }}
      Modules={
        <>
          <BorderedSection padding="m">
            <SC.Container>
              <div>
                <Typography.Headline8 color={'neutral_20'}>
                  Detalhes do depósito
                </Typography.Headline8>
                <SC.Columns>
                  <div>
                    <Typography.Headline9 color={'neutral_30'}>
                      Pessoas contempladas
                    </Typography.Headline9>
                    <Spacer y="xs4" />
                    <SC.Row>
                      <Icon name="IconUsers" color={'neutral_30'} size={16} />
                      <Typography.Body3 color={'neutral_30'}>
                        {formData.deposits?.length}
                      </Typography.Body3>
                      <LinkButton
                        variant="default"
                        onClick={() => {
                          if (formData.deposits)
                            ModalService.show(DepositsByEmployeeModal, {
                              deposits: formData.deposits,
                            });
                        }}
                      >
                        Ver depósito por pessoa
                      </LinkButton>
                    </SC.Row>
                  </div>
                  <div>
                    <Typography.Headline9 color={'neutral_30'}>
                      Disponibilização do depósito
                    </Typography.Headline9>
                    <SC.Row>
                      <Icon
                        name="IconCalendarEvent"
                        color={'neutral_30'}
                        size={16}
                      />
                      <Typography.Body3 color={'neutral_30'}>
                        {formData.metadata.creditDateDescription}
                      </Typography.Body3>
                    </SC.Row>
                  </div>
                </SC.Columns>
                <Spacer y={'xs'} />
                <Typography.Headline9 color={'neutral_30'}>
                  Tipo de depósito
                </Typography.Headline9>
                <Spacer y={'xs4'} />
                <Typography.Body3 color={'neutral_30'}>
                  {formData.metadata.description}
                </Typography.Body3>
                {formData.depositType === 'AUTOMATIC' && (
                  <>
                    <Spacer y={'xs'} />
                    <NotificationCard
                      iconName="IconInfoCircle"
                      type="info"
                      title="Sobre depósitos automáticos"
                      subtitle="Garanta que a empresa tenha saldo disponível no Flash Cash Corporativo no dia da disponibilização para que os depósitos consigam ser efetuados no mês. Se você tiver definido a data de disponibilização para todo dia 31, os depósitos ocorrerão no dia 30 para os meses que terminam nessa data, e dia 28 para o mês de fevereiro."
                    />
                  </>
                )}
              </div>
              <SC.SpacerColumns>
                <Spacer x={'m'} />
                <Divider orientation="vertical" />
                <Spacer x={'m'} />
              </SC.SpacerColumns>
              <SC.ResultColumn>
                <div>
                  <Typography.Headline7 color={'neutral_20'}>
                    Método de pagamento
                  </Typography.Headline7>
                  <Spacer y={'xs'} />
                  {formData.metadata.paymentMethod && (
                    <SC.Row>
                      <div style={{ width: '60px', height: '60px' }}>
                        <Icon
                          name={formData.metadata.paymentMethod.icon}
                          color="primary"
                          size={64}
                          background="default"
                        />
                      </div>
                      <div>
                        <Typography.Headline9 color={'neutral_30'}>
                          {formData.metadata.paymentMethod.title}
                        </Typography.Headline9>
                        <Typography.Body4 color={'neutral_30'}>
                          {formData.metadata.paymentMethod.description}
                        </Typography.Body4>
                      </div>
                    </SC.Row>
                  )}
                  <Spacer y={'xs'} />
                </div>
                <div>
                  <Typography.Headline7 color={'neutral_20'}>
                    Total do pedido
                  </Typography.Headline7>
                  <Spacer y={'xs4'} />
                  <Typography.Headline6 color="secondary_50">
                    {`${formData.complementary ? 'Até ' : ''}${toCurrency(
                      formData.metadata.totalAmount,
                    )}`}
                  </Typography.Headline6>
                </div>
              </SC.ResultColumn>
            </SC.Container>
          </BorderedSection>
          <Spacer y={'m'} />
        </>
      }
    />
  );
}
