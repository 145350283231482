import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { RouterInputs, trpc } from '@frontend/trpc';
import { toasts } from '../forms/toasts';

export default function useEditPolicy() {
  const editPolicy = trpc.company.policies.patch.useMutation();
  const context = trpc.useContext();
  const limitByCategoryEnabled = FeatureFlagService.getFlag(
    'limitByCategoryPolicy',
  );

  return {
    /** Edits a policy
     *
     * Returns policy data if successful, void otherwise */
    mutateAsync: async (
      input: RouterInputs['company']['policies']['patch'],
    ) => {
      const exec = async () => {
        return editPolicy
          .mutateAsync(input)
          .then((data) => {
            // Invalidates cache for the policy search
            void context.company.policies.search.invalidate();
            void context.company.policies.get.invalidate({
              policyId: input.id,
            });

            !limitByCategoryEnabled && toasts.toastSuccess();
            return data;
          })
          .catch(() => {
            toasts.toastError();
          });
      };

      return exec();
    },
    data: editPolicy.data,
    isLoading: editPolicy.isLoading,
  };
}

export type UseEditPolicyReturn = ReturnType<typeof useEditPolicy>;
