import Callout from '@frontend/components/frames/Callout';
import Icon from '@frontend/components/Icon';
import { ComboBoxField } from '@frontend/components/inputs/ComboBoxField/ComboBoxField';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { Controller } from 'react-hook-form';
import { FormSchemaOutput, UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function CategoriesMultiselect(props: Props) {
  return (
    <Callout
      title="Categorias de uso"
      description="Configure as categorias nas quais transações feitas com esse cartão serão aceitas."
      alignItems="start"
      leftAdornment={
        <Icon
          name="IconCategory"
          size={48}
          color="secondary_50"
          background="default"
        />
      }
    >
      <Controller
        control={props.form.control}
        name="mccGroups"
        render={({ field }) => {
          return (
            <ComboBoxField
              label="Categorias ativas na política"
              onChange={(
                selectedOptions: Set<keyof typeof translatedMccGroups>,
              ) => {
                const categories = {} as NonNullable<
                  FormSchemaOutput['mccGroups']
                >;

                for (const mcc of selectedOptions) {
                  categories[mcc] = true;
                }

                field.onChange(categories);
              }}
              options={sortedMccGroups.map((mcc) => ({
                value: mcc,
                title: translatedMccGroups[mcc].title,
                description: translatedMccGroups[mcc].description,
              }))}
              value={
                new Set(
                  Object.entries(field.value)
                    .filter(([_, value]) => value)
                    .map(([mccGroup, _]) => mccGroup),
                )
              }
            />
          );
        }}
      />
    </Callout>
  );
}
