import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import { SurveyStore } from '@frontend/components/SurveyStore';
import Typography from '@frontend/components/Typography';
import { CARDS_HOME_TITLE } from '@frontend/pages/Home/Home';
import { useNavigate } from 'react-router-dom';

export default function Feedback() {
  const navigate = useNavigate();

  return (
    <Flex direction="column" align="center" justify="center">
      <Icon
        background={'success'}
        color={'success_40'}
        name={'IconCheck'}
        size={64}
      />
      <Spacer y="xs2" />
      <Flex
        direction="column"
        align="center"
        justify="center"
        style={{ width: '600px' }}
      >
        <Typography.Headline6
          style={{ width: '420px' }}
          center
          color="neutral_10"
        >
          Política atualizada! Agora é só vincular os cartões
        </Typography.Headline6>
        <Spacer y="s" />
        <Typography.Body3 weight={400} color="neutral_40" center>
          Em <strong>{CARDS_HOME_TITLE}</strong>, você pode selecionar os
          cartões que deseja vincular a essa política.
        </Typography.Body3>
        <Spacer y="xs4" />
        <Typography.Body3 weight={400} color="neutral_40" center>
          Você pode fazer isso com quantos cartões desejar, assim como
          desvinculá-los da política quando for necessário.
        </Typography.Body3>
        <Spacer y="xs2" />
        <img
          style={{ width: '350px', borderRadius: '12px' }}
          src="https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/policies/link-card-policy.gif"
          alt="Tutorial para vínculo de política a um cartão"
        />
        <Spacer y="xs2" />
        <div>
          <Button
            variant="primary"
            size="large"
            onClick={() => navigate('/corporateCard/home')}
          >
            Ir para {CARDS_HOME_TITLE}
            <Icons name="IconArrowRight" />
          </Button>
        </div>
        <Spacer y="s" />
        <SurveyStore
          title={`Como você avalia a facilidade de uso da plataforma ao editar uma política?`}
          type="policy-feedback"
          params={{
            flow: 'edit',
          }}
        />
      </Flex>
    </Flex>
  );
}
