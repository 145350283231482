import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { DateTime } from 'luxon-business-days';
import React from 'react';
import { useCreateDeposit } from '../../data/useCreateDeposit';
import { DepositFormSchema } from './schema';

type Input = {
  onSuccess: () => void;
  onAuthError: () => void;
};

export function useForm(input: Input) {
  const form = FormService.useCreateForm(DepositFormSchema, {
    mode: 'onChange',
    defaultValues: {
      automatic: false,
      complementary: false,
      expires: false,
    },
  });

  /* --------------------- Additional schema validations -------------------- */
  const formValues = form.watch();

  /** When `expires` is enabled, disable `automatic` and `complementary` */
  React.useEffect(() => {
    if (formValues.expires) {
      form.setValue('automatic', false);
      form.setValue('complementary', false);
    } else {
      form.resetField('expirationDate');
    }
  }, [formValues.expires]);

  /** When `automatic` is enabled:
   * - disable `expires`;
   * - deselect PIX/BILLET payment method;
   */
  React.useEffect(() => {
    if (formValues.automatic) {
      form.setValue('expires', false);
      if (isIncluded(formValues.paymentMethod, ['BILLET', 'PIX'])) {
        form.resetField('paymentMethod');
      }
    }
  }, [formValues.automatic]);

  /** Checks if billet deposits are at least 3 business days in the future */
  React.useEffect(() => {
    if (!formValues.creditDate) return;

    if (formValues.paymentMethod === 'BILLET') {
      const createDateFrom = DateTime.now()
        .plusBusiness({ days: 3 })
        .startOf('day')
        .toJSDate();

      if (formValues.creditDate < createDateFrom) {
        form.setError('creditDate', {
          type: 'custom',
          message:
            'Para pagamentos com Boleto, a data de depósito precisa ser a partir de 3 dias úteis',
        });
        return;
      }
    }
    void form.trigger('creditDate');
  }, [formValues.creditDate, formValues.paymentMethod]);
  /* ------------------------------- On Submit ------------------------------ */
  const createDeposit = useCreateDeposit();

  const onSubmit = form.handleSubmit(
    async (values) => {
      await createDeposit.mutateAsync({
        deposit: values,
        onSuccess: input.onSuccess,
        onAuthError: input.onAuthError,
      });
    },
    (error) => {
      dispatchToast({
        type: 'error',
        content: 'Falha ao validar formulário de depósito via planilha',
        description: errors.actions.toastDescription,
      });
      ErrorMonitorService.error({
        message: 'Error while creating deposit from spreadsheet',
        severity: 'error',
        extras: {
          zodErrors: JSON.stringify(error),
        },
      });
    },
  );

  return {
    ...form,
    onSubmit,
    result: createDeposit.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
