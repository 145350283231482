import { useAdminMfaManager } from '@flash-tecnologia/hros-web-utility';
import AuthService from '@frontend/services/AuthService';
import { maskEmail } from '@frontend/utils/masks/maskEmail';
import React from 'react';

export function useSendConfirmationCode() {
  const confirmationCode = useAdminMfaManager();
  const user = AuthService.useUser();

  React.useEffect(() => {
    void confirmationCode.sendCode();
  }, []);

  return {
    email: user?.email ? maskEmail(user.email) : '-',
    loading: confirmationCode.loadingSendCode,
    timeRemaining: confirmationCode.timeRemaining,
    resend: confirmationCode.sendCode,
  };
}
