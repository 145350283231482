import { Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import LinkButton from '@frontend/components/LinkButton';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { useSummaryData } from '../../data/useSummaryData';

export function DepositSucceeded(data: ReturnType<typeof useSummaryData>) {
  if (!data.deposit.paymentDetails) return null;
  return (
    <Flex padding={'xs2'} gap="xs2" direction="column">
      <Flex gap="xs4" justify="space-between" align="center">
        <Flex gap="xs4">
          <Tag
            size="xs"
            variant={data.deposit.paymentDetails.paymentStatus.variant}
            dot
          >
            {data.deposit.paymentDetails.paymentStatus.label}
          </Tag>
          {data.deposit.paymentDetails.paymentMethod && (
            <Tag size="xs" variant="neutral">
              <Flex align="center" gap="xs5">
                <Icon
                  name={data.deposit.paymentDetails.paymentMethod.icon}
                  color="neutral_10"
                  size={14}
                />
                {data.deposit.paymentDetails.paymentMethod.label}
              </Flex>
            </Tag>
          )}
        </Flex>
        {data.downloadPdf &&
          (data.downloadPdf.isLoading ? (
            <Spinner variant="primary" size={24} />
          ) : (
            <LinkButton
              disabled={data.downloadPdf.disabled}
              icon={data.downloadPdf.disabled ? 'IconMail' : 'IconDownload'}
              iconPosition="left"
              onClick={data.downloadPdf.onClick}
              variant="default"
            >
              {data.downloadPdf.label}
            </LinkButton>
          ))}
      </Flex>
      <Typography.Body4 color="neutral_30">
        {data.downloadPdf?.emailText}{' '}
        <Typography.Body4 weight={700} as="span">
          {data.downloadPdf?.email}
        </Typography.Body4>
      </Typography.Body4>
      <Flex backgroundColor="neutral_95" radius="s" padding={['xs4', 'xs3']}>
        <Flex gap="xs4" align="center" grow={1}>
          <Icon name="IconRepeat" color="neutral_40" background="neutral" />
          <Flex direction="column">
            <Typography.Body4 color="neutral_40" weight={600}>
              {data.deposit.depositType}
            </Typography.Body4>
            <Typography.Caption color="neutral_40">
              {data.deposit.creditDateDescription}
            </Typography.Caption>
          </Flex>
        </Flex>
        <Flex gap="xs4" align="center" grow={1}>
          <Icon name="IconCoin" color="neutral_40" background="neutral" />
          <Flex direction="column">
            <Typography.Body4 color="neutral_40" weight={600}>
              {data.deposit.depositAmount.totalAmount}
            </Typography.Body4>
            <Typography.Caption color="neutral_40">
              Diversos valores por pessoa
            </Typography.Caption>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
