import { PillButton, Radio } from '@flash-tecnologia/hros-web-ui-v2';
import Button from '@frontend/components/Button';
import Grid from '@frontend/components/frames/Grid';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApprovalType } from './data/useApprovalType';
import Feedback from './Feedback/Feedback';
import * as SC from './styled';

export default function ApprovalFlow() {
  const { approvalType, setApprovalType } = useApprovalType();
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();

  return (
    <SC.Section padding="s">
      <Grid templateColumns="3fr 1fr" justify="end">
        <SC.StyledFlex direction="column" width="100%">
          <Typography.Headline8 color="neutral_30">
            Fluxo de aprovação para pedidos de saldo
          </Typography.Headline8>
          <Typography.Body4>
            Configure como funcionará o fluxo de aprovação para pedidos de saldo
            corporativo.
          </Typography.Body4>
        </SC.StyledFlex>
        <PillButton
          size="small"
          variant="default"
          icon="IconMessageChatbot"
          label="Deixe sua opinião"
          iconPosition="left"
          onClick={() => setShowFeedback(true)}
        />
        <Feedback open={showFeedback} onClose={() => setShowFeedback(false)} />
      </Grid>
      <Spacer y="s" />
      <SC.StyledFlex direction="column" width="80%" gap="xs">
        <SC.Section
          padding="xs2"
          variant={approvalType === 'AUTO' ? 'secondary' : 'neutral'}
        >
          <Grid templateColumns="1fr 20px" gap="xs2">
            <SC.StyledFlex direction="column" width="100%">
              <Typography.Body4 color="neutral_30" weight={700}>
                Aprovação automática
              </Typography.Body4>
              <Typography.Caption color="neutral_40" weight={700}>
                Os pedidos feitos pela equipe serão enviados diretamente para
                revisão do depósito em Saldos e cartões, sem a necessidade de um
                aprovador.
              </Typography.Caption>
            </SC.StyledFlex>
            <Radio
              checked={approvalType === 'AUTO'}
              onChange={() => setApprovalType('AUTO')}
            ></Radio>
          </Grid>
        </SC.Section>
        <SC.Section
          padding="xs2"
          variant={approvalType === 'CUSTOM' ? 'secondary' : 'neutral'}
        >
          <Grid templateColumns="1fr 20px" gap="xs2">
            <SC.StyledFlex direction="column" width="100%">
              <Typography.Body4 color="neutral_30" weight={700}>
                Aprovação personalizada
              </Typography.Body4>
              <Typography.Caption color="neutral_40" weight={700}>
                Crie um fluxo personalizado de acordo com as necessidades da
                empresa
              </Typography.Caption>
              <Spacer y="xs" />
              {approvalType === 'CUSTOM' && (
                <PillButton
                  size="small"
                  variant="pink"
                  icon="IconArrowRight"
                  label="Criar fluxo personalizado"
                  type="primary"
                  onClick={() =>
                    navigate('corporateCard/balance/approval-flow')
                  }
                />
              )}
            </SC.StyledFlex>
            <Radio
              checked={approvalType === 'CUSTOM'}
              onChange={() => setApprovalType('CUSTOM')}
            ></Radio>
          </Grid>
        </SC.Section>
      </SC.StyledFlex>
      <Spacer y="s" />
      <Button variant="primary" onClick={() => {}}>
        Salvar Alteração
      </Button>
    </SC.Section>
  );
}
