import { Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';

export function ListLoading() {
  return (
    <Flex align="center" justify="center" padding={['m', undefined]}>
      <Spinner variant="primary" size={48} />
    </Flex>
  );
}
