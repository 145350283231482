import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '../Icon';
import Typography from '../Typography';
import { useVersion } from './context';
import { Feature } from './context/types';
import { VERSIONS_CONTENT } from './context/versions.contant';
import * as SC from './styled';

type Props = {
  feature: Feature;
};

export default function VersionCalloutNew(props: Props) {
  const context = useVersion(props.feature);
  const featureContent = VERSIONS_CONTENT[props.feature];

  if (!context.showCallout) {
    return null;
  }

  return (
    <SC.Container useNewVersion>
      <Icon
        name="IconSparkles"
        size={24}
        color="secondary_50"
        background="default"
      />

      <Typography.Body4 weight={700} color="secondary_50">
        Esta área vai mudar!
      </Typography.Body4>

      <Typography.Body4 color="secondary_10">
        Estamos melhorando a experiência de {featureContent.featureName}. Você
        já pode testar a nova versão agora!
      </Typography.Body4>

      <SC.ActionContainer>
        <PillButton
          icon="IconArrowRight"
          size="small"
          label="Experimentar"
          type="primary"
          variant="pink"
          onClick={() => context.onChangeVersion()}
        />
      </SC.ActionContainer>
    </SC.Container>
  );
}
