import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import LinkButton from '@frontend/components/LinkButton';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { ComboBoxFieldProps } from '../../ComboBoxField';

type Props = {
  emptyState: NonNullable<ComboBoxFieldProps['emptyState']>;
};

export function ListNoSearchResults(props: Props) {
  return (
    <Flex
      align="center"
      justify="center"
      padding={['m', undefined]}
      direction="column"
    >
      <Icon
        name="IconZoomCancel"
        size={40}
        background="neutral"
        color="neutral_40"
      />
      <Spacer y="xs4" />
      <Typography.Body4 color="neutral_20" weight={600}>
        {props.emptyState.filtered.title}
      </Typography.Body4>
      <Typography.Caption color="neutral_40">
        {props.emptyState.filtered.description}
      </Typography.Caption>
      {props.emptyState.filtered.linkButton && (
        <>
          <Spacer y="xs3" />
          <LinkButton
            variant="default"
            onClick={props.emptyState.filtered.linkButton.onClick}
            icon="IconArrowRight"
          >
            {props.emptyState.filtered.linkButton.label}
          </LinkButton>
        </>
      )}
    </Flex>
  );
}
