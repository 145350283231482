import { LinkButton, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Flex from '@frontend/components/frames/Flex';
import SelectField from '@frontend/components/inputs/SelectField';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import { ROUTE_CompanyCustomization } from '@frontend/routes/ExternalRoutes';
import ModalService from '@frontend/services/ModalService';
import { Controller } from 'react-hook-form';
import { AddFirstPolicy } from './AddFirstPolicy';
import useGetPolicies from './data/useGetPolicies';
import useForm from './form/useForm';

type Props = {
  cardId: string;
};

function LinkPolicyModal(props: Props) {
  const controller = ModalService.useModalController();
  const form = useForm({ cardId: props.cardId, onSuccess: handleClose });
  const policies = useGetPolicies({ onError: handleClose });
  const hasPolicies = policies.data.length > 0;

  /** Closes the LinkPolicyModal modal */
  function handleClose() {
    controller.remove();
  }

  function Content() {
    if (policies.isLoading)
      return (
        <Flex align="center" justify="center">
          <Spinner size={64} variant="primary" />
        </Flex>
      );
    if (!hasPolicies) return <AddFirstPolicy onAddPolicyClick={handleClose} />;
    return (
      <>
        <Controller
          control={form.control}
          name="policyId"
          render={({ field, fieldState }) => (
            <SelectField
              fullWidth
              label="Selecione uma política"
              {...field}
              options={policies.data}
              variant="filled"
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Spacer y="xs2" />
        <LinkButton
          style={{ width: 'fit-content' }}
          variant="default"
          target="_blank"
          href={ROUTE_CompanyCustomization.buildPath({
            searchParams: { tab: 'corporate-card' },
          })}
        >
          Ver todas as políticas
          <Icon name="IconExternalLink" color="primary" />
        </LinkButton>
      </>
    );
  }

  return (
    <DefaultModal
      buttons={
        hasPolicies
          ? {
              cancel: {
                text: 'Cancelar',
                onClick: handleClose,
              },
              confirm: {
                text: 'Vincular',
                disabled: !form.isValid || !policies.data.length,
                onClick: form.onSubmit,
              },
            }
          : undefined
      }
      title="Vincular política de uso do cartão"
      subtitle="Vincule ao cartão corporativo uma política de uso definida pela empresa."
      isOpen={controller.visible}
      onClose={controller.remove}
      loading={form.isSubmitting}
    >
      <Content />
    </DefaultModal>
  );
}

export default ModalService.create(LinkPolicyModal);
