import PermissionsService from '@frontend/services/Permissions';

export function checkAuth() {
  const hasRoutePermission = PermissionsService.getRoutePermission();
  const contractPermission = PermissionsService.getContractPermission();

  return {
    isAuthorized: hasRoutePermission && contractPermission.isValid,
    isLoading: contractPermission.isLoading,
  };
}
