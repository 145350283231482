import Tag from '@frontend/components/TagV2';
import { ComponentProps } from 'react';

export type CardSelectTagProps = {
  description: string;
  disabled?: boolean;
  variant: ComponentProps<typeof Tag>['variant'];
};

export default function CardSelectTag(props: CardSelectTagProps) {
  return (
    <Tag variant={props.variant} size="xs">
      {props.description}
    </Tag>
  );
}
