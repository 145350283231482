import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { RouterOutputs } from '@frontend/trpc';
import Spacer from '../../../../components/Spacer';
import { useMenuActions } from './dataSource';

type Props = {
  deposit: RouterOutputs['company']['deposits']['search']['deposits'][number];
};

export function MenuActions(props: Props) {
  const { menuOptions } = useMenuActions({
    deposit: props.deposit,
  });

  return menuOptions.length ? (
    <Menu type="default" locked options={menuOptions}>
      <PillButton size="medium" variant="default" icon="IconDotsVertical" />
    </Menu>
  ) : (
    <div>
      <Spacer y="m" />
      <Spacer y="xs3" />
    </div>
  );
}
