import LazyComponent from '@frontend/components/LazyComponent';
import RouterService from '@frontend/services/RouterService';
import { PageMetadata } from '@frontend/utils/types/pageMetadata';
import { Route } from 'react-router-dom';

const RequestBalance = () => (
  <LazyComponent factory={() => import('./RequestBalance')} />
);

export const METADATA_RequestBalance = {
  title: 'Saldos e cartões corporativos',
} as const satisfies PageMetadata;

export const ROUTE_RequestBalance = RouterService.create(
  'corporateCard/user-accounts/request-balance',
);

export const RouteComponent_RequestBalance = (
  <Route index path={ROUTE_RequestBalance.path} element={<RequestBalance />} />
);
