import Flex from '@frontend/components/frames/Flex';
import LinkButton from '@frontend/components/LinkButton';
import { SurveyStore } from '@frontend/components/SurveyStore';
import Typography from '@frontend/components/Typography';
import AuthService from '@frontend/services/AuthService';
import { maskEmail } from '@frontend/utils/masks/maskEmail';
import { UseFormReturn } from '../../form/useForm';
import * as SC from './styled';

type Props = {
  form: UseFormReturn;
};

export function Success(props: Props) {
  const paymentMethod = props.form.getValues('paymentMethod');
  const user = AuthService.useUser();

  return (
    <SC.Wrapper gap="xs" direction="column">
      <Typography.Body4 color="neutral_40">
        O {paymentMethod === 'PIX' ? 'código Pix' : 'boleto'} será enviado para
        o e-mail <strong>{user?.email ? maskEmail(user.email) : '-'}</strong>.
        Você pode acompanhar o status no Extrato da Carteira.
      </Typography.Body4>
      <Flex
        radius="m"
        padding={['xs3', 'xs2']}
        gap="xs2"
        direction="column"
        backgroundColor="secondary_99"
        borderColor="secondary_95"
        borderWidth="xs2"
      >
        <Flex justify="space-between" align="center">
          <Typography.Body4 color="neutral_30" weight={700}>
            Deposite saldo para a equipe
          </Typography.Body4>
          <LinkButton
            underlined={false}
            icon="IconArrowRight"
            variant="default"
            size="sm"
          >
            Acessar
          </LinkButton>
        </Flex>
        <Typography.Caption color="neutral_40">
          Distribua para sua equipe o saldo que foi adicionado na carteira
          corporativa, para que ela possa utilizar seus cartões.
        </Typography.Caption>
      </Flex>
      <SurveyStore
        title="Como você avalia a facilidade de uso da plataforma ao adicionar saldo na carteira corporativa?"
        type="add-flash-cash-feedback"
      />
    </SC.Wrapper>
  );
}
