import { ModalV2 as Modal } from '@frontend/components/modals/ModalV2';
import ModalService from 'src/services/ModalService';
import { useController } from './data/useController';

function AddFlashCashModal() {
  const controller = ModalService.useModalController();
  const flowController = useController();

  return (
    <Modal
      highlight={flowController.highlight}
      title={flowController.header}
      open={controller.visible}
      onClose={controller.remove}
      size={500}
      actions={flowController.actions}
    >
      {flowController.content}
    </Modal>
  );
}

export default ModalService.create(AddFlashCashModal);
