import { Feature, VersionContent } from './types';

export const VERSIONS_CONTENT: Record<Feature, VersionContent> = {
  accountManagement: {
    featureName: 'Cartão corporativo',
    survey: {
      title:
        'Como você avalia a nova experiência da nova experiência de Cartão corporativo?',
      type: 'account-management-v2-feedback',
    },
  },
} as const;
