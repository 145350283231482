import { IconTypes, PillButton } from '@flash-tecnologia/hros-web-ui-v2';

import Icon from '../Icon';
import Typography from '../Typography';
import * as SC from './styled';

export type SummaryHeaderProps = {
  icon: IconTypes;
  title: string;
  onClickViewMore?: () => void;
};

export function SummaryHeader(props: SummaryHeaderProps) {
  return (
    <SC.HeaderContainer>
      <Icon
        name={props.icon}
        color="neutral_40"
        background="neutral"
        size={24}
      />

      <Typography.Body4 color="neutral_30" weight={600}>
        {props.title}
      </Typography.Body4>

      {props.onClickViewMore && (
        <PillButton
          icon="IconInfoCircle"
          variant="default"
          size="extra-small"
          onClick={props.onClickViewMore}
        />
      )}
    </SC.HeaderContainer>
  );
}
