import { SelectField as SelectFieldUI } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import { WritableDeep } from 'type-fest';

type Props<TOption extends string> = {
  label: string;
  options: Readonly<
    Array<{
      value: TOption;
      label: string;
      caption?: string;
      icon?: React.ReactNode;
    }>
  >;
  onChange: (_selectedValue: TOption) => void;
  variant: 'standard' | 'filled' | 'outlined';
  value?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: () => void;
  fullWidth?: boolean;
};

export default function SelectField<TOption extends string>(
  props: Props<TOption>,
) {
  return (
    <StyledContainer fullWidth={!!props.fullWidth}>
      <SelectFieldUI
        disabled={props.disabled}
        label={props.label}
        value={props.value}
        fullWidth
        options={props.options as WritableDeep<Props<TOption>>['options']} // `as` needed due to wrong typing in the UI component
        variant={props.variant}
        onSelectChange={(_e, { value }: { value: TOption }) => {
          props.onChange(value);
        }}
        error={props.error}
        helperText={props.helperText}
        onBlur={props.onBlur}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? `100%` : 'unset')};
`;
