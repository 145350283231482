import Typography from '@frontend/components/Typography';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';

type Props = {
  onDecideLater: () => void;
  openFocusedFlow: () => void;
};

function DecideLaterModal(props: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja sair?',
        body: (
          <Typography.Body4 color="neutral_40">
            Você ainda deverá pedir os novos cartões Visa em um outro momento.
          </Typography.Body4>
        ),
      }}
      variant="error"
      buttons={{
        cancel: {
          onClick: () => {
            modalController.remove();
            props.openFocusedFlow();
          },
          text: 'Continuar com o pedido',
          variant: 'neutral',
        },
        confirm: {
          text: 'Sair',
          onClick: () => {
            modalController.remove();
            props.onDecideLater();
          },
        },
      }}
    />
  );
}

export default ModalService.create(DecideLaterModal);
