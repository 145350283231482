import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Spacer from '@frontend/components/Spacer';
import { useFormContext } from 'react-hook-form';
import { ApprovalFlow } from '../../form/schema';
import { ApproverOptions } from './ApproverSelector';
export function SelectApproverMode() {
  const { watch, setValue } = useFormContext<ApprovalFlow>();
  const condition = watch('condition');
  return (
    <>
      <Spacer y="xs2" />
      <Grid templateColumns="1fr" gap="xs2">
        {condition?.map((_, index) => {
          return <ApproverOptions index={index} />;
        })}
        <Flex>
          <LinkButton
            variant="default"
            onClick={() => setValue('condition', [...condition, {} as any])}
          >
            {'Adicionar nível +'}
          </LinkButton>
        </Flex>
      </Grid>
    </>
  );
}
