import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import AuthService from '@frontend/services/AuthService';
import ModalService from '@frontend/services/ModalService';
import { TrackingService } from '@frontend/services/TrackingService';
import React, { useEffect, useState } from 'react';
import VersionFeedbackModal from '../Old/VersionFeedbackModal/VersionFeedbackModal';
import { Feature, FeatureInLocalStorage, VersionContextReturn } from './types';
import { VERSIONS_CONTENT } from './versions.contant';

export const VersionContext = React.createContext<VersionContextReturn>({
  isNewVersion: () => false,
  onChangeVersion: () => null,
  onShowSurveyModal: () => null,
});

type ProviderProps = {
  children: React.ReactNode;
};

export function VersionProvider(props: ProviderProps) {
  const company = AuthService.useCompany();
  const employeeId = AuthService.useEmployeeId();

  const key = `corporate-card-feature-versions-${company?.id}`;

  const [versions, setVersions] = useState<FeatureInLocalStorage>(
    (getFromLS(key) as FeatureInLocalStorage) ?? {},
  );

  useEffect(() => {
    setVersions((getFromLS(key) as FeatureInLocalStorage) ?? {});
  }, [key]);

  function onChangeVersion(feature: Feature) {
    const versionContent = VERSIONS_CONTENT[feature];

    if (!versionContent) {
      throw new Error('Invalid feature name');
    }

    const isActive = !versions[feature];

    setVersions((current) => {
      current[feature] = isActive;

      return { ...current };
    });
    isActive
      ? versionContent.onChangeToNewVersion?.()
      : versionContent.onChangeToLastVersion?.();

    if (employeeId && company?.id) {
      TrackingService.track(
        'feature-version',
        {
          feature,
          active: isActive ? 'true' : 'false',
        },
        {
          employeeId,
          companyId: company.id,
        },
      );
    }

    setInLS({ key, value: versions });
  }

  function onShowSurveyModal(feature: Feature) {
    const modalContent = VERSIONS_CONTENT[feature];

    if (modalContent) {
      ModalService.show(VersionFeedbackModal, { ...modalContent, feature });
      return;
    }

    throw new Error('Invalid feature on show SurveyModal');
  }

  function getVersion(feature: Feature) {
    return versions[feature] ?? false;
  }

  function isNewVersion(feature: Feature) {
    return getVersion(feature);
  }

  return (
    <VersionContext.Provider
      value={{
        isNewVersion,
        onChangeVersion,
        onShowSurveyModal,
      }}
    >
      {props.children}
    </VersionContext.Provider>
  );
}
