import { checkAuth } from '@frontend/utils/auth/checkAuth';
import { Navigate, Outlet } from 'react-router-dom';

export default function CheckPermissions() {
  const authorized = checkAuth();

  if (!authorized.isAuthorized && !authorized.isLoading) {
    return <Navigate to={'/home'} />;
  }

  return <Outlet />;
}
