import { Template } from '@frontend/components/FocusedFlow/Template';
import { CARDS_HOME_TITLE } from '@frontend/pages/Home/Home';
import ModalService from '@frontend/services/ModalService';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import _isEmpty from 'lodash/isEmpty';
import _remove from 'lodash/remove';
import { useState } from 'react';
import readXlsxFile from 'read-excel-file';
import {
  DownloadTemplateModule,
  UploadModule,
  ValidationErrorModule,
} from '../components/modules';
import { useUploadTransaction } from './useUploadTransaction';
import { useValidation } from './useValidation';

export const useUploadTransactionController = () => {
  const modalController = ModalService.useModalController();

  const [invalidInputList, setInvalidInputList] = useState<string[][]>([]);
  const [validInputList, setValidInputList] = useState<string[][]>([]);

  const { isLoading, upload } = useUploadTransaction();
  const { validate } = useValidation();

  const validateFile = async (fileListInput: Array<{ data: ArrayBuffer }>) => {
    /** Matrix representation of the input spreadsheet */
    const table =
      fileListInput[0] &&
      ((await readXlsxFile(fileListInput[0].data)) as string[][]);

    try {
      const errors = validate(table);
      // Removes header by shifting the first row out
      table.shift();

      // Appends the errors to the table as a new column
      Object.keys(errors).map((index) => {
        table[parseInt(index)].push(errors[index]);
      });

      const invalidInputList = _remove(table, (_, i) => !!errors[i]);

      setValidInputList(table);
      if (!_isEmpty(errors)) setInvalidInputList(invalidInputList);
    } catch (e) {
      dispatchToast({
        type: 'error',
        content: 'Não foi possível importar a planilha',
        description: errors.actions.toastDescription,
      });
    }
  };

  const Modules = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {!!invalidInputList.length ? (
        <ValidationErrorModule
          invalidInputList={invalidInputList}
          validInputList={validInputList}
          callback={() => {
            setInvalidInputList([]);
          }}
        />
      ) : (
        <>
          <DownloadTemplateModule />
          <UploadModule onUpload={validateFile} />
        </>
      )}
    </div>
  );

  const headerMetadata = {
    breadcrumbs: [
      {
        link: '/corporateCard/home',
        text: CARDS_HOME_TITLE,
      },
      {
        text: 'Importar transações',
      },
    ],
  };

  const content = (
    <Template
      pageInfo={{ title: 'Importar transações' }}
      contentInfo={{
        title: 'Informações de transações',
        description: (
          <span>
            <p>
              A importação de transações tem como função adicionar transações
              para que o extrato do seu cartão terceiro (cartões externos que
              não fazem parte da Flash) contenha transações para a futura
              prestação de contas dentro da plataforma da Flash.
            </p>
            <br />
            <p>
              Após adicionar essas transações, elas irão aparecer no extrato
              daquele cartão determinado e o colaborador poderá prestar contas
              via Flash OS.
            </p>
            <br />
            <p>
              Importante: Você pode adicionar transações de vários cartões ao
              mesmo tempo. Fique atento aos dados digitados para que as
              informações corretas vão para os cartões corretos.
            </p>
          </span>
        ),
      }}
      Modules={Modules}
    />
  );

  const footer = {
    leftActions: {
      onClick: () => modalController.remove(),
      label: 'Cancelar',
    },
    rightActions: [
      {
        onClick: () => {
          upload(validInputList, () => modalController.remove());
        },
        label: !!invalidInputList.length
          ? 'Continuar apenas as transações processadas'
          : 'Importar transações',
        isDisabled: !validInputList.length,
        isLoading: isLoading,
        variant: 'primary',
      },
    ],
  } as const;

  return {
    headerMetadata,
    content,
    footer,
  };
};
