import CheckIsCorporateCard from '@frontend/components/CheckIsCorporateCard';
import CheckPermissions from '@frontend/components/CheckPermissions/CheckPermissions';
import { useVersion } from '@frontend/components/VersionCallout/context';
import { RouteComponent_Home_ExternalCard } from '@frontend/pages/ExternalCards';
import { useExternalCards } from '@frontend/pages/ExternalCards/utils/hooks/useExternalCards';
import {
  RouteComponent_Home_FlashCard,
  RouteComponent_Home_FlashCard_Index,
} from '@frontend/pages/Home/FlashCard';
import Home from '@frontend/pages/Home/Home';
import { RouteComponent_Playground } from '@frontend/pages/Playground';
import { RouteComponent_PolicyDetails } from '@frontend/pages/Policies/PolicyDetails';
import {
  RouteComponent_ApprovalFlow,
  RouteComponent_ApprovalFlowEditForm,
  RouteComponent_ApprovalFlowForm,
} from '@frontend/pagesV2/approval-flow';
import {
  ROUTE_UserAccounts,
  UserAccountsPage,
} from '@frontend/pagesV2/user-accounts/UserAccounts';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { useHandleChangeCompany } from '@frontend/utils/hooks/useHandleChangeCompany';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RouteComponent_Cards } from '../pages/Cards';
import { RouteComponent_Home_ExternalCards_v2 } from '../pages/ExternalCards/index';
import { RouteComponent_ExternalCardDetails } from '../pages/ExternalCards/subpages/ExternalCardDetails';
import { RouteComponent_EmployeeStatement } from '../pages/Home/EmployeeStatement';
import EmployeeTransactionDetail from '../pages/Home/EmployeeTransactionDetail';
import { RouteComponent_Management } from '../pages/Management';
import Statement from '../pages/Statement';
import CorporateCardStatement from '../pages/Statement/CorporateCard';
import ExternalCardStatement from '../pages/Statement/ExternalCards';
import FlashCardStatement from '../pages/Statement/FlashCard';
import TransactionDetail from '../pages/TransactionDetail';
import Transactions from '../pages/Transactions';
import {
  RouteComponent_Billet,
  RouteComponent_Billet_Index,
} from '../pages/Transactions/Billet';
import { RouteComponent_Deposit } from '../pages/Transactions/Deposits';
import { RouteComponent_FlashCash } from '../pages/Transactions/FlashCash';
import { Wallet } from '../pages/Wallet';
import BasePath from './BasePath';

export default function Router() {
  const accountManagementVersion = useVersion('accountManagement');
  const externalCards = useExternalCards();

  const policiesEnabled = FeatureFlagService.getFlag('policies');

  useHandleChangeCompany();

  return (
    <Routes>
      {RouteComponent_Playground}
      {RouteComponent_Cards}
      {policiesEnabled && RouteComponent_PolicyDetails}
      {UserAccountsPage}
      {RouteComponent_ApprovalFlow}
      {RouteComponent_ApprovalFlowForm}
      {RouteComponent_ApprovalFlowEditForm}
      <Route
        path="/corporateCard/statement/:transactionId"
        element={<TransactionDetail />}
      />
      <Route path="/corporateCard/statement" element={<Statement />}>
        <Route index element={<FlashCardStatement />} />
        <Route
          path="/corporateCard/statement/flashCard"
          element={<FlashCardStatement />}
        />
        <Route
          path="/corporateCard/statement/corporateCard"
          element={<CorporateCardStatement />}
        />
        {externalCards.enabled && (
          <Route
            path="/corporateCard/statement/externalCards"
            element={<ExternalCardStatement />}
          />
        )}
      </Route>
      <Route path={BasePath.path} element={<CheckIsCorporateCard />}>
        <Route path="/corporateCard/wallet" element={<Wallet />} />
      </Route>
      <Route path={BasePath.path} element={<CheckPermissions />}>
        <Route
          path={'/corporateCard/home'}
          element={
            accountManagementVersion.isNewVersion ? (
              <Navigate to={ROUTE_UserAccounts.buildPath({})} />
            ) : (
              <Home />
            )
          }
        >
          {RouteComponent_Home_FlashCard_Index}
          {RouteComponent_Home_FlashCard}
          {externalCards.enabled &&
            !externalCards.useV2 &&
            RouteComponent_Home_ExternalCard}
        </Route>
        {RouteComponent_ExternalCardDetails}
        {RouteComponent_EmployeeStatement}
        <Route
          path="/corporateCard/home/:employeeId/statement/:transactionId"
          element={<EmployeeTransactionDetail />}
        />
        {RouteComponent_Management}
        <Route path="/corporateCard/transactions" element={<Transactions />}>
          {RouteComponent_Billet_Index}
          {RouteComponent_Billet}
          {RouteComponent_Deposit}
          {RouteComponent_FlashCash}
        </Route>
      </Route>
      <Route path={BasePath.path} element={<CheckPermissions />}>
        {RouteComponent_Home_ExternalCards_v2}
      </Route>
    </Routes>
  );
}
